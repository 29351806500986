import "react-progress-button/react-progress-button.css";
import GanymedeInput from '../../components/ganymede/GanymedeInput';
import { useFormContext } from 'react-hook-form';


function SendEmailStep() {
  const { register, formState: { errors }, watch} = useFormContext();
  const client = watch('client');

  return (
    <div className={'wellness-email-container'}>
      <div className={'email-input'}>
        <GanymedeInput type={'text'} errors={errors} defaultValue={client?.email} label={'Email'} {...register('email', { required: { value: true, message: 'Email is required' },
          pattern: { value: /(.+)@(.+){2,}\.(.+){2,}/, message: 'Email is invalid' }})}/>
      </div>
    </div>
  );
}

export default SendEmailStep;
