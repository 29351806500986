import {useOutletContext} from 'react-router-dom';
import GanymedePageContainer from "../components/ganymede/PageContainer";
import snoutIcon from "../components/ganymede/snout.png";
import GanymedeKeyValueTable from "../components/ganymede/KeyValueTable";
import GanymedePageFooter from "../components/ganymede/PageFooter";
import GanymedePaymentCardDisplay from "../components/ganymede/GanymedePaymentCardDisplay";
import {useEffect, useState} from "react";
import { useForm, FormProvider } from 'react-hook-form';
import ProgressButton from 'react-progress-button';
import "react-progress-button/react-progress-button.css";

function InvoicePaymentView() {
  const outletContext = useOutletContext();
  const formMethods = useForm({ });
  const { handleSubmit, register, watch, formState: { errors } } = formMethods;
  const tos = watch('tos');
  const [selectedPlanObject, setSelectedPlanObject] = useState();
  const [requestState, setRequestState] = useState('');
  useEffect(() => {
    setSelectedPlanObject(outletContext.dagwood?.invoice?.plans?.find(plan => plan?.id === outletContext.selectedPlanId))
  }, [outletContext.selectedPlanId, outletContext.dagwood?.invoice?.plans]);
  useEffect(() => {
    if (outletContext?.apiError?.type === 'payment') setRequestState('error');
  }, [outletContext?.apiError]);
  if (!outletContext.dagwood) return (<></>);

  const { dagwood: { invoice, invoice: { pet } }, dagwood, selectedPlanId, setSelectedPlanId, selectedPaymentMethod, setSelectedPaymentMethod, doPayment, apiError } = outletContext;

  const tabulateItems = (invoice, plan) => {
    return invoice.items.reduce((agg, item) => {
      item.covered = !!plan?.coverage?.find(c => c.id === item.id || c === item.id);
      agg[item.name] = (<>
        {item.covered && <span className={'strikethrough'}>{item.price}</span>}
        <span>{item.covered?"$0":item.price}</span></>
      );
      return agg;
    }, {});
  };

  const onSubmit = (values) => {
    setRequestState('loading');
    // use new card if it has been entered, otherwise use selected payment method
    doPayment(invoice.id, invoice.updated, selectedPlanId, values.newCardForm || dagwood?.paymentMethods[values.selectedCardRadio]);
  }

  const paymentFooter = (
      <div className={'payment-footer'}>
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={`add-cc-form`}
          >
            <GanymedePaymentCardDisplay
              className={'invoice-payment-card-display'}
              paymentMethods={dagwood?.paymentMethods}
              selectedPaymentMethod={selectedPaymentMethod}
              allowChange={true}
              onSelectPaymentMethod={setSelectedPaymentMethod}
            />
            {apiError?.type === 'payment' && <div className={'form-validation-error invoice-payment-error'}>{apiError?.message || 'something went wrong with your payment. please try again'}</div>}
            <div className={'btn-container'}>
              <div>
                <div>
                  <span>
                  <input id="agreementCheckbox" className={'agreement-checkbox'}
                    type="checkbox"
                    {...register("tos", { required: { value: true, message: 'You must agree to our Terms of Service to proceed' } })}
                  />
                </span>
                  <label htmlFor="agreementCheckbox">
                    By clicking “Complete Payment” I understand that I will be charged monthly for {pet.name}’s Snout Wellness Plan, and I have read and agree to the Snout <a href={'poop.com'}>Terms of Service</a>.
                  </label>
                </div>
                {errors?.tos && <span className={'form-validation-error'}>{errors.tos.message}</span>}
              </div>
              <div>
                <ProgressButton durationSuccess={2000} controlled={true} onClick={() => {}} className={`complete-payment-button ${(selectedPaymentMethod && !tos) ? 'btn-disabled' : ''}`} state={requestState} type="submit">Complete Payment</ProgressButton>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
  );
  return (
      <>
        <GanymedePageContainer
            className={'checkout-container'}
            trailer={'*This is an estimate. Final prices may vary depending on the situation.'}
            footerContent={paymentFooter}
        >
          <div className={'title'}>
            <div className={'pre-title'}>
              {pet.name}'s
            </div>
            <div>
              Wellness Plan Checkout
            </div>
          </div>
          {!dagwood?.invoice?.currentPlan && <div className={'plan-select'}>
            <div
              className={`${!selectedPlanId ? 'selected-plan ' : ''}plan-option`}
              onClick={() => setSelectedPlanId(undefined)}
            >
              <div>
                <div className={'plan-title'} style={{ marginTop: '0.75rem' }}>Full Invoice</div>
              </div>
              <div className={'plan-price'}>
                {invoice.due}
              </div>
            </div>
            {
              invoice.plans.map((plan) => (
                <div
                  key={plan.id}
                  className={`${selectedPlanId === plan.id ? 'selected-plan ' : ''}plan-option`}
                  onClick={() => setSelectedPlanId(plan.id)}
                >
                  <div>
                    <div className={'brand'}>
                      <span><img alt="" src={snoutIcon}
                        style={{ height: '0.75rem', width: '0.75rem', display: 'inline', marginRight: '0.19rem' }}/>Snout</span>
                    </div>
                    <div className={'plan-title'}>{plan.name}</div>
                  </div>
                  <div className={'plan-price'}>
                    {`${plan.total.price} /${plan.total.period}`}
                  </div>
                </div>
              ))
            }
          </div>}
          <GanymedeKeyValueTable className={'ganymede-full-width'} header={ 'Today\'s Services' }
                                 data={tabulateItems(invoice, selectedPlanObject)}
          />
          {
            selectedPlanId &&
            (<GanymedeKeyValueTable className={'ganymede-full-width'}
                                    header={ `${pet.name}'s Wellness Plan` }
                                    data={{ [`${selectedPlanObject?.total.period}ly subscription fee`]:`${selectedPlanObject?.total.price}` }}/>)
          }
          <GanymedeKeyValueTable className={'ganymede-full-width total'}
                                 header={['Today\'s Total', selectedPlanObject?.due || invoice.due]}
                                 data={{ '':selectedPlanId &&`You're saving ${selectedPlanObject?.savings} today! 🎉` }}/>
        </GanymedePageContainer>
        <GanymedePageFooter />
      </>
  );
}

export default InvoicePaymentView;
