import {useOutletContext} from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';
import "react-progress-button/react-progress-button.css";
import Select from 'react-select';
import GanymedeCurrencyInput from '../../components/ganymede/GanymedeCurrencyInput';
import { useEffect } from 'react';

const MethodOption = ({ innerRef, innerProps, data, ...props }) => {
  return (
    <div className={`complete-option ${data.isDisabled ? 'disabled' : ''} ${props.className}`} ref={innerRef} {...innerProps}>
      <div>{data?.text}</div>
    </div>
  );
}

const requestCompletionMethods = [
  { method: 'same_device', text: 'Complete through this same device', details: 'Click on the "Next" button below and hand this device over to the client'},
  { method: 'email', text: 'Send via Email', details: `We’ll send a URL to your client via email for them to complete this charge`},
  { method: 'code', text: 'Send via unique code', details: `Share this unique code with the client for them to access and complete this charge on a separate device`},
  { method: 'SMS', text: 'Send via SMS Message', details: `We’ll send a URL to your client via SMS message for them to complete this charge`},
]

function RequestDetailsStep() {
  const outletContext = useOutletContext();
  const { unregister, control, formState: { errors } } = useFormContext();

  const {  } = outletContext;

  useEffect(() => {
    unregister('paymentMethod');
  }, []);

  const requestMethodTransformations = {
    input: (value) => {
      return value ? { label: <MethodOption data={value} className={'label'} />, value} : value;
    },
    output: (e) => {
      return e?.method;
    }
  }

  return (
    <div className={'wellness-request-details-container'}>
      <GanymedeCurrencyInput
        className={'charge-amount-input'}
        label="Charge Amount"
        name={'chargeAmount'}
        max={1000000}
        rules={{ required: { value: true, message: 'Charge amount is required'}, min: { value: 50, message: 'Charge amount must be at least be $0.50'}}}
      />
      <div className={'complete-charge-select'}>
        <div>How do you want the client to complete this charge?</div>
        <Controller
        name={`requestMethod`}
        defaultValue={requestCompletionMethods[0].method}
        rules={{ required: { value: true, message: 'Select a method of completing this request'} }}
        control={control}
        render={({ field }) =>
          <>
            <Select
              value={requestMethodTransformations.input(requestCompletionMethods.find((config) => config.method === field.value))}
              placeholder={'Select a method to complete this request'}
              defaultOptions={requestCompletionMethods}
              options={requestCompletionMethods}
              onChange={e => field.onChange(requestMethodTransformations.output(e))}
              components={{ Option: MethodOption }}
            />
            {errors?.[field.name] && <span className={'form-validation-error'}>{errors?.[field.name]?.message}</span>}
          </>
        }
        />
      </div>
    </div>
  );
}

export default RequestDetailsStep;
