import { useForm } from 'react-hook-form';
import Dog from '../../../../src/assets/svgs/Dog.svg'
import Cat from '../../../../src/assets/svgs/Cat.svg'
import StepContainer from "../StepContainer";
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENT_CODES } from '../../../constants';

function Species({state, dispatch}) {

  const { formState: { errors }, handleSubmit, register } = useForm({
    defaultValues: {
      species: state?.currentPet?.species
    }
  });

  const onSubmit = (values) => {
    track(AMPLITUDE_EVENT_CODES.SELF_ENROLL_PET_SPECIES, {
      vet: state.vet,
      client: state.client,
      pet: { ...state.currentPet, species: values.species },
    });
    dispatch({type: 'next', species: values.species});
  }

  return (
    <StepContainer back={() => dispatch({type: 'prev'})}>
      <form className={'flex flex-col items-center h-full'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'font-roboto-slab text-3xl sm:text-4xl text-center mb-4'}>What kind of pet is {state?.currentPet?.name[0].toUpperCase() + state?.currentPet?.name.slice(1) }?</div>
        <div className={"flex flex-wrap flex-row gap-4 justify-center"}>

          <input id="radio-dog" type="radio" name="radio-dog" className="hidden snout-radio" value="dog" {...register('species', { shouldUnregister: true, required: { value: true, message: 'Response is required' }})}/>
          <label htmlFor="radio-dog" className="justify-end flex flex-col items-center cursor-pointer text-l  text-stone-500 mb-4 px-14 pb-6 pt-10 rounded-md bg-white border border-solid border-stone-300">
            <div className={"items-end mb-2"}>
              <img className='ml-[-10px]' src={Dog} alt="dog"/>
            </div>
            Dog
            <span className={"mt-2 place-content-baseline w-5 h-5 inline-block rounded-full bg-white border border-solid border-stone-200 flex-no-shrink"}></span>
          </label><input id="radio-cat" type="radio" name="radio-cat" className="hidden snout-radio" value="cat" {...register('species', { shouldUnregister: true, required: { value: true, message: 'Response is required' }})}/>
          <label htmlFor="radio-cat" className="justify-end flex flex-col items-center cursor-pointer text-l  text-stone-500 mb-4 px-14 pb-6 pt-10 rounded-md bg-white border border-solid border-stone-300">
            <div className={"items-end mb-2"}>
              <img className="ml-[-18px]"src={Cat} alt="cat"/>
            </div>
            Cat
            <span className={"mt-2 place-content-baseline w-5 h-5 inline-block rounded-full bg-white border border-solid border-stone-200 flex-no-shrink"}></span>
          </label>
          <div className={'form-validation-error col-span-full -mt-3'}>{errors?.species?.message}</div>
        </div>
        <button className={'mt-auto sm:mt-8 w-full sm:w-40 btn-primary'} type="submit">Continue</button>
      </form>
    </StepContainer>
  );
}

export default Species;
