import {useParams, Outlet} from 'react-router-dom';

function PracticeAuthenticator() {
  const { token } = useParams();

  localStorage.setItem('token', token);

  return (
    <>
      <Outlet context={{ token }}/>
    </>
  );
}

export default PracticeAuthenticator;
