import { Link, useOutletContext } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';
import "react-progress-button/react-progress-button.css";
import GanymedeInput from '../../components/ganymede/GanymedeInput';
import Select from 'react-select';

function PatientInformationStep() {
  const getStatusConfig = (patient) => {
    if (patient?.plan_instance) {
      if (patient.payment_status === 'overdue') {
        return {
          text: 'Payment overdue',
          badge: 'red',
        }
      } else if (patient.payment_status === 'attempting payment') {
        return {
          text: 'Payment pending',
          badge: 'yellow',
        }
      }
      return {
        text: 'Enrolled',
        badge: 'green',
      }
    }
    else if (!patient?.species) {
      return {
        text: 'Not eligible for plan',
        badge: 'grey',
      };
    }
    else if (patient?.invitation?.external_id) {
      return {
        text: 'Invited, not enrolled',
        badge: 'yellow',
      };
    }
    else {
      return {
        text: 'Not enrolled',
        badge: 'grey',
      };
    }
  }
  const outletContext = useOutletContext();

  const { register, trigger, watch } =  useFormContext();
  const patients = watch('patients');
  const { fields, newPatientFormMethods, patientMode, setPatientMode } = outletContext;

  // Transform and Parse section of react hook api here: https://react-hook-form.com/advanced-usage
  const speciesTransformations = {
    input: (value) => {
      return value ? { label: value?.charAt(0)?.toUpperCase() + value?.slice(1), value} : value;
    },
    output: (e) => {
      return e?.value;
    }
  }


  return (
    <>
      <div className={'patient-information-container'}>
        {patientMode === 'select' && <div className={'patient-select-container'}>
          <div className={'d-grid grid-col-gap-2 grid-row-gap-2 grid-cols-2'}>
            {fields.map(({ id, patient }, i) => {
                // register select and checkboxes for each patient
                return (<label htmlFor={`patient-option-checkbox-${i}`} className={`patient-option-display grid-span-1 ${patients?.[i]?.selected ? 'selected' : ''}`} key={id}>
                  <div className={'patient-option-heading'}>
                    <div className={'patient-option-checkbox'}>
                      <input id={`patient-option-checkbox-${i}`} type={'checkbox'} {...register(`patients.${i}.selected`, {
                        onChange: () => trigger('patients'),
                        validate: (val) => {
                          // at least 1 patient in the form array must be selected
                          return patients?.find((p) => p.selected) || 'Select at least 1 patient';
                        }
                      })} />
                      <span />
                    </div>
                    <div className={'patient-heading w-full'}>
                      <div className={'patient-option-species'}>{patient?.species && `${(patient.species.toLowerCase() === 'dog' || patient.species.toLowerCase() === 'd') ? '🐶' : '🐱'}`}&nbsp;</div>
                      <div className={'patient-option-name'}>{patient?.name}</div>
                      <div className={`patient-option-badge ${getStatusConfig(patient).badge}`}>
                        {getStatusConfig(patient).text}
                      </div>
                      {patient?.plan_instance?.id && <div className={'ml-2.5'}>
                        <Link to={`planInfo/${patient.id}`}
                          className={'text-sm cursor-pointer hover:text-sky-600'}>view plan</Link>
                      </div>}
                    </div>
                  </div>
                  {/*<div className={'patient-option-plan-select'}>*/}
                  {/*  <div className={'patient-plan-display'}>*/}
                  {/*    <div>Current Plan</div>*/}
                  {/*    <div>{patient?.plan_instance?.name || 'None'}</div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </label>)
              }
            )}
            <button type={"button"} onClick={() => setPatientMode('new')} className={'add-patient-field-btn grid-span-1'}>
              + Add Patient
            </button>
          </div>
        </div>}
        {patientMode === 'new' &&
        <div className={'new-patient-container'}>
          <div>
            <GanymedeInput className={'grid-span-3'} label={'Patient Name'} errors={newPatientFormMethods?.formState?.errors}
              {...newPatientFormMethods.register('name', { shouldUnregister: true, required: { value: true, message: 'Pet name is required' }})}
            />
            <Controller
              name="species"
              control={newPatientFormMethods.control}
              rules={{ shouldUnregister: true, required: { value: true, message: 'Species is required'}}}
              render={({ field }) =>
                <div className={'grid-span-3'}>
                  <Select
                    className={'ganymede-select'}
                    value={speciesTransformations.input(field.value)}
                    placeholder={'Select species...'}
                    options={[{label: 'Dog', value:'dog' }, { label: 'Cat', value: 'cat' }]}
                    isSearchable={false}
                    onChange={e => field.onChange(speciesTransformations.output(e))}
                  />
                  {newPatientFormMethods?.formState?.errors?.species && <span className={'form-validation-error'}>{newPatientFormMethods.formState.errors.species.message}</span>}
                </div>
              }
            />
          </div>
          <div>
            {patients?.length > 0 && <button className={'back-to-search-btn'} type={'button'}
              onClick={() => setPatientMode('select')}>Cancel</button>}
            <button className={`${!newPatientFormMethods?.formState?.isValid ? 'disabled' : ''}`} type="submit" form="new-patient-form">Add</button>
          </div>
        </div>
        }
      </div>
    </>
  );
}

export default PatientInformationStep;
