import React, { useState } from "react";
import "./Tooltip.css";

const Tooltip = (props) => {
  const [active, setActive] = useState(false);

  return (
    <div
      className="Tooltip-Wrapper"
      // When to show the tooltip

      onTouchEnd={()=> setActive(!active)}
      onMouseEnter={()=> setActive(true)}
      onMouseLeave={()=> setActive(false)}
    >
      {/* Wrapping */}
      {props.children}
      {active && (
        <div className={`Tooltip-Title ${props.direction || "top"}`}>{props.title}
        <div className={`Tooltip-Detail ${props.direction || "top"}`}>{props.content}</div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
