import GanymedePaymentCardDisplay from "../../../components/ganymede/GanymedePaymentCardDisplay";
import GanymedeKeyValueTable from "../../../components/ganymede/KeyValueTable";
import {FormProvider, useForm} from "react-hook-form";
import common from "../../../lib/common";
import { useEffect, useRef } from "react";
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import SnoutProgressBtn from '../../../components/SnoutProgressBtn/SnoutProgressBtn';
import api from '../../../services/jupiter_api';
import StepContainer from "../StepContainer";
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENT_CODES } from '../../../constants';
import GanymedeInput from '../../../components/ganymede/GanymedeInput';
import GanymedeCreditCardNumberInput from '../../../components/ganymede/GanymedeCreditCardNumberInput';
import GanymedeCreditCardExpiryInput from '../../../components/ganymede/GanymedeCreditCardExpiryInput';
const DayJs = require('dayjs');
const customParseFormat = require('dayjs/plugin/customParseFormat');
DayJs.extend(customParseFormat);

function CheckoutStep({ state, dispatch }) {

  const { toasts } = useToasterStore();
  // limit visible toasts to 3.
  // stolen from here https://github.com/timolins/react-hot-toast/issues/31#issuecomment-803359550
  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= 3) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  const formMethods = useForm({mode: 'onChange', defaultValues:
    state?.paymentMethod,
    });
  const { handleSubmit, register, formState: { errors }, getValues, setValue } = formMethods;

  const checkout = async (paymentMethod) => {
    btnRef?.current?.loading();

    try {
      const result = await api.clientSelfEnroll(state.client, state.currentPet, state.selectedPlan, { ...paymentMethod, ...state.personalDetails });
      track(AMPLITUDE_EVENT_CODES.SELF_ENROLL_PAYMENT_SUCCESS, {
        vet: state.vet,
        client: state.client,
        pet: state.currentPet,
        plan: state.selectedPlan,
      });
        btnRef?.current?.success();
        setTimeout(()=> {
          dispatch({type: 'next', planInstanceId: result.external_id });
        }, 1000)

    } catch(e) {
      track(AMPLITUDE_EVENT_CODES.SELF_ENROLL_PAYMENT_FAILURE, {
        vet: state.vet,
        client: state.client,
        pet: state.currentPet,
        plan: state.selectedPlan,
        error: e,
      });
      btnRef?.current?.error();
      toast( t => {
        return (<>
          <button className={'close-toast-button'} onClick={() => toast.dismiss(t.id)}>x</button>
          {e.message}
        </>)
      }, {
        type: 'error',
        duration: Infinity,
      });
    }
  }

  const btnRef = useRef();

  const onSubmit = async (form) => {
    const [exp_month, exp_year] = form.paymentMethod.expiry.split(' / ')
    await checkout({ ...form.paymentMethod, exp_year, exp_month });
  }

  //Checks to see whether there is a non-zero enrollment fee
  const getPlanDetails = () => {

    let planDetails = {'Plan': state?.selectedPlan?.name, 'Monthly Payment': common.pennyIntToDollarString(state?.selectedPlan?.monthly_price)};
    const enrollmentFeeAmt = state?.selectedPlan?.enrollment_fee;

    if( enrollmentFeeAmt > 0) {
      planDetails['Enrollment fee'] = common.pennyIntToDollarString(enrollmentFeeAmt);
    }
    return planDetails;
  }

  const valiDATE = (newValue) => {
    const [month, year] = newValue?.split(' / ');
    // check for valid date
    if (!DayJs(`${year}-${month}`, 'YY-MM', true).isValid()) return 'Not a valid date';
    // check for month on or after this month
    const dayIsOnOrBeforeToday = DayJs(`${year}-${month}`, 'YY-MM').isAfter(DayJs(), 'month') || DayJs(`${year}-${month}`, 'YY-MM').isSame(DayJs(), 'month');
    return dayIsOnOrBeforeToday || 'Cannot be in the past';
  }

  return (
    <StepContainer back={() => dispatch({type: 'prev'})}>
      <div className={'font-roboto-slab text-3xl sm:text-4xl text-center mb-8'}>Billing Information</div>
      <div className={' bg-gray-50'}>
        <GanymedeKeyValueTable
            className={'total-table'}
            style={{width: '100%'}}
            data={getPlanDetails()}/>
        <GanymedeKeyValueTable
            className={'total-table last:[&_th]:text-right'}
            style={{width: '100%', marginTop: '0.63rem'}}
            header={[`Total`, `${common.pennyIntToDollarString(state?.selectedPlan?.monthly_price + state?.selectedPlan?.enrollment_fee)}`]}
        />
      </div>
      <p style={{fontSize: '0.88rem', color: '#828282'}}>Complete your care plan enrollment by providing your payment details and reviewing our membership agreement</p>
      <FormProvider {...formMethods}>
        <form className={'flex flex-col items-center'} onSubmit={handleSubmit(onSubmit)}>
          <div className={`grid grid-cols-6 gap-x-2.5 w-full mb-2`}>
            <GanymedeInput
              className={'col-span-full sm:col-span-2'}
              label="First Name"
              type="text"
              errors={errors}
              {...register("paymentMethod.first_name", {
                onChange: (e) => setValue('paymentMethod.first_name', e?.target?.value?.trim()),
                shouldUnregister: false, required: { value: true, message: 'First name is required' }
              })}
            />
            <GanymedeInput
              className={'col-span-full sm:col-span-2'}
              label="Last Name"
              type="text"
              errors={errors}
              {...register("paymentMethod.last_name", {
                onChange: (e) => setValue('paymentMethod.last_name', e?.target?.value?.trim()),
                shouldUnregister: false, required: { value: true, message: 'Last name is required' }
              })}
            />
            <GanymedeInput
              className={'col-span-full sm:col-span-2'}
              label="Zip"
              type="text"
              errors={errors}
              {...register("paymentMethod.postal_code", {
                onChange: (e) => setValue('paymentMethod.postal_code', e?.target?.value?.trim()),
                required: {  value: true, message: 'Zip code is required' },
                minLength: {  value: 5, message: 'Zip code too short' }
              })}
            />
            <GanymedeCreditCardNumberInput
              className={'col-span-full sm:col-span-3'}
              label="Card Number"
              name={'paymentMethod.number'}
              rules={{ required: { shouldUnregister: false, value: true, message: 'Card number is required' } }}
            />
            <GanymedeCreditCardExpiryInput
              className={'col-span-full sm:col-span-2'}
              label="Expiry"
              name={'paymentMethod.expiry'}
              rules={{ validate: valiDATE, shouldUnregister: false, required: { value: true, message: 'Expiry is required' } }}
            />
            <GanymedeInput
              className={'col-span-full sm:col-span-1'}
              label="CVC"
              type="tel"
              errors={errors}
              maxLength={4}
              {...register('paymentMethod.cvc', {
                onChange: (e) => setValue('paymentMethod.cvc', e?.target?.value?.trim()),
                required: { value: true, message: 'CVC required' }, minLength: { value: 3, message: 'CVC invalid' }, min: 3 }
              )} />
          </div>
          <div className="mb-8">
            <div className={''}>
              <div>
                <input id="agreementCheckbox" className={'agreement-checkbox'}
                       type="checkbox"
                       {...register("tos", { required: { value: true, message: 'You must agree to our Membership Agreement to proceed' } })}
                />
                <label className={'text-left sm:text-base'} htmlFor="agreementCheckbox">
                  YES, I understand that I will be charged monthly for {state?.currentPet?.name}'s Snout wellness plan and I have read and agree to be bound by the <button type={'button'} className={'bg-gray-50 text-snout-orange border-0 p-0 font-roboto text-base cursor-pointer'} onClick={() => {
                  const formState = getValues();
                  dispatch({ type: 'showTos', formState, })
                }}>Snout Membership Agreement</button>.
                </label>
              </div>
            </div>
            {errors?.tos && <span className={'form-validation-error'}>{errors.tos.message}</span>}
          </div>
          <div className={'w-40'}>
            <SnoutProgressBtn ref={btnRef}>Continue</SnoutProgressBtn>
          </div>
        </form>
      </FormProvider>
      <Toaster position={'bottom-center'}/>
    </StepContainer>
  );
}

export default CheckoutStep;
