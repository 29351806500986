import {useNavigate} from 'react-router-dom';
import {useState} from "react";
import GanymedePageFooter from "../../components/ganymede/PageFooter";
import GanymedePageContainer from "../../components/ganymede/PageContainer";

function PaymentRequestEntryView() {

  const navigate = useNavigate();

  const [inputCode, setInputCode] = useState('');

  async function onClick () {
    navigate(`load/${inputCode}`);
  }

  return (
      <>
        <GanymedePageContainer className={'subscription-container'}>
          <h1>Payment Request</h1>
          <p>Welcome! Please enter your payment request code to complete checkout.</p>
          <div className={'center-flex'}>
            <input placeholder={'Enter Your Code'} autoFocus className={'code-input'} type="text" value={inputCode} onChange={e => setInputCode(e.target.value)} />
          </div>
          <div className={'center-flex'}>
            <div className={'begin-btn-container'}>
              <button className={'begin-btn'} onClick={onClick}>Begin</button>
            </div>
          </div>
        </GanymedePageContainer>
        <GanymedePageFooter />
      </>
  );
}

export default PaymentRequestEntryView;
