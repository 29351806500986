import "react-progress-button/react-progress-button.css";
import GanymedeInput from '../../components/ganymede/GanymedeInput';
import { useFormContext } from 'react-hook-form';
import parsePhoneNumber, {isPossiblePhoneNumber} from "libphonenumber-js";


function SendSmsStep() {
  const { register, formState: { errors }, watch} = useFormContext();
  const client = watch('client');

  return (
    <div className={"wellness-email-container"}>
      <div>
        <GanymedeInput
          label="Phone Number"
          type="text"
          errors={errors}
          {...register("phone", {
            value:
              client?.phone &&
              parsePhoneNumber(client.phone, "US")?.formatNational(),
            required: {
              value: true,
              message: "You must enter your phone number.",
            },
            validate: (value) => {
              return (
                isPossiblePhoneNumber(value, "US") || "Invalid phone number"
              );
            },
          })}
        />
      </div>
    </div>
  );
}

export default SendSmsStep;