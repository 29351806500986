import {useOutletContext, useNavigate} from "react-router-dom";
import GanymedePaymentCardDisplay from "../../components/ganymede/GanymedePaymentCardDisplay";
import GanymedePageContainer from "../../components/ganymede/PageContainer";
import GanymedeKeyValueTable from "../../components/ganymede/KeyValueTable";
import GanymedePageFooter from "../../components/ganymede/PageFooter";
import GanymedeDocumentHeader from "../../components/ganymede/DocumentHeader";
import InvoiceColumnHeader from "../../components/ganymede/InvoiceColumnHeader";
import {FormProvider, useForm} from "react-hook-form";
import ProgressButton from "react-progress-button";
import common from "../../lib/common";
import { useEffect, useRef, useState } from "react";
import parsePhoneNumber from 'libphonenumber-js';
import { Link } from "react-router-dom";

function PlanCheckoutView(props) {
  const { dagwood, apiState, checkout, plan, paymentMethod, setPaymentMethod, paymentMode, setPaymentMode, tos, setTos } = useOutletContext();

  const navigate = useNavigate();

  const formMethods = useForm({mode: 'onChange', defaultValues: {
    paymentMethod,
    }});
  const {handleSubmit, register, formState: { errors, isValid, isSubmitting }, watch, setValue } = formMethods;
  const [detailsConfig, setDetailsConfig] = useState({});

  // update payment method field on page load
  useEffect(() => {
    if (paymentMethod) {
      if (paymentMethod.external_id && paymentMode === 'select') {
        setValue('paymentMethodRadio', dagwood?.client?.cards?.findIndex(card => card.external_id === paymentMethod.external_id)?.toString());
      } else {
        setValue('paymentMethod', paymentMethod);
      }
    }
  }, [])

  // update payment method state with form values as they change
  const paymentMethodField = watch('paymentMethod');
  useEffect(() => {
    if (paymentMethodField) {
      setPaymentMethod(paymentMethodField);
    }
  }, [paymentMethodField])

  // update terms of service field on page load
  useEffect(() => {
    if (tos) {
      setValue('tos', tos);
    }
  }, [])

  // update terms of service checkbox state from form value
  const tosField = watch('tos');
  useEffect(() => {
    if (tosField !== undefined) {
      setTos(tosField);
    }
  }, [tosField])

  const btnRef = useRef();

  const onSubmit = async (form) => {
    await checkout(form.paymentMethod);
  }

  let planObject = {};
  useEffect(() => {
    if (dagwood?.plans) {
      planObject[`${dagwood.plans[plan].name} Plan`] = (<InvoiceColumnHeader
          value={common.pennyIntToDollarString(dagwood.plans[plan].monthly_price)}
          subscript={'/month'}
      />);
    }

    if (dagwood && plan !== undefined) {
      const cfg = {'Plan': dagwood?.plans?.[plan]?.name, 'Monthly Payment': common.pennyIntToDollarString(dagwood?.plans?.[plan]?.monthly_price), 'Enrollment Fee': common.pennyIntToDollarString(dagwood?.plans?.[plan]?.enrollment_fee)}
      if (!dagwood?.plans?.[plan]?.enrollment_fee) delete cfg['Enrollment Fee'];
      setDetailsConfig(cfg);
    }
  },[dagwood, plan])

  // button progress state for api calls
  useEffect(() => {
    if (apiState) {
      switch(apiState.status) {
        case 'loading':
          btnRef?.current?.loading();
          break;
        case 'success':
          btnRef?.current?.success(null, false);
          break;
        case 'error':
          btnRef?.current?.error(null);
          break;
        default:
          btnRef?.current?.notLoading();
          break;
      }
    }
  }, [apiState]);

  return (
      <>
        <div className={'subscription-flow flex flex-row text-left items-center px-3 py-1.5 md:px-10 md:py-3 lg:px-20 lg:py-6 bg-white'}>
          <img className={'w-20 mr-2.5'} alt={dagwood?.vet?.name} src={dagwood?.vet?.logo_url} /><span className={'text-2xl sm:text-3xl md:text-4xl font-normal'}>{dagwood?.vet?.name}</span>
        </div>
        <GanymedePageContainer className={'plan-checkout-container'}>
          <div className={'InvoiceHeader'}>
            <GanymedeDocumentHeader
                title="Care Plan Enrollment"
              attribution={(
                <>
                  {[dagwood.vet.name, dagwood.vet.street_address, dagwood.vet.street_address2, `${dagwood.vet.city}, ${dagwood.vet.state} ${dagwood.vet.postal_code}`, parsePhoneNumber(dagwood.vet.phone, 'US')?.formatNational()].map((attr, index) => (<span key={index}>{attr}</span>))}
                </>
              )}
            />
            <div style={{height:"1.25rem"}}/>
            <GanymedeKeyValueTable
              className={'total-table'}
              style={{width: '100%'}}
              data={detailsConfig}/>
            <GanymedeKeyValueTable
              className={'total-table'}
              style={{width: '100%', marginTop: '0.63rem'}}
              header={[`Total`, `${common.pennyIntToDollarString(dagwood?.plans?.[plan]?.monthly_price + dagwood?.plans?.[plan]?.enrollment_fee)}`]}
              />
            <p style={{fontSize: '0.88rem', color: '#828282'}}>Complete your care plan enrollment by providing your payment details and reviewing our membership agreement</p>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <GanymedePaymentCardDisplay
                    paymentMethods={dagwood.client.cards}
                    mode={paymentMode}
                    setMode={setPaymentMode}
                />
                <div className ="bottom-content-container">

                <div className={'membership-agreement'}>
                  <div className ='btn-container'>
                  <input id="agreementCheckbox" className={'agreement-checkbox'}
                         type="checkbox"
                         {...register("tos", { required: { value: true, message: 'You must agree to our Membership Agreement to proceed' } })}
                  />
                      <label className={'text-left sm:text-base'} htmlFor="agreementCheckbox">
                        YES, I understand that I will be charged monthly for {dagwood?.patient?.name}'s Snout wellness plan and I have read and agree to be bound by the <Link  to="../membership-agreement">Snout Membership Agreement</Link>.
                      </label>
                  </div>





                  <div className='flow-options-btn-container'>
                  <button type={'button'} className='back-button' onClick={() => { navigate(-1); }}>Back</button>
                  <div className="btn-container">
                    <ProgressButton state={isSubmitting || apiState?.status === 'success' ? 'disabled' : ''} durationSuccess={2000} ref={btnRef} controlled={true} onClick={() => {}} className={`complete-payment-button ${!isValid ? 'btn-disabled' : ''}`} type="submit">Complete Payment</ProgressButton></div>
                  </div>
                </div>
                {errors?.tos && <span className={'form-validation-error'}>{errors.tos.message}</span>}
                </div>
              </form>
            </FormProvider>

          </div>

        </GanymedePageContainer>
        <GanymedePageFooter />
      </>
  );
}

export default PlanCheckoutView;
