export const AMPLITUDE_EVENT_CODES = {
  // enrollment flow
  ENROLL_SENT: 'invitation-sent',
  ENROLL_OPENED: 'enroll-load',
  ENROLL_PLAN_SELECTED: 'enroll-plan-select',
  ENROLL_USER_INFORMATION_ENTERED: 'enroll-user-info',
  ENROLL_CC_INFO_ENTERED: 'enroll-cc',
  ENROLL_AGREEMENT_BOX_CHECKED: 'enroll-agreement-agree',
  ENROLL_ERROR: 'enroll-error',
  ENROLL_SUCCESS: 'enroll-success',

  // payment request
  REQUEST_SENT: 'payreq-sent',
  REQUEST_OPENED: 'payreq-load',
  REQUEST_CC_INFO_ENTERED: 'payreq-cc',
  REQUEST_ERROR: 'payreq-error',
  REQUEST_SUCCESS: 'payreq-success',

  // collections
  COLLECT_SENT: 'collections-sent',
  COLLECT_OPENED: 'collections-load',
  COLLECT_CC_INFO_ENTERED: 'collections-cc',
  COLLECT_ERROR: 'collections-error',
  COLLECT_SUCCESS: 'collections-success',

  // self registration
  SELF_ENROLL_NEW_OR_EXISTING: 'self-enroll-new-or-exists',
  SELF_ENROLL_NEW_CLIENT: 'self-enroll-new-client-info',
  SELF_ENROLL_CLIENT_LOOKUP_SUCCESS: 'self-enroll-client-lookup-success',
  SELF_ENROLL_CLIENT_LOOKUP_FAILURE: 'self-enroll-client-lookup-fail',
  SELF_ENROLL_SELECT_PET: 'self-enroll-select-pet',
  SELF_ENROLL_PET_NAME: 'self-enroll-pet-name',
  SELF_ENROLL_PET_SIZE: 'self-enroll-pet-size',
  SELF_ENROLL_PET_SEX_AGE: 'self-enroll-pet-sex-age',
  SELF_ENROLL_BILLING_DETAILS: 'self-enroll-billing-details',
  SELF_ENROLL_PET_SPECIES: 'self-enroll-pet-species',
  SELF_ENROLL_PET_SPAY_NEUTER: 'self-enroll-pet-spay-neuter',
  SELF_ENROLL_PET_REVIEW: 'self-enroll-pet-review',
  SELF_ENROLL_SELECT_PLAN: 'self-enroll-select-plan',
  SELF_ENROLL_PAYMENT_SUCCESS: 'self-enroll-payment-success',
  SELF_ENROLL_PAYMENT_FAILURE: 'self-enroll-payment-failure',
}