import {Link} from "react-router-dom";

function PagesView() {
  return (
      <div>
        <h1>Welcome to the Prototype Pages!</h1>
        <ul>
          <li><Link to="/prototype-pages/basic-invoice">Basic Invoice</Link></li>
          <li><Link to="/prototype-pages/checkout">Checkout</Link></li>
          <li><Link to="/prototype-pages/receipt">Receipt</Link></li>
          <li>Coverage Mapper</li>
        </ul>
      </div>
  );
}

export default PagesView;
