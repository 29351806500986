import { useForm } from 'react-hook-form';
import StepContainer from "../StepContainer";
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENT_CODES } from '../../../constants';
import DayJs from "dayjs";
DayJs.extend(require('dayjs/plugin/localizedFormat'));

function PetSize({state, dispatch}) {

  const { handleSubmit} = useForm({
  });

  const onSubmit = () => {
    track(AMPLITUDE_EVENT_CODES.SELF_ENROLL_PET_REVIEW, {
      vet: state.vet,
      client: state.client,
      pet: state.currentPet,
    });
    dispatch('next');
  }

  return (
    <StepContainer back={() => dispatch({type: 'prev'})}>
      <form className={'flex flex-col items-center h-full'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'font-roboto-slab text-3xl sm:text-4xl text-center mb-4'}>Here's what we've got for {state?.currentPet?.name[0].toUpperCase() + state?.currentPet?.name.slice(1)}:</div>
        <div className={'drop-shadow-lg w-[90%] sm:w-full flex flex-col px-4 py-4 border border-solid rounded-md border-stone-300 bg-white '}>
          <div className='flex flex-col items-center'>
            <div className='text-stone-500 text-sm items-center'>Pet Name</div>
            <div className='font-medium capitalize'>{state.currentPet.name}</div>
            <hr className='mx-4 border-0 bg-stone-300 h-[1px] w-[90%] my-4'/>
          </div>
          <div className='flex flex-col items-center'>
            <div className='text-stone-500 text-sm items-center'>Birthdate</div>
            <div className=' font-medium'>{`${DayJs(state.currentPet.birthdate).format('M/D/YYYY')}` }</div>
            <hr className='border-0 bg-stone-300 h-[1px]  my-4 w-[90%]'/>
          </div>
          <div className='flex flex-col items-center'>
            <div className='text-stone-500 text-sm items-center'>Gender</div>
            <div className=' font-medium'>{state.currentPet.sex === 'm' ? 'Male' : 'Female'}</div>
            <hr className='border-0 bg-stone-300 h-[1px] w-[90%] my-4'/>
          </div>
          <div className='flex flex-col items-center'>
            <div className='text-stone-500 text-sm items-center '>Size</div>
            <div className=' font-medium capitalize'>{state.currentPet.size}</div>
            <hr className='border-0 bg-stone-300 h-[1px] w-[90%] my-4'/>
          </div>
          <div className='flex flex-col items-center'>
            <div className='text-stone-500 text-sm items-center'>Spayed/Neutered?</div>
            <div className='font-medium capitalize'>{state.currentPet.spayNeuter}</div>
          </div>
        </div>
        <button className={'mt-auto sm:mt-8 w-full sm:w-40 btn-primary' } type="submit">Continue</button>
      </form>
    </StepContainer>
  );
}

export default PetSize;
