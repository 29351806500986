import { useForm } from 'react-hook-form';
import RadioButton from '../../../components/RadioButton/RadioButton.js'
import StepContainer from "../StepContainer";
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENT_CODES } from '../../../constants';

function SpayNeuter({state, dispatch}) {

  const { formState: { errors }, handleSubmit, register } = useForm({
    defaultValues: { spayNeuter: state?.currentPet?.spayNeuter }
  });

  const onSubmit = (values) => {
    track(AMPLITUDE_EVENT_CODES.SELF_ENROLL_PET_SPAY_NEUTER, {
      vet: state.vet,
      client: state.client,
      pet: { ...state.currentPet, spayNeuter: values.spayNeuter },
    });
    dispatch({ type: 'next', spayNeuter: values.spayNeuter });
  }

  return (
    <StepContainer back={() => dispatch({type: 'prev'})}>
      <form className={'flex flex-col items-center h-full'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'font-roboto-slab text-3xl sm:text-4xl text-center mb-4'}>Has {state?.currentPet?.name[0].toUpperCase() + state?.currentPet?.name.slice(1)} been spayed or neutered?</div>
        <div className="w-full grid grid-cols-1 grid-row-gap-2">
          <RadioButton label="Yes" id="radio-yes" name="radio-yes" value="yes" {...register('spayNeuter', { shouldUnregister: true, required: { value: true, message: 'Please choose an option above.' }})}/>
          <RadioButton label="No" id="radio-no" name="radio-no" value="no" {...register('spayNeuter', { shouldUnregister: true, required: { value: true, message: 'Please choose an option above.' }})}/>
          <RadioButton label="Not Sure" id="radio-not-sure" name="radio-not-sure" value="not sure" {...register('spayNeuter', { shouldUnregister: true, required: { value: true, message: 'Please choose an option above.' }})}/>
          <div className={'form-validation-error col-span-full -mt-2'}>{errors?.spayNeuter?.message}</div>
        </div>
        <button className={'mt-auto sm:mt-8 w-full sm:w-40 btn-primary'} type="submit">Continue</button>
      </form>
    </StepContainer>
  );
}

export default SpayNeuter;
