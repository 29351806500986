import BackArrow from "../../assets/svgs/BackArrow.svg";
import SnoutLogo from "../../assets/svgs/Snout.svg";

function StepContainer(props) {

  return (
    <div className='flex flex-col w-full h-full items-center bg-gray-50'>
      <div className={'w-full'}>
        <button className={`${props.back || '!hidden'} cursor-pointer block sm:hidden absolute left-[calc(5%)] top-3 bg-transparent border-0`} onClick={()=> props.back()}><div className='flex flex-rol items-center justify-center'><img className='w-3' src={BackArrow} alt='back'/><span className='text-stone-400 text-xl ml-2'>Back</span></div></button>
        <div className='bg-white flex flex-row justify-center items-center border-t-0 border-x-0 border-solid border-stone-200'>
          <img className='my-4' src={SnoutLogo} alt="snout-logo"/>
        </div>
      </div>
      <div className={`${props.wide && 'max-w-full sm:!max-w-[80%]'} w-full max-w-[520px] flex flex-col flex-grow sm:flex-grow-0`}>
        <button className={`${props.back || '!hidden'} cursor-pointer hidden sm:block absolute left-[calc(5%)] top-[11rem] bg-transparent border-0`} onClick={()=> props.back()}><img className='w-8' src={BackArrow} alt='back'/></button>
        <div className={'px-4 pb-8 pt-16 mt-4 sm:mt-14 flex-grow'}>
          {props.children}
        </div>
      </div>
    </div>
  );

}

export default StepContainer;
