import {useOutletContext} from 'react-router-dom';
import "react-progress-button/react-progress-button.css";

function HoldlessFinishRequestStep() {
  const outletContext = useOutletContext();

  const { } = outletContext;

  return (
    <div className={'finish-step-container'}>
      <div>
        <div className={'complete-payment-container'}>
          <div>
            <div>
              <h4>Terms and Conditions</h4>
              <div className={'charge-warning'}>By clicking the button below, you agree that this is the total value of services you have received today. Your care plan benefits will be applied and your payment method will be charged for any items and services outside of the plan within 24 hours.</div>
            </div>
          </div>
          </div>
      </div>
    </div>
  );
}

export default HoldlessFinishRequestStep;
