import { forwardRef } from 'react';
import './radio-button.css'

export const RadioButton = forwardRef(({ label, className, ...props }, ref ) => {
  return (
    <div className={className}>
      <input
        type="radio"
        name="radio-existing"
        className="hidden snout-radio"
        {...props}
        ref={ref}
      />
      <label
        htmlFor={props.id}
        className={`wizard flex items-center cursor-pointer text-l text-stone-500 px-3 py-3 rounded-md bg-white border border-solid border-stone-300`}
      >
        <span className="wizard w-5 h-5 inline-block mr-2 rounded-full bg-white border border-solid border-stone-200 flex-no-shrink"></span>
        {label}
      </label>
    </div>
  );
})

export default RadioButton;