import GanymedeKeyValueTable from "../../components/ganymede/KeyValueTable";
import './zoo.css';
import InvoiceColumnHeader from "../../components/ganymede/InvoiceColumnHeader";
import snoutIcon from "../../components/ganymede/snout.png";
import syossetIcon from "../../components/ganymede/syosset.png";
import GanymedeDocumentHeader from "../../components/ganymede/DocumentHeader";
import GanymedePageContainer from "../../components/ganymede/PageContainer";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import GanymedePageFooter from "../../components/ganymede/PageFooter";
import GanymedePaymentCardDisplay from '../../components/ganymede/GanymedePaymentCardDisplay';
import {useForm, FormProvider} from "react-hook-form";


function ZooView() {
  const formMethods = useForm();
  return (
      <div className={'Zoo'}>
        <h1>Welcome to the Zoo!</h1>
        <h2>Ganymede Component Set</h2>
        <span>These components were originally designed or inspired by Justin Chung for use in the Snout Wellness MVP. They're focused on financial and payment activity.</span>



        {/*** DOCUMENT HEADER EXAMPLE ***/}

        <div className={'ZooComponentExample'}>
          <div className={'ZooComponentDocumentation'}>
            <h3>DocumentHeader</h3>
            <p>
              DocumentHeader is intended for use as a page header on invoices and receipts to provide branding for the
              vet associated with the document and a clear title and date for the document in question.
            </p>
          </div>
          <div className={'ZooComponent'}>
            <h4>example</h4>
            <GanymedeDocumentHeader
                badge={<img alt="vet icon" src={syossetIcon} style={{ height: '5.50rem', width: '4.38rem'}}/>}
                supertext={'March 31, 2022'}
                title={'Bowser\'s Invoice'}
                attribution={(
                    <>
                      <span>Syosset Animal Hospital</span>
                      <span>123 Main St.</span>
                      <span>Seattle, WA 98015</span>
                      <span>(415) 823-2934</span>
                    </>
                )}
            />
            <h4>example source code</h4>
            <SyntaxHighlighter language="javascript" style={dark}>
              {`<GanymedeDocumentHeader
  badge={
    <img 
      src={syossetIcon} 
      style={{ height: '5.50rem', width: '4.81rem'}}
    />
  }
  supertext={'March 31, 2022'}
  title={'Bowser\\'s Invoice'}
  attribution={(
    <>
      <span>Syosset Animal Hospital</span>
      <span>123 Main St.</span>
      <span>Seattle, WA 98015</span>
      <span>(415) 823-2934</span>
    </>
  )}
/>`}
            </SyntaxHighlighter>
          </div>
        </div>



        {/*** PAGE FOOTER EXAMPLE ***/}

        <div className={'ZooComponentExample'}>
          <div className={'ZooComponentDocumentation'}>
            <h3>Example Component</h3>
            <p>
              PageFooter is a footer element intended for use at the bottom of the browser window which carries
              subtle SnoutID badging suitable for cases in which the vet branding takes precedence over our own.
            </p>
          </div>
          <div className={'ZooComponent'}>
            <h4>example</h4>
            <GanymedePageFooter></GanymedePageFooter>
            <h4>example source code</h4>
            <SyntaxHighlighter language="javascript" style={dark}>
              {`<GanymedePageFooter></GanymedePageFooter>`}
            </SyntaxHighlighter>
          </div>
        </div>



        {/*** INVOICE COLUMN HEADER EXAMPLE ***/}

        <div className={'ZooComponentExample'}>
          <div className={'ZooComponentDocumentation'}>
            <h3>InvoiceColumnHeader</h3>
            <div>
              InvoiceColumnHeader is a special label layout component intended for use as a column header in
              KeyValueTable.
            </div>
          </div>
          <div className={'ZooComponent'}>
            <h4>example</h4>
            <InvoiceColumnHeader
                labelTop={'Without'}
                labelBottom={'Wellness Plan'}
                value={'$1,565'}
                subscript={'Total'}
            />
            <h4>example source code</h4>
            <SyntaxHighlighter language="javascript" style={dark}>
              {`<InvoiceColumnHeader
  labelTop={'Without'}
  labelBottom={'Wellness Plan'}
  value={'$1,565'}
  subscript={'Total'}
/>`}
            </SyntaxHighlighter>
          </div>
        </div>



        {/*** KEY VALUE TABLE EXAMPLE ***/}

        <div className={'ZooComponentExample'}>
          <div className={'ZooComponentDocumentation'}>
            <h3>KeyValueTable</h3>
            <p>
              KeyValueTable is meant to be a generic client-facing data table for use in invoices,
              receipts, and other financial statements or illustrations.
            </p>
            <p>
              There are two primary modes for the KeyValueTable: simple key-value object presentation
              and complex nested data presentation.
            </p>
            <p>
              In the simple key-value object presentation mode, you need only pass to the
              <strong>&nbsp;data</strong> property an object containing a few properties in which key is a simple string
              label for the corresponding data value.
            </p>
            <p>
              In the complex presentation mode, you may additionally:
              <ul>
                <li>
                  pass one or more nested objects representing labeled groups of properties to be presented...
                  <SyntaxHighlighter language="javascript" style={dark}>
                    {`// so, instead of this:
{ one: 1, two: 2 }

// which results in something like:
// -------------
// | one |  1  |
// -------------
// | two |  2  |
// -------------

// you would do this:
{ letters:
  { ay: 'a', bee: 'b' },
  numbers:
  { one: 1, two: 2 }
}

// and see something like:
// -------------
// | letters   |
// -------------
// | ay  |  a  |
// -------------
// | bee |  b  |
// -------------
// | numbers   |
// -------------
// | one |  1  |
// -------------
// | two |  2  |
// -------------`}
                  </SyntaxHighlighter>
                </li>
                <li>
                  replace presentation values with arrays of values, each of which will be rendered in its own column
                  <SyntaxHighlighter language="javascript" style={dark}>
                    {`// so, instead of this:
{ one: 1, two: 2 }

// you would do this:
{ odds: [1, 2, 3],
  evens: [2, 4, 6]
}

// and the result would be:
// ---------------------
// | odds  | 1 | 3 | 5 |
// ---------------------
// | evens | 2 | 4 | 6 |
// ---------------------
`}
                  </SyntaxHighlighter>
                </li>
                <li>
                  include a <strong>header</strong> property which accepts an array of values which will be
                  rendered at the top of each column in a specially formatted row; this feature in intended to allow
                  for table and column titles
                </li>
                <li>
                  include a <strong>footer</strong> property which accepts an array of values which will be
                  rendered at the bottom of each column in a specially formatted row; this well-suited for
                  presentation of sums of columns
                </li>
              </ul>
            </p>
            <p className={'no-indent'}>
              NOTE: at this time, the CSS backing this component has only been tested in the simple scenario
              and the fully-featured scenario. If you attempt to add headers to the simple form, or other such
              permutations, you may find inconsistencies in the style which need to be addressed. Please don't
              be discouraged from such usage, instead please fix those styles so we can all benefit from such
              usage in the future! :)
            </p>
            <p className={'no-indent'}>
              <span className={'italicized'}>"OMFG Kyle, why have you done this?"</span>
              <br/><br/>
              It's complex, but I weighed this against building components for each permutation of the
              features supported and it felt right <span className={'italicized'}>to me</span>. Please have
              some patience with this component and help me figure out what is actually best for the code
              base. If you're struggling, let me know and I'm happy to help sort you out AND I'll even update
              the documentation for free in hopes that the next poor soul can avoid whatever pitfall you've
              encountered.
            </p>
          </div>
          <div className={'ZooComponent'}>
            <h4>simple object example</h4>
            <GanymedeKeyValueTable header={ 'Pet Details' } data={{ 'Pet Name':'Bowser', 'Sex':'Female', 'Species':'Dog' }}/>
            <h4>simple object example source code</h4>
            <SyntaxHighlighter language="javascript" style={dark}>
              {`<GanymedeKeyValueTable
header={ 'Pet Details' }
data={{ 
  'Pet Name':'Bowser',
  'Sex':'Female',
  'Species':'Dog' }}/>`}
            </SyntaxHighlighter>
            <h4>full-featured example</h4>
            <GanymedeKeyValueTable className={'GanymedeLarge'} header={[(<span>Bowser's<br/>Care Plan</span>), (<InvoiceColumnHeader labelTop={'Without'} labelBottom={'Wellness Plan'} value={'$1,565'} subscript={'Total'} />), (<InvoiceColumnHeader labelTop={(<span><img alt="" src={snoutIcon} style={{ height: '0.75rem', width: '0.75rem', display: 'inline', marginRight: '0.19rem'}}/>SnoutID</span>)} labelBottom={'Wellness Plan'} value={'$140'} subscript={'/month'} />), (<InvoiceColumnHeader labelTop={(<span><img alt="" src={snoutIcon} style={{ height: '0.75rem', width: '0.75rem', display: 'inline', marginRight: '0.19rem'}}/>SnoutID</span>)} labelBottom={'Complete Coverage'} value={'$190*'} subscript={'/month'} />)]}
                                   data={{
                                     'Today\'s Services': { 'Exam':['$120','Free','Free'], 'Rabies':['$35','Covered','Covered'], 'Bordetella':['$35', 'Covered', 'Covered'], 'Urine':['$120', 'Covered', 'Covered'] },
                                     'Future Care Plan': { 'Fecal':['$35','Covered','Covered'], 'Bloodwork':['$35','Covered','Covered'], 'Simparica':['$120','Covered','Covered'], 'Dental':['$35*','Covered','Covered'], 'Medication Delivery':['Not Included','Free','Free'], 'Accidentals':['Per Incident','Not Included','$50 /month*'], '24Hr Service via App':['Not Included','Free','Free'] },
                                   }}
                                   footer={['Due Today', '$310', '$140', '$140']}
                                   trailer={[(<></>), (<button>Pay Invoice</button>), (<button>Choose Plan</button>), (<button>Choose Plan</button>)]}
            />
            <h4>full-featured example source code</h4>
            <SyntaxHighlighter language="javascript" style={dark}>
              {`<GanymedeKeyValueTable

className={'GanymedeLarge'}

header={[
  (<span>Bowser's<br/>Care Plan</span>),
  (<InvoiceColumnHeader 
    labelTop={'Without'}
    labelBottom={'Wellness Plan'}
    value={'$1,565'}
    subscript={'Total'} />), 
  (<InvoiceColumnHeader
    labelTop={(<span><img
      src={snoutIcon}
      style={{ height: '0.75rem',
               width: '0.75rem',
               display: 'inline', 
               marginRight: '0.19rem'}}/>
      SnoutID</span>)}
    labelBottom={'Wellness Plan'}
    value={'$140'}
    subscript={'/month'} />),
  (<InvoiceColumnHeader
    labelTop={(<span><img
      src={snoutIcon}
      style={{ height: '0.75rem',
               width: '0.75rem', 
               display: 'inline', 
               marginRight: '0.19rem'}}/>
      SnoutID</span>)}
    labelBottom={'Complete Coverage'}
    value={'$190*'}
    subscript={'/month'} />)
]}

data={{
 'Today\\'s Services': {
   'Exam':['$120','Free','Free'],
   'Rabies':['$35','Covered','Covered'],
   'Bordetella':['$35', 'Covered', 'Covered'], 
   'Urine':['$120', 'Covered', 'Covered'] },
 'Future Care Plan': {
  'Fecal':['$35','Covered','Covered'],
  'Bloodwork':['$35','Covered','Covered'],
  'Simparica':['$120','Covered','Covered'],
  'Dental':['$35*','Covered','Covered'],
  'Medication Delivery':['Not Included','Free','Free'],
  'Accidentals':['Per Incident','Not Included','$50 /month*'],
  '24Hr Service via App':['Not Included','Free','Free'] },
}}

footer={['Due Today', '$310', '$140', '$140']}

trailer={[(<></>), 
(<button>Pay Invoice</button>), 
(<button>Choose Plan</button>), 
(<button>Choose Plan</button>)]}
/>`}
            </SyntaxHighlighter>
          </div>
        </div>



        {/*** PAGE CONTAINER EXAMPLE ***/}

        <div className={'ZooComponentExample'}>
          <div className={'ZooComponentDocumentation'}>
            <h3>PageContainer</h3>
            <p>
              PageContainer is a visually pleasing document margin treatment that lends the feel of a
              physical document to the contents. Skeuomorphism is back, baby! Yeeah!!
            </p>
          </div>
          <div className={'ZooComponent'}>
            <h4>example</h4>
            <GanymedePageContainer trailer={'pineapple belongs on pizza'}>
              <div>Hello, World!</div>
            </GanymedePageContainer>
            <h4>example source code</h4>
            <SyntaxHighlighter language="javascript" style={dark}>
              {`<GanymedePageContainer trailer={'pineapple belongs on pizza'}>
  <div>Hello, World!</div>
</GanymedePageContainer>`}
            </SyntaxHighlighter>
          </div>
        </div>



        {/*** PAYMENT CARD DISPLAY EXAMPLE ***/}

  <div className={'ZooComponentExample'}>
    <div className={'ZooComponentDocumentation'}>
      <h3>PaymentCardDisplay</h3>
      <p>
        PaymentCardDisplay is a reusable display to show the current card with brand icon and last 4,
        as well as displaying an optional 'change' CTA that is intended to kick the user over to a manage payment view
      </p>
    </div>
    <div className={'ZooComponent'}>
      <h4>example</h4>
       provide a real example instance of your component here
      <FormProvider {...formMethods}>
        <GanymedePaymentCardDisplay  allowChange={true} onRequestChange={() => console.log('poop') } />
      </FormProvider>
      <h4>example source code</h4>
      <SyntaxHighlighter language="javascript" style={dark}>
        {`<GanymedePaymentCardDisplay 
  brand={'visa'} // card brand
  last4={'6969'} // last 4 of the credit card number
  allowChange={true} // bool to show 'change' link
  // callback when user clicks 'change' link
  onRequestChange={() => console.log('poop')}
/>`}
      </SyntaxHighlighter>
    </div>
  </div>



        {/*** EXAMPLE COMPONENT EXAMPLE - FOR USE AS A TEMPLATE PLEASE DO NOT REMOVE ***/}

        <div className={'ZooComponentExample'}>
          <div className={'ZooComponentDocumentation'}>
            <h3>Example Component</h3>
            <p>
              Hey! describe the purpose of your component...
            </p>
          </div>
          <div className={'ZooComponent'}>
            <h4>example</h4>
            {/* provide a real example instance of your component here */}
            <button>Example Component</button>
            <h4>example source code</h4>
            <SyntaxHighlighter language="javascript" style={dark}>
              {`<!-- Show us the source code your your instance example.
     Remember! Whitespace matters here, so go ahead and 
     let your indentation be a little funky so that the
     documentation is nice and readable. -->
     
<ExampleComponent value={'potato'} />`}
            </SyntaxHighlighter>
          </div>
        </div>



      </div>
  );
}

export default ZooView;
