import {useOutletContext} from "react-router-dom";
import GanymedePaymentCardDisplay from "../../components/ganymede/GanymedePaymentCardDisplay";
import GanymedePageContainer from "../../components/ganymede/PageContainer";
import GanymedeKeyValueTable from "../../components/ganymede/KeyValueTable";
import GanymedePageFooter from "../../components/ganymede/PageFooter";
import GanymedeDocumentHeader from "../../components/ganymede/DocumentHeader";
import {FormProvider, useForm} from "react-hook-form";
import parsePhoneNumber from 'libphonenumber-js';
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENT_CODES } from '../../constants';

function PaymentRequestView({ paymentCard, selectedPlan = 'wellness', setSelectedPlan }) {

  const formMethods = useForm({mode: 'onChange'});
  const {handleSubmit} = formMethods;

  const { dagwood, onSubmitPaymentInformation, checkout, plan, paymentMode, setPaymentMode } = useOutletContext();

  const onSubmit = async (form) => {
    track(AMPLITUDE_EVENT_CODES.REQUEST_CC_INFO_ENTERED, {
      user: { ...dagwood.client, cards: undefined, patients: undefined, personalDetails: undefined },
      pet: dagwood.patients,
      amount: dagwood.invoice_amount,
      card: form.paymentMethod.external_id ? 'existing' : 'new',
    });
    onSubmitPaymentInformation(form.paymentMethod);
  }

  return (
      <>
        <div className={'subscription-flow flex flex-row text-left items-center px-3 py-1.5 md:px-10 md:py-3 lg:px-20 lg:py-6 bg-white'}>
          <img className={'w-20 mr-2.5'} alt={dagwood?.vet?.name} src={dagwood?.vet?.logo_url} /><span className={'text-2xl sm:text-3xl md:text-4xl font-normal'}>{dagwood?.vet?.name}</span>
        </div>
        <GanymedePageContainer className={'plan-checkout-container'}>
          <div className={'InvoiceHeader'}>
            <GanymedeDocumentHeader
              title={`Invoice`}
              attribution={(
                <>
                  {[dagwood?.vet?.name, dagwood?.vet?.street_address, dagwood?.vet?.street_address2, `${dagwood?.vet?.city}, ${dagwood?.vet?.state} ${dagwood?.vet?.postal_code}`, dagwood?.vet?.phone && parsePhoneNumber(dagwood?.vet?.phone, 'US')?.formatNational()].map((attr, index) => (<span key={index}>{attr}</span>))}
                </>
              )}
            />
            <GanymedeKeyValueTable className={'ganymede-full-width payment-request-total'} header={['Total Invoice Amount', dagwood?.invoice_amount]}/>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <GanymedePaymentCardDisplay
                    allowChange={true}
                    paymentMethods={dagwood?.client?.cards}
                    mode={paymentMode}
                    setMode={setPaymentMode}
                />
                <div className={'next-btn-container'}>
                  <button className={'!w-full sm:!w-[7.5rem]'} type={'submit'}>Next</button>
                </div>
              </form>
            </FormProvider>
          </div>
        </GanymedePageContainer>
        <GanymedePageFooter />
      </>
  );
}

export default PaymentRequestView;
