import StepContainer from "../StepContainer";

function UserNotFoundStep({state, dispatch}) {

  return (
    <StepContainer back={() => dispatch({type: 'prev'})}>
      <div className={'flex flex-col items-center h-full'}>
        <div className={'font-roboto-slab text-3xl sm:text-4xl text-center'}>We couldn't find anyone by that email.</div>
        <div className={'text-gray-500 text-center my-5'}>Try again or click Continue to enter your information manually.</div>
        <div className={'flex justify-center mt-auto sm:mt-0 gap-2 flex-wrap'}>
          <button onClick={() => dispatch('prev')} className={'w-full mr-0 sm:mr-3 sm:w-40 hover:bg-orange-50 cursor-pointer bg-white text-snout-orange border border-snout-orange border-solid rounded-md py-3 px-6 font-bold font-roboto'}>Try Again</button>
          <button onClick={() => dispatch('next')} className={'w-full sm:w-40 hover:bg-orange-700 cursor-pointer bg-snout-orange text-white border-0 rounded-md py-3 px-6 font-bold font-roboto'}>Continue</button>
        </div>
      </div>
    </StepContainer>
  );
}

export default UserNotFoundStep;
