import { Outlet, Navigate } from 'react-router-dom';

const ProtectedRoute = () => {
  if (!localStorage.getItem('token')) {
    return <Navigate to={'/europa/login'} replace />
  }
  return <Outlet />
}

export default ProtectedRoute;
