import {useOutletContext} from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';
import "react-progress-button/react-progress-button.css";
import Select from 'react-select';
import { useEffect, useState } from 'react';
import GanymedeInput from '../../components/ganymede/GanymedeInput';
import common from "../../lib/common";

const MethodOption = ({ innerRef, innerProps, data, ...props }) => {
  return (
    <div className={`complete-option ${data.isDisabled ? 'disabled' : ''} ${props.className}`} ref={innerRef} {...innerProps}>
      <div>{data?.text}{data?.isDisabled ? ' (cannot use this method with multiple patients)' : ''}</div>
    </div>
  );
}

const PlanOption = ({ innerRef, innerProps, data, ...props }) => {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setSelected(props.isSelected);
  }, [props.isSelected]);
  return (
    <div className={`plan-option ${selected ? 'selected' : ''}`} ref={innerRef} {...innerProps}>
      <input checked={selected} onChange={() => {}} type={'checkbox'} />
      <div>{data?.value?.name} - {common.pennyIntToDollarString(data?.value?.monthly_price)}/mo.</div>
    </div>
  );
}

function InvitationDetailsStep() {
  const outletContext = useOutletContext();
  const { resetField, control, formState: { errors }, watch, trigger, register } = useFormContext();

  const getStatusConfig = (patient) => {
    if (patient?.plan_instance) {
      if (patient.payment_status === 'overdue') {
        return {
          text: 'Payment overdue',
          badge: 'red',
        }
      } else if (patient.payment_status === 'attempting payment') {
        return {
          text: 'Payment pending',
          badge: 'yellow',
        }
      }
      return {
        text: 'Enrolled',
        badge: 'green',
      }
    }
    else if (!patient?.species) {
      return {
        text: 'Not eligible for plan',
        badge: 'grey',
      };
    }
    else if (patient?.invitation?.external_id) {
      return {
        text: 'Invited, not enrolled',
        badge: 'yellow',
      };
    }
    else {
      return {
        text: 'Not enrolled',
        badge: 'grey',
      };
    }
  }

  const patients = watch('patients');
  const codeDisabled = patients?.filter(p => p.selected).length > 1;
  const requestCompletionMethods = [
    // { method: 'same_device', text: 'Enroll via this device', details: 'Click on the "Next" button below and hand this device over to the client'},
    { method: 'email', text: 'Invite via email', details: `We will send the invite to the specified email address`},
    { method: 'SMS', text: 'Invite via SMS', details: `We will send the invite to the specified phone number via text`},
    { method: 'code',
      text: 'Invite via unique code',
      isDisabled: codeDisabled,
      details: codeDisabled ? 'You cannot use this method for more than one patient. Please select the email option.' : `We will generate a unique code for your client to enroll. Be sure to share the code with them!`},
  ]

  const { fields, catPlans, dogPlans } = outletContext;

  const requestMethodTransformations = {
    input: (value) => {
      return value ? { label: <MethodOption data={value} className={'label'} />, value} : value;
    },
    output: (e) => {
      return e?.method;
    }
  }

  const planTransformations = {
    input: (values) => {
      return values ? values.map((v) => ({ label: v.name, value: v})) : values;
    },
    output: (e) => {
      return e?.map(selectObj => selectObj.value);
    }
  }

  return (
    <div className={'wellness-request-details-container'}>
      <div className={'patient-information-container'}>
        <div className={'patient-select-container'}>
          <div className={'d-grid grid-col-gap-2 grid-row-gap-2 grid-cols-2'}>
            {fields.map(({ id, patient }, i) => {
                // register select and checkboxes for each patient
                return (<label htmlFor={`patient-option-checkbox-${i}`}
                  className={`disabled-checkbox patient-option-display grid-span-1 ${patients?.[i]?.selected ? 'selected' : ''}`} key={id}>
                  <div className={'patient-option-heading'}>
                    <div className={'patient-option-checkbox'}>
                      <input disabled id={`patient-option-checkbox-${i}`}
                        type={'checkbox'} {...register(`patients.${i}.selected`, {
                        onChange: () => trigger('patients'),
                        validate: (val) => {
                          // at least 1 patient in the form array must be selected
                          return patients?.find((p) => p.selected) || 'Select at least 1 patient';
                        }
                      })} />
                    </div>
                    <div className={'patient-heading'}>
                      <span
                        className={'patient-option-species'}>{patient?.species && `${(patient.species.toLowerCase() === 'dog' || patient.species.toLowerCase() === 'd') ? '🐶' : '🐱'}`}&nbsp;</span>
                      <span className={'patient-option-name'}>{patient?.name}</span>
                      <span className={`patient-option-badge ${getStatusConfig(patient).badge}`}>
                        {getStatusConfig(patient).text}
                      </span>
                    </div>
                  </div>
                  <div className={'patient-option-plan-select'}>
                    {patient?.plan_instance &&
                    <div className={'patient-plan-display'}>
                      <div>Current Plan</div>
                      <div>{patient?.plan_instance.name}</div>
                    </div>
                    }
                    {!patient?.plan_instance &&
                    <div className={'patient-plan-select'}>
                      <Controller
                        name={`patients.${i}.plans`}
                        control={control}
                        rules={{ required: { value: patients?.[i]?.selected, message: 'Select plans to offer' } }}
                        render={({ field }) => {
                          return <div onClick={e => e.preventDefault()}>
                            <Select
                              isSearchable={false}
                              isDisabled={!patients?.[i]?.selected}
                              className={'ganymede-select'}
                              onFocus={e => e.preventDefault()}
                              isMulti={true}
                              value={planTransformations.input(field.value)}
                              placeholder={'Select plans to offer'}
                              defaultOptions={(patient?.species?.toLowerCase() === 'cat' ? catPlans : dogPlans)?.map((plan) => ({
                                label: plan.name, value: plan,
                              }))}
                              options={(patient?.species?.toLowerCase() === 'cat' ? catPlans : dogPlans)?.map((plan) => ({
                                label: plan.name, value: plan,
                              }))}
                              onChange={e => field.onChange(planTransformations.output(e))}
                              noOptionsMessage={() => `No plans found for species: ${patient?.species}`}
                              closeMenuOnSelect={false}
                              components={{Option: PlanOption}}
                              hideSelectedOptions={false}
                            />
                            {errors?.patients?.[i]?.plans &&
                            <span className={'form-validation-error'}>{errors?.patients?.[i]?.plans.message}</span>
                            }
                          </div>
                        }
                        }
                      />
                    </div>
                    }
                  </div>
                </label>)
              }
            )}
          </div>
        </div>
      </div>
      <div className={'enrollment-referral-name'}>
        <div>Employee Name (Optional for Referrals)</div>
        <GanymedeInput type={'text'} label={'Your Name'} errors={errors} {...register('employee_name')} />
      </div>
      <div className={'complete-charge-select'}>
        <div>How do you want to enroll the client?</div>
        <Controller
        name={`invitationMethod`}
        defaultValue={requestCompletionMethods[0].method}
        rules={{ required: { value: true, message: 'Select an enrollment method'} }}
        control={control}
        render={({ field }) =>
          <>
            <Select
              isSearchable={false}
              value={requestMethodTransformations.input(requestCompletionMethods.find((config) => config.method === field.value))}
              placeholder={'Select an enrollment method'}
              defaultOptions={requestCompletionMethods}
              options={requestCompletionMethods}
              onChange={e => field.onChange(requestMethodTransformations.output(e))}
              components={{ Option: MethodOption }}
            />
            {errors?.[field.name] && <span className={'form-validation-error'}>{errors?.[field.name]?.message}</span>}
          </>
        }
        />
      </div>
    </div>
  );
}

export default InvitationDetailsStep;
