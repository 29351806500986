import './ganymede.css'

function RenderObject (props) {
  return (
    <>
      {
        Object.keys(props.value).map((key, i) =>
            <tr key={key}>
              <td>
                {/* capitalize text of first child */}
                {i === 0 && typeof(key) === 'string' && key.length ? key.split(' ').map(str => str[0].toUpperCase().concat(str.slice(1))).join(' ') : key}
              </td>
              {
                (Array.isArray(props.value[key]) &&
                props.value[key].map((value, index) =>
                    <td key={`${key}${index}`}>
                      {value}
                    </td>
                )) ||
                (<td>
                  { props.value[key] }
                </td>)
              }
            </tr>
        )
      }
    </>
  );
}

function RenderGroups (props) {
  // if we see a nested object in the zeroth key then we assume we're dealing with grouped data
  const zerothValue = props.data[Object.keys(props.data)[0]];
  if (typeof zerothValue === 'object' && !Array.isArray(zerothValue) && zerothValue['$$typeof'] === undefined) {
    return (
        <>
          {
            Object.keys(props.data).map( key =>
                <tbody className={'grouped'} key={key}>
                  <tr>
                    <th colSpan={ 100 }>{ key }</th>
                  </tr>
                  <RenderObject value={props.data[key]} />
                </tbody>
            )
          }
        </>
    );
  }
  // otherwise directly render values
  return (
      <tbody>
        <RenderObject value={props.data} />
      </tbody>
  );
}

function GanymedeKeyValueTable (props) {
  return (
      <table style={props.style} className={`${props.className || ''} GanymedeKeyValueTable`}>
        {props.header && <thead>
        <tr>
          {
            (Array.isArray(props.header) &&
              props.header.map((value, index) =>
                <th key={index}>
                  {/* capitalize text of first child */}
                  {index === 0 && typeof (value) === 'string' && value.length ? value.split(' ').map(str => str[0].toUpperCase().concat(str.slice(1))).join(' ') : value}
                </th>
              )) ||
            (<th colSpan={100}>
              {props.header}
            </th>)
          }
        </tr>
        </thead>}
        {props.data && <RenderGroups data={props.data} />}
        <tfoot>
        {
          Array.isArray(props.footer) &&
          (
              <tr className={'footer'}>
                {
                  props.footer.map((value, index) =>
                      <td key={index}>
                        {value}
                      </td>
                  )
                }
              </tr>
          )
        }
        {
          Array.isArray(props.trailer) &&
          (
              <tr className={'trailer'}>
                {
                  props.trailer.map((value, index) =>
                      <td colSpan={props.trailer.length === 1 ? 3 : ''} key={index}>
                        {value}
                      </td>
                  )
                }
              </tr>

          )
        }
        </tfoot>
      </table>
  );
}

export default GanymedeKeyValueTable;
