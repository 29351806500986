import { useOutletContext, useNavigate } from "react-router-dom";
import GanymedePageContainer from "../../components/ganymede/PageContainer";
import GanymedePageFooter from "../../components/ganymede/PageFooter";
import GanymedeDocumentHeader from "../../components/ganymede/DocumentHeader";
import { FormProvider, useForm } from "react-hook-form";
import GanymedeInput from "../../components/ganymede/GanymedeInput";
import GanymedeKeyValueTable from "../../components/ganymede/KeyValueTable";
import common from "../../lib/common";
import parsePhoneNumber, {isPossiblePhoneNumber} from "libphonenumber-js";
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENT_CODES } from '../../constants';

function PersonalDetailsView() {

  const { plan, dagwood, personalDetails, setPersonalDetails } = useOutletContext();

  const formMethods = useForm({mode: 'onChange'});
  const {handleSubmit, register, formState: { errors }} = formMethods;

  const navigate = useNavigate();

  const onSubmit = async (form) => {
    // track set personal details step in amplitude
    track(AMPLITUDE_EVENT_CODES.ENROLL_USER_INFORMATION_ENTERED, {
      user: { ...dagwood.client, cards: undefined, patients: undefined, personalDetails: undefined },
      pet: dagwood.patient,
      plan: dagwood.plans[plan],
    });
    setPersonalDetails(form);
    navigate('../checkout');
  }

  //Checks to see whether there is a non-zero enrollment fee
  const getPlanDetails = () => {

  let planDetails = {'Plan': dagwood?.plans?.[plan]?.name, 'Monthly Payment': common.pennyIntToDollarString(dagwood?.plans?.[plan]?.monthly_price)}
  const enrollmentFeeAmt = dagwood?.plans?.[plan]?.enrollment_fee

  if( enrollmentFeeAmt > 0) {
    planDetails['Enrollment fee'] = common.pennyIntToDollarString(enrollmentFeeAmt)
    }
    return planDetails;
  }


  return (
      <>
        <div className={'subscription-flow flex flex-row text-left items-center px-3 py-1.5 md:px-10 md:py-3 lg:px-20 lg:py-6 bg-white'}>
          <img className={'w-20 mr-2.5'} alt={dagwood?.vet?.name} src={dagwood?.vet?.logo_url} /><span className={'text-2xl sm:text-3xl md:text-4xl font-normal'}>{dagwood?.vet?.name}</span>
        </div>
        <GanymedePageContainer className={'plan-checkout-container'}>
          <div className={'InvoiceHeader'}>
            <GanymedeDocumentHeader
                title="Care Plan Enrollment"
                attribution={(
                    <>
                      {[dagwood.vet.name, dagwood.vet.street_address, dagwood.vet.street_address2, `${dagwood.vet.city}, ${dagwood.vet.state} ${dagwood.vet.postal_code}`, parsePhoneNumber(dagwood.vet.phone, 'US')?.formatNational()].map((attr, index) => (<span key={index}>{attr}</span>))}
                    </>
                )}
            />
            <div style={{height:"1.25rem"}}/>
            <GanymedeKeyValueTable
                className={'total-table'}
                style={{width: '100%'}}
                data={getPlanDetails()}/>
            <GanymedeKeyValueTable
                className={'total-table'}
                style={{width: '100%', marginTop: '0.63rem'}}
                header={[`Total`, `${common.pennyIntToDollarString(dagwood?.plans?.[plan]?.monthly_price + dagwood?.plans?.[plan]?.enrollment_fee)}`]}
            />
            <p style={{fontSize: '0.88rem', color: '#828282'}}>Please provide your billing information to complete your care plan purchase.</p>
            <h3 style={{'margin-bottom': '1.25rem', 'margin-top': '0'}}>Enter Billing Information</h3>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={'flex flex-col'}>
                  <div className="billing-info-form grid grid-cols-7 gap-x-7 gap-y-0">
                    <h4 className={'col-span-full'}>Contact Information</h4>
                    <div className="ganymede-input form-control-container col-span-full sm:col-span-3">
                      <GanymedeInput
                          label="First Name"
                          type="text"
                          errors={errors}
                          {...register("firstName", { value: personalDetails?.firstName, required: { value: true, message: 'You must enter your first name.' } })}
                      />
                    </div>
                    <div className="ganymede-input form-control-container col-span-full sm:col-span-4">
                      <GanymedeInput
                          label="Last Name"
                          type="text"
                          errors={errors}
                          {...register("lastName", { value: personalDetails?.lastName, required: { value: true, message: 'You must enter your last name.' } })}
                      />
                    </div>
                    <div className="ganymede-input form-control-container col-span-full sm:col-span-3">
                      <GanymedeInput
                          label="Email Address"
                          type="text"
                          errors={errors}
                          {...register("emailAddress", {
                            value: personalDetails?.emailAddress,
                            pattern: {
                              value: /.+@.+\..+/,
                              message: "Email is invalid",
                            },
                            required: { value: true, message: 'You must enter your email address.' }
                          })}
                      />
                    </div>
                    <div className="ganymede-input form-control-container col-span-full sm:col-span-4">
                      <GanymedeInput
                          label="Phone Number"
                          type="text"
                          errors={errors}
                          {...register("phoneNumber", {
                            value: personalDetails?.phoneNumber ? parsePhoneNumber(personalDetails?.phoneNumber, 'US')?.formatNational() : personalDetails?.phoneNumber,
                            required: { value: true, message: 'You must enter your phone number.' } ,
                            validate: (value) => {
                              return (
                                  isPossiblePhoneNumber(value, "US") ||
                                  "Invalid phone number"
                              );
                            }
                          })}
                      />
                    </div>
                    <h4 className={'col-span-full'}>Billing Address</h4>
                    <div className="ganymede-input form-control-container col-span-full">
                      <GanymedeInput
                          label="Street Address"
                          type="text"
                          errors={errors}
                          {...register("streetAddress", { value: personalDetails?.streetAddress, required: { value: true, message: 'You must enter your street address.' } })}
                      />
                    </div>
                    <div className="ganymede-input form-control-container col-span-full sm:col-span-2">
                      <GanymedeInput
                          label="City"
                          type="text"
                          errors={errors}
                          {...register("city", { value: personalDetails?.city, required: { value: true, message: 'You must enter your city.' } })}
                      />
                    </div>
                    <div className="ganymede-input form-control-container col-span-full sm:col-span-2">
                      <GanymedeInput
                          label="State"
                          type="text"
                          errors={errors}
                          {...register("state", { value: personalDetails?.state, required: { value: true, message: 'You must enter your state.' } })}
                      />
                    </div>
                    <div className="ganymede-input form-control-container col-span-full sm:col-span-3">
                      <GanymedeInput
                          label="ZIP Code"
                          type="text"
                          errors={errors}
                          //value={personalDetails?.zip}
                          {...register("zip", { value: personalDetails?.zip, required: { value: true, message: 'You must enter your zip code.' } })}
                      />
                    </div>
                    {/*{errors?.clientNameField && <span className={'form-validation-error'}>{errors.clientNameField.message}</span>}*/}
                  </div>
                  <div className ='flow-options-btn-container'>
                    <button type={'button'} className="back-button" onClick={() => { navigate(-1); }}>Back</button>
                    <button onClick={() => {}} className={`navigation-button`} type="submit">Next: Payment Information</button>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>

        </GanymedePageContainer>
        <GanymedePageFooter />
      </>
  );
}

export default PersonalDetailsView;
