import { useState } from 'react';

const Wizard = ({definition, initialState}) => {

  const [state, setState] = useState({
    ...initialState, // allows arguments to be passed into the wizard
    wizard: { // this section of state is private to the wizard; steps cannot access this!
      currentStep: Object.keys(definition.steps)[0], // the first step listed in the definition is the initial step
    }
  });

  const dispatcher = (action) => {
    // if this function is called with just the string then turn it into a payload-less action
    // this feature is provided for the convenience of steps which will often dispatch actions
    if (typeof action === 'string') {
      action = { type: action };
    }

    // lookup the reducer for this action based upon the current step and the action type
    let reducer = definition.steps[state.wizard.currentStep].actions[action.type];

    // if the reducer is just a string, generate a simple step navigation reducer using the string as the destination step name
    // this feature is provided for the convenience of wizard definitions which will often define such reducers
    if (typeof reducer === 'string') {
      const stepName = reducer;
      reducer = (state, action) => {
        state.wizard.currentStep = stepName;
      }
    }

    // do the flux needful part one; execute the reducer
    let oldState = state;
    let newState = JSON.parse(JSON.stringify(state));
    reducer(newState, action);

    // if a step transition has occurred and the destination step has an onArrival function, then call it!
    // (and keep doing that until this stops resulting in step transitions...)
    while (oldState.wizard.currentStep !== newState.wizard.currentStep
        && definition.steps[newState?.wizard?.currentStep]?.onArrival) {
      const from = oldState.wizard.currentStep;
      oldState = newState;
      newState = JSON.parse(JSON.stringify(oldState));
      definition.steps[newState?.wizard?.currentStep]?.onArrival(newState, from);
    }

    // do the flux needful part two; update state with the result of the reducer (plus other shenanigans above...)
    setState(newState);
  };

  const stateCopy = JSON.parse(JSON.stringify(state)); // making a deep copy of state so steps cannot directly mutate
  delete stateCopy.wizard; // this section of state is private to the wizard; steps cannot access this!

  const CurrentStep = definition.steps[state.wizard.currentStep].component;

  return (
    <CurrentStep state={stateCopy} dispatch={dispatcher} args={definition.steps[state.wizard.currentStep].args} />
  );
};


export default Wizard;
