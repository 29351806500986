import './App.css';
import { lazy, Suspense } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import ProtectedRoute from './components/protected-route';
import { init } from '@amplitude/analytics-browser';

import ZooView from "./dev/zoo/Zoo";
import PagesView from "./dev/pages/Pages";
import BasicInvoiceView from "./dev/pages/basic-invoice/BasicInvoice";
import DevMenuView from "./dev";
import Checkout from './dev/pages/checkout/Checkout';
import Receipt from './dev/pages/receipt/Receipt';
import InvoiceViewController from './client/InvoiceViewController';
import InvoiceView from './client/InvoiceView';
import InvoiceLoadingView from './client/InvoiceLoadingView';
import InvoiceReceiptView from "./client/InvoiceReceiptView";
import InvoiceSelectionView from "./client/InvoiceSelectionView";
import InvoicePaymentView from "./client/InvoicePaymentView";
import InvitationCodeEntryView from "./client/subscription/InvitationCodeEntryView";
import PlanChooserView from "./client/subscription/PlanChooserView";
import InvitationView from "./client/subscription/InvitationView";
import PlanCheckoutView from "./client/subscription/PlanCheckoutView";
import SubscriptionReceiptView from "./client/subscription/SubscriptionReceiptView";
import PracticeAuthenticator from './practice/practice-authenticator/PracticeAuthenticator';
import InvitationLoadingView from "./client/subscription/InvitationLoadingView";
import SubscriptionCollectionsLoadingView from './client/collections/SubscriptionCollectionsLoadingView';
import SubscriptionCollectionsPaymentView from './client/collections/SubscriptionCollectionsPaymentView';
import SubscriptionCollectionsPendingChargeView from './client/collections/SubscriptionCollectionsPendingChargeView';
import SubscriptionCollectionsPaymentOkView from './client/collections/SubscriptionCollectionsPaymentOkView';
import ClientInformationStep from './practice/wellness/ClientInformationStep';
import PatientInformationStep from './practice/wellness/PatientInformationStep';
import RequestDetailsStep from './practice/wellness/RequestDetailsStep';
import PaymentInformationStep from './practice/wellness/PaymentInformationStep';
import FinishRequestStep from './practice/wellness/FinishRequestStep';
import HoldlessFinishRequestStep from './practice/wellness/HoldlessFinishRequestStep';
import CodeDisplayStep from './practice/wellness/CodeDisplayStep';
import SendEmailStep from './practice/wellness/SendEmailStep';
import SendSmsStep from './practice/wellness/SendSmsStep';
import InvitationDetailsStep from './practice/wellness/InvitationDetailsStep';
import PaymentRequestEntryView from './client/payment-request/PaymentRequestEntryView';
import PaymentRequestLoadingView from './client/payment-request/PaymentRequestLoadingView';
import PaymentRequestView from './client/payment-request/PaymentRequestView';
import PaymentRequestCheckoutView from './client/payment-request/PaymentRequestCheckoutView';
import PaymentRequestFinishView from './client/payment-request/PaymentRequestFinishView';
import PaymentRequestReceiptView from './client/payment-request/PaymentRequestReceiptView';
import ServiceAgreement from './client/contracts/ServiceAgreement';
import PersonalDetailsView from "./client/subscription/PersonalDetailsView";
import ReceiptStep from './practice/wellness/ReceiptStep';
import SnoutLoadingDots from './components/snout-loading-dots/SnoutLoadingDots';
import WizardFlow from './client/SelfRegistration/ClientSelfRegWizard';
import PlanReceipt from './client/PlanReceipt/PlanReceipt';
import PatientPlanUsage from './practice/wellness/PatientPlanUsage';

const CreatePasswordResetLink = lazy(() => import('./pages/europa/CreatePasswordResetLink'));
const ResetPassword = lazy(() => import('./pages/europa/ResetPassword'));
const Login = lazy(() => import('./pages/europa/Login'));
const VetListing = lazy(() => import('./pages/europa/VetListing'));
const VetCreate = lazy(() => import('./pages/europa/VetCreate'));
const VetUpdate = lazy(() => import('./pages/europa/VetUpdate'));
const VetDetails = lazy(() => import('./pages/europa/VetDetails'));
const PlanListing = lazy(() => import('./pages/europa/PlanListing'));
const PlanCreate = lazy(() => import('./pages/europa/PlanCreate'));
const PlanDetails = lazy(() => import('./pages/europa/PlanDetails'));
const PlanInstanceListing = lazy(() => import('./pages/europa/PlanInstanceListing'));
const PlanInstanceDetails = lazy(() => import('./pages/europa/PlanInstanceDetails'));
const InvoiceListing = lazy(() => import('./pages/europa/InvoiceListing'));
const VetInvoiceListing = lazy(() => import('./pages/europa/VetInvoiceListing'));
const PaymentRequestListing = lazy(() => import('./pages/europa/PaymentRequestListing'));
const InvoiceDetails = lazy(() => import('./pages/europa/InvoiceDetails'));
const InvitationListing = lazy(() => import('./pages/europa/InvitationListing'));
const Clients = lazy(() => import('./pages/europa/TestData/Clients'));
const Patients = lazy(() => import('./pages/europa/TestData/Patients'));
const InvoiceCreate = lazy(() => import('./pages/europa/TestData/Invoice'));
const PatientDetails = lazy(() => import('./pages/europa/PatientDetails'));
const AddonListing = lazy(() => import('./pages/europa/AddonListing'));
const AlertConfig = lazy(() => import('./pages/europa/AlertConfig'));
const WellnessViewController = lazy(() => import('./practice/wellness/WellnessViewController'));
const SubscriptionViewController = lazy(() => import('./client/subscription/SubscriptionViewController'));
const SubscriptionCollectionsViewController = lazy(() => import('./client/collections/SubscriptionCollectionsViewController'));
const PaymentRequestViewController = lazy(() => import('./client/payment-request/PaymentRequestViewController'));

function App() {
  // initialize amplitude
  if (process.env.NODE_ENV === 'production' && window?.location?.host === 'wellness.snoutid.com') init('e42a18cbef7615867290c3c1aaffd2fa');

  // if we're in dev and landing on the root path, 99% of the time we're headed for the demo practice so just go there
  if (window.location.protocol === 'http:' && window.location.host === 'localhost:3000' && window.location.pathname === '/') {
    window.location = 'http://localhost:3000/practice/demo/wellness';
  }

  return (
    <Router>
      <Suspense fallback={<div style={{ display: 'flex', flexDirection: 'column', flex: '1', justifyContent: 'center', alignItems: 'center' }}><SnoutLoadingDots /></div>}>
          <Routes>
            {/* ADMIN Routes */}
            <Route exact path="/europa" element={<Navigate to="/europa/login" replace />} />
            <Route exact path="/europa/login" element={<Login />} />   {/* This is the redirect target for ProtectedRoute */}
            <Route exact path="/europa/reset" element={<CreatePasswordResetLink />} />
            <Route exact path="/europa/password/:resetToken" element={<ResetPassword />} />
            <Route exact path='/europa' element={<ProtectedRoute />}>
              <Route exact path="invoices" element={<InvoiceListing />} />
              <Route exact path="vet/:vet_id/invoices" element={<VetInvoiceListing />} />
              <Route exact path="vet/:vet_id/invoice/create" element={<InvoiceCreate />} />
              <Route exact path="vet/:vet_id/payment_requests" element={<PaymentRequestListing />} />
              <Route exact path="vets" element={<VetListing />} />
              <Route exact path="vet/create" element={<VetCreate />} />
              <Route exact path="vet/:id" element={<VetDetails />} />
              <Route exact path="vet/:id/update" element={<VetUpdate />} />
              <Route exact path="vet/:vet_id/plans" element={<PlanListing />} />
              <Route exact path="vet/:vet_id/plan/create" element={<PlanCreate />} />
              <Route exact path="vet/:vet_id/plan/:plan_id" element={<PlanDetails />} />
              <Route exact path="vet/:vet_id/addons" element={<AddonListing />} />
              <Route exact path="plan/:plan_id" element={<PlanDetails />} />
              <Route exact path="invoice/:id" element={<InvoiceDetails />} />
              <Route exact path="clients" element={<Clients />} />
              <Route exact path="patients" element={<Patients />} />
              <Route exact path="patient/:id" element={<PatientDetails />} />
              <Route exact path="vet/:vet_id/plan_instances" element={<PlanInstanceListing />} />
              <Route exact path="vet/:vet_id/invitations" element={<InvitationListing />} />
              <Route exact path="plan_instance/:id" element={<PlanInstanceDetails />} />
              <Route exact path="alert_config" element={<AlertConfig />} />
            </Route>

            {/* DEV Routes*/}
            {/*<Route exact path="/" element={<DevMenuView />} />*/}
            <Route exact path="/component-zoo" element={<ZooView />} />
            <Route exact path="/prototype-pages" element={<PagesView />} />
            <Route exact path="/prototype-pages/basic-invoice" element={<BasicInvoiceView />} />
            <Route exact path="/prototype-pages/checkout" element={<Checkout />} />
            <Route exact path="/prototype-pages/receipt" element={<Receipt />} />

            {/* CLIENT Routes */}
            <Route path="subscription" element={<SubscriptionViewController />}>
              <Route path="" element={<InvitationCodeEntryView />} />
              <Route path="load/:code" element={<InvitationLoadingView />} />
              <Route path=":code" element={<InvitationView />}>
                <Route path="chooser" element={<PlanChooserView />} />
                <Route path="personal-details" element={<PersonalDetailsView/>}/>
                <Route path="agreement" element={<ServiceAgreement/>}/>
                <Route path="checkout" element={<PlanCheckoutView />} />
                <Route path='membership-agreement' element={<ServiceAgreement />}/>
                <Route path="receipt" element={<SubscriptionReceiptView />} />
              </Route>
            </Route>
            <Route exact path="/join" element={<Navigate to="/subscription" replace />} />
            <Route path="request" element={<PaymentRequestViewController />}>
              <Route path="" element={<PaymentRequestEntryView />} />
              <Route path="load/:code" element={<PaymentRequestLoadingView />} />
              <Route path=":code" element={<PaymentRequestView />}>
                <Route path="checkout" element={<PaymentRequestCheckoutView />} />
                <Route path="finish" element={<PaymentRequestFinishView />} />
                <Route path="receipt" element={<PaymentRequestReceiptView />} />
              </Route>
            </Route>
            <Route path="invoice/:invoiceId" element={<InvoiceViewController />} >
              <Route path="" element={<InvoiceLoadingView />} />
              <Route path="view" element={<InvoiceView />}>
                <Route path="selection" element={<InvoiceSelectionView />} />
                <Route path="payment" element={<InvoicePaymentView />} />
              </Route>
              <Route path="receipt" element={<InvoiceReceiptView />} />
            </Route>
            <Route path="payment/plan/:planId" element={<SubscriptionCollectionsViewController />} >
              <Route path="" element={<SubscriptionCollectionsLoadingView />} />
              <Route path="updatePayment" element={<SubscriptionCollectionsPaymentView />} />
              <Route path="receipt" element={<SubscriptionCollectionsPaymentOkView />} />
              <Route path="pending" element={<SubscriptionCollectionsPendingChargeView />} />
            </Route>

            {/* PRACTICE Routes */}
            <Route path="practice">
              <Route path=":token" element={<PracticeAuthenticator />}>
                <Route path="wellness" element={<WellnessViewController />}>
                  <Route path="" element={<Navigate replace to="client" />} />
                  <Route path="client" element={<ClientInformationStep />} />
                  <Route path="patient" element={<PatientInformationStep />} />
                  <Route path="patient/planInfo/:patientId" element={<PatientPlanUsage />} />
                  {/*request routes*/}
                  <Route path="request/details" element={<RequestDetailsStep />} />
                  <Route path="request/payment" element={<PaymentInformationStep />} />
                  <Route path="request/payment/finish" element={<FinishRequestStep />} />
                  <Route path="request/payment/hfinish" element={<HoldlessFinishRequestStep />} />
                  <Route path="request/viewCode" element={<CodeDisplayStep />} />
                  <Route path="request/sendEmail" element={<SendEmailStep />} />
                  <Route path="request/sendSMS" element={<SendSmsStep />} />
                  <Route path="request/receipt" element={<ReceiptStep />} />
                  {/* invitation routes*/}
                  <Route path="invitation/details" element={<InvitationDetailsStep />} />
                  <Route path="invitation/payment" element={<PaymentInformationStep />} />
                  <Route path="invitation/viewCode" element={<CodeDisplayStep />} />
                  <Route path="invitation/sendEmail" element={<SendEmailStep />} />
                  <Route path="invitation/sendSMS" element={<SendSmsStep />} />
                </Route>
              </Route>
            </Route>

            { /* CONTRACT Routes */}
            <Route path='contracts'>
              <Route path=":token" element={<PracticeAuthenticator />}>
                <Route path="serviceagreement" element={<ServiceAgreement />}></Route>
              </Route>
            </Route>

            { /* PUBLIC ROUTES */ }
            <Route path='membership-agreement' element={<ServiceAgreement />}></Route>
            <Route path='enroll/:slug' element={<WizardFlow/>}></Route>
            <Route path='receipt/careplan/:guid' element={<PlanReceipt/>}></Route>
          </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
