import {useOutletContext, useNavigate} from "react-router-dom";
import GanymedePageContainer from "../../components/ganymede/PageContainer";
import GanymedeKeyValueTable from "../../components/ganymede/KeyValueTable";
import GanymedePageFooter from "../../components/ganymede/PageFooter";
import GanymedeDocumentHeader from "../../components/ganymede/DocumentHeader";
import {FormProvider, useForm} from "react-hook-form";
import ProgressButton from "react-progress-button";
import { useEffect, useRef } from "react";
import parsePhoneNumber from 'libphonenumber-js';

function PaymentRequestView() {

  const formMethods = useForm({mode: 'onChange'});
  const {handleSubmit, formState: { isValid, isSubmitting }, trigger, setValue } = formMethods;

  const { dagwood, apiState, checkout } = useOutletContext();

  const onSubmit = async (form) => {
    await checkout();
  }

  const navigate = useNavigate()

  const btnRef = useRef();

  // button progress state for api calls
  useEffect(() => {
    if (apiState) {
      switch(apiState.status) {
        case 'loading':
          btnRef?.current?.loading();
          break;
        case 'success':
          btnRef?.current?.success(null, false);
          break;
        case 'error':
          btnRef?.current?.error(null);
          break;
        default:
          btnRef?.current?.notLoading();
          break;
      }
    }
  }, [apiState]);

  const clientHasCarePlan = dagwood?.client.patients?.some(patient => patient.plan_instance?.is_plan_in_good_standing);
  const noPaymentHoldsForSubscribers = dagwood?.feature_flags.no_payment_holds_for_subscribers === 'true';
  const willHoldFunds = !(clientHasCarePlan && noPaymentHoldsForSubscribers);


  return (
      <>
        <div className={'subscription-flow flex flex-row text-left items-center px-3 py-1.5 md:px-10 md:py-3 lg:px-20 lg:py-6 bg-white'}>
          <img className={'w-20 mr-2.5'} alt={dagwood?.vet?.name} src={dagwood?.vet?.logo_url} /><span className={'text-2xl sm:text-3xl md:text-4xl font-normal'}>{dagwood?.vet?.name}</span>
        </div>
        <GanymedePageContainer className={'request-checkout-container'}>
          <div className={'InvoiceHeader'}>
            <GanymedeDocumentHeader
              attribution={(
                <>
                  {[dagwood?.vet?.name, dagwood?.vet?.street_address, dagwood?.vet?.street_address2, `${dagwood?.vet?.city}, ${dagwood?.vet?.state} ${dagwood?.vet?.postal_code}`, dagwood?.vet?.phone && parsePhoneNumber(dagwood?.vet?.phone, 'US')?.formatNational()].map((attr, index) => (<span key={index}>{attr}</span>))}
                </>
              )}
              title={`Invoice`}
            />
            <GanymedeKeyValueTable className={'[&_th]:py-5 ganymede-full-width mb-5'} header={['Total Invoice Amount', dagwood?.invoice_amount]}/>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={'finish-step-container'}>
                  <div>
                    <div className={'complete-payment-container'}>
                      <div>
                        <div>
                          {willHoldFunds
                            ? <div className={'charge-warning'}>By clicking the "Complete" button below, you are agreeing to a temporary hold on your payment method for the invoice total. If you are enrolled in a care plan, or if you enroll in the next 48 hours, your benefits will be applied to this invoice and the hold will be released. Otherwise, your payment method will be charged for the full amount after 48 hours.</div>
                            : <div className={'charge-warning'}>By clicking the "Complete" button below, you are agreeing to pay the invoice total listed above, less any care plan coverage which may be applied during processing, using the payment method you have selected.</div>
                          }
                        </div>                    
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                <div className='flow-options-btn-container'>
                  <button type={'button'} className='back-button' onClick={() => { navigate(-1); }}>Back</button>
                  <div className="btn-container">
                    <ProgressButton state={isSubmitting || apiState?.status === 'success' ? 'disabled' : ''} durationSuccess={2000} ref={btnRef} controlled={true} onClick={() => {}} className={`complete-payment-button ${!isValid ? 'btn-disabled' : ''}`} type="submit">Complete Payment</ProgressButton></div>

                  </div>
                </div>
              </form>
            </FormProvider>
          </div>

        </GanymedePageContainer>
        <GanymedePageFooter />
      </>
  );
}

export default PaymentRequestView;
