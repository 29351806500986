import './receipt.css';
import GanymedeKeyValueTable from "../../../components/ganymede/KeyValueTable";
import GanymedePageContainer from "../../../components/ganymede/PageContainer";
import GanymedePageFooter from '../../../components/ganymede/PageFooter';
import CheckCircle from '../../../assets/svgs/CheckCircle.svg';

function Receipt({ pet = { name: 'Bowser' }, plan = { name: 'Wellness Plan' } }) {
  return (
      <>
        <GanymedePageContainer
          className={'receipt-container'}
          footerContent={
            (<><GanymedeKeyValueTable className={'ganymede-full-width total'} header={['Total Paid Today', '$69']} data={{ 'Confirmation Number':'123412333', 'Payment Date': 'May 21, 2022', 'Payment Method': 'Visa ****6969' }}/>
            <GanymedeKeyValueTable className={'ganymede-full-width'} header={ 'Payment Details' }
            data={{
            'Exam':<><span className={'strikethrough'}>$120</span><span>$0</span></>,
            'Rabies':<><span className={'strikethrough'}>$35</span><span>$0</span></>,
            'Bordetella':<><span className={'strikethrough'}>$35</span><span>$0</span></>,
            'Fecal':<><span className={'strikethrough'}>$50</span><span>$0</span></>,
          }}/></>)
          }
        >
          <div className={'receipt-header'}>
            <span className={'payment-success-icon'}>
              <img src={CheckCircle}  alt={'success icon'} />
            </span>
            <span className={'receipt-title'}>Payment Successful</span>
            <span className={'receipt-subheading'}>
              {pet?.name} is now enrolled in {plan?.name}, we look forward to seeing you both soon.
              You will receive a confirmation email shortly.
            </span>
          </div>
        </GanymedePageContainer>
        <GanymedePageFooter />
      </>
  );
}

export default Receipt;
