import { useForm } from 'react-hook-form';
import GanymedeInput from '../../../components/ganymede/GanymedeInput';
import { useState } from 'react';
import SnoutLoadingDots from '../../../components/snout-loading-dots/SnoutLoadingDots';
import StepContainer from "../StepContainer";
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENT_CODES } from '../../../constants';

function NewClientStep({state, dispatch}) {
  const [loading, setLoading] = useState(false);


  const { formState: { errors }, handleSubmit, register, setValue, } = useForm({
    defaultValues: state?.client,
    mode: 'onChange',
  });

  const onSubmit = (values) => {
    track(AMPLITUDE_EVENT_CODES.SELF_ENROLL_NEW_CLIENT, {
      vet: state?.vet,
      client: values,
    });
    dispatch({type: 'next', client: values });
  }

  return (
    <StepContainer back={() => dispatch({type: 'prev'})}>
      <form className={'flex flex-col items-center h-full'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'font-roboto-slab text-3xl sm:text-4xl text-center'}>Let’s find the right care plan for your pet.</div>
        <div className={'text-gray-500 text-center my-5'}>Let’s start with the basics:</div>
        <div className={'grid grid-cols-12 grid-col-gap-2 grid-row-gap-0 w-full '}>
          <GanymedeInput className={'col-span-full'} errors={errors} label={'First Name'} type="text"
            {...register('first_name', {
              onChange: (e) => setValue('first_name', e?.target?.value?.trim()),
              required: { value: true, message: 'First name is required' }
            })}/>
          <GanymedeInput className={'col-span-full'} errors={errors} label={'Last Name'} type="text"
            {...register('last_name', {
              onChange: (e) => setValue('last_name', e?.target?.value?.trim()),
              required: { value: true, message: 'Last name is required' }
            })}/>
          <div className={'flex flex-row mb-3 w-full col-span-full'}>
            <GanymedeInput
                className={'w-full [&_input]:!pr-7 flex-grow-0'}
                type='text'
                label={"Email"}
                errors={errors}
                {...register("email", {
                  value: state?.client?.email,
                  onChange: (e) => setValue('email', e?.target?.value?.trim()),
                  required: { value: true, message: "Email is required" },
                  pattern: {
                    //This is the version I found online at https://emailregex.com/.
                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Email is invalid",
                  },
                })}
            />
            <SnoutLoadingDots className={`mt-6 -ml-6 ${loading ? 'block' : '!hidden'}`} size={'0.25rem'}/>
          </div>
        </div>
        <button className={'mt-auto sm:mt-8 w-full sm:w-40 btn-primary' } type="submit">Continue</button>
      </form>
    </StepContainer>
  );
}

export default NewClientStep;
