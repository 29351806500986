import {useNavigate, useOutletContext, useParams} from 'react-router-dom';
import {useEffect, useState} from "react";
import GanymedePageFooter from "../../components/ganymede/PageFooter";
import GanymedePageContainer from "../../components/ganymede/PageContainer";
import SnoutLoadingDots from '../../components/snout-loading-dots/SnoutLoadingDots';

function InvitationLoadingView() {

  const code = useParams()?.code;
  const navigate = useNavigate();
  const { dagwood, loadDagwoodByCode } = useOutletContext();
  const [ mode, setMode ] = useState('loading');

  const [inputCode, setInputCode] = useState('');

  useEffect(() => {
    (async () => {
      try {
        await loadDagwoodByCode(code);
      } catch (e) {
        if (e.status === 404) {
          setMode('not found');
        } else {
          setMode('whoops');
        }
      }
    })();
  }, []);

  return (
      <>
        <GanymedePageContainer className={'loader-container'}>
          { mode === 'loading' &&
            <>
            <h1>Fetching Your Invitation...</h1>
            <div className={'loading-dots-container'}>
            <SnoutLoadingDots />
            </div>
            </>
          }
          { mode === 'not found' &&
          <>
            <h1>Invitation Code Not Found</h1>
            <p>Sorry, your invitation code was not recognized!</p>
          </>
          }
          { mode === 'whoops' &&
          <>
            <h1>Whoops!</h1>
            <p>Sorry, an unknown error has occurred. Please try again later.</p>
          </>
          }
        </GanymedePageContainer>
        <GanymedePageFooter />
      </>
  );
}

export default InvitationLoadingView;
