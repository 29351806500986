const camelToSnake = (str) => {
  return str.split(/(?=[A-Z])/).map(s => s.toLowerCase()).join('_');
}

const snakeToCamel = (str) => {
  return str.split('_').map((subStr, i) => {
    if (i !== 0) return subStr[0].toUpperCase().concat(subStr.slice(1));
    return subStr;
  }).join('');
}

/*
* function takes an object and a config and maps keys to either 1. a new key, 2. a camelCase version of that key, or 3. a snake_case version of that key
*
* please note if a key in <object> IS an object itself, it's key will stay the same.
*
* config looks like this:
*
* const keyRulesMap = {
  client: {
    email: 'emailAddress', // new key entirely
    last_name: 'c', // for camel
    first_name: 's', // for snake
  },
  patient: {
    firstName: 's',
  },
  payment: {

  },
}
* */
const mapKeysToNewKeys = (object, keyRulesMap) => {
  return Object.keys(object).reduce((agg, key, index) => {
    const rule = keyRulesMap[key];
    if (typeof rule === 'object') {
      agg[key] = mapKeysToNewKeys(object[key], rule);
    }
    else if (!rule) agg[key] = object[key];
    else if (rule === 'c') agg[snakeToCamel(key)] = object[key];
    else if (rule === 's') agg[camelToSnake(key)] = object[key];
    else agg[rule] = object[key];
    return agg;
  }, {});
};

const common = {

  stringSplice: (baseString, offset, insertString) => {
    return baseString.slice(0,offset) + insertString + baseString.slice(offset);
  },

  pennyIntToDollarString: (pennyInt) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
        .format(pennyInt/100);
  },

  pennyIntToRoundedDollarString: (pennyInt) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, })
      .format(pennyInt/100);
  },

  dollarStringToPennyInt: (dollarString) => {
    return Math.round(parseFloat(dollarString.replace('$', '')) * 100);
  },

  createCrossRenderFunction: (ref, fn) => {
    if (ref.current) {
      fn(ref.current);
      ref.current = undefined;
    }
    return (arg) => fn(arg);
  },

  formatSelfRegistrationPayload: (payload) => {
    // format data for backend
    const formattedDataPayload = { ...payload,
      patient: {
      ...payload.patient
    }}

    // format keys for backend
    const keyRulesMap = {
      client: {
        email: 'emailAddress',
        last_name: 'c',
        first_name: 'c',
      },
      patient: {
        spayNeuter: 's',
      },
      payment: {
        // has the right keys for now
      },
    }
    return mapKeysToNewKeys(formattedDataPayload, keyRulesMap);
  },
  
  getSavings: (plan) => {
    const listPriceTotalCost = plan?.items.reduce((acc, ele) => acc + ele.list_price * ele.max_quantity, 0);
    const yearlyTotal = 12 * plan?.monthly_price;
    const enrollmentFee = plan?.enrollment_fee;
    const yearlyExtraSavings = 12 * plan?.monthly_extra_savings;

    const savings = listPriceTotalCost - (yearlyTotal + enrollmentFee) + yearlyExtraSavings
    if (savings <= 0) return undefined;
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, })
      .format(savings/100);
  }

};

export default common;
