import GanymedePageContainer from "../../components/ganymede/PageContainer";
import GanymedeKeyValueTable from "../../components/ganymede/KeyValueTable";
import CheckCircle from "../../assets/svgs/CheckCircle.svg";
import GanymedePageFooter from "../../components/ganymede/PageFooter";
import { useOutletContext } from "react-router-dom";
import common from '../../lib/common';
import DayJs from 'dayjs';

/**
 * This has 3 modes:
 * Payment request receipt mode (hold has been placed, but invoice hasn't been processed yet)
 * Invoice receipt mode (deprecated; displays payment request, invoice info and invoice items taking into account changes made after payment processing)
 * Receipt mode (displays original payment request, invoice info and invoice items even if changes were made after payment processing)
 */
function PaymentRequestReceiptView() {
  const { dagwood } = useOutletContext(); // Dagwood is always populated by the time we're routed to here

  let formattedDate;
  let data = {};
  let tabulatedItems = {};
  let invoiceTotal;
  let allItemTotal;
  let coveredItemTotal = 0;
  const noPetsEnrolled = dagwood.patients.every(prPatient => dagwood.client.patients.find(patient => patient.id === prPatient.id && !patient.plan_instance));

  if (dagwood.receipt) {
    formattedDate = DayJs(dagwood.receipt.invoice_date).format('MM/DD/YYYY');
    allItemTotal = dagwood.receipt.items.reduce((accum, currVal)=> accum + currVal.item_price, 0);
    coveredItemTotal = dagwood.receipt.items
      .filter(item => item.plan_item_description)
      .reduce((accum, currVal)=> accum + currVal.item_price, 0);
    invoiceTotal = dagwood.receipt.invoice_amount;
    if (dagwood.receipt.items.length) {
      tabulatedItems = dagwood.receipt.items.reduce((agg, item) => {
        item.covered = !!item.plan_item_description;
        agg[item.item_description] = (<>
          {item.covered && <span className={'strikethrough'}>{common.pennyIntToDollarString(item.item_price)}</span>}
          <span>{item.covered ? "$0" : common.pennyIntToDollarString(item.item_price)}</span></>
        );
        return agg;
      }, {});
    }

    data = {
      'Confirmation number': dagwood.external_id,
      'Payment method': `${dagwood.receipt.card_brand} ****${dagwood.receipt.card_last4}`,
      'Client': `${dagwood.receipt.client_first_name} ${dagwood.receipt.client_last_name}`,
      'Patient(s)': dagwood.receipt.items.map(item => item.patient_name)
        .filter((v, i, a) => a.indexOf(v) === i)
        .join(', '),
      ...tabulatedItems,
    };
    if (invoiceTotal - allItemTotal > 0) {
      // Some places don't have tax, so tax would be $0.
      // Some systems include tax with the line item, so displaying "Tax: $0" is inaccurate.
      // Some systems have implicit tax (not available for us to sync), so we have to calculate it.
      data['Tax'] = common.pennyIntToDollarString(invoiceTotal - allItemTotal);
    }
  }
  else if (dagwood.invoice) {
    formattedDate = DayJs(dagwood.invoice.date).format('MM/DD/YYYY');
    allItemTotal = dagwood.invoice.items.reduce((accum, currVal)=> {
      return accum + currVal.price
    }, 0);
    coveredItemTotal = dagwood.invoice.items
      .filter(item => item.plan_item)
      .reduce((accum, currVal)=> accum + currVal.price, 0);
    invoiceTotal = dagwood.invoice.amount;
    if (dagwood.invoice.items) {
      tabulatedItems = dagwood.invoice.items.reduce((agg, item) => {
        item.covered = !!item.plan_item;
        agg[item.description] = (<>
          {item.covered && <span className={'strikethrough'}>{common.pennyIntToDollarString(item.price)}</span>}
          <span>{item.covered ? "$0" : common.pennyIntToDollarString(item.price)}</span></>
        );
        return agg;
      }, {});
    }

    data = {
      'Confirmation number': dagwood.external_id,
      'Payment method': `${dagwood.card.brand} ****${dagwood.card.last4}`,
      'Client': `${dagwood.client.first_name} ${dagwood.client.last_name}`,
      'Patient(s)': dagwood.patients.map(p => p.name).join(', '),
      ...tabulatedItems,
    };
    if (invoiceTotal - allItemTotal > 0) {
      data['Tax'] = common.pennyIntToDollarString(invoiceTotal - allItemTotal);
    }
  }
  else {
    invoiceTotal = common.dollarStringToPennyInt(dagwood.invoice_amount);
    data = {
      'Confirmation number': dagwood.external_id,
      'Payment method': `${dagwood.card.brand} ****${dagwood.card.last4}`,
      'Client': `${dagwood.client.first_name} ${dagwood.client.last_name}`,
      'Patient(s)': dagwood.patients.map(p => p.name).join(', ')
    };
  }

  let subtitle;
  if (dagwood.invoice) {
    subtitle = 'Your invoice has been successfully processed and paid.';
  } else if (dagwood.fundsHeld) {
    subtitle = `We have placed a hold on your credit card. Your care plan benefits, if any, will be applied to your invoice accordingly. We will send you a final, itemized receipt upon completion.`;
  } else {
    subtitle = `This payment is being processed. Your care plan benefits, if any, will be applied to your invoice accordingly. We will actually charge your card and send you a final, itemized receipt upon completion.`;
  }

  return (
    <>
      <div className={'subscription-flow flex flex-row text-left items-center px-3 py-1.5 md:px-10 md:py-3 lg:px-20 lg:py-6 bg-white'}>
        <img className={'w-20 mr-2.5'} alt={dagwood.vet.name} src={dagwood.vet.logo_url} /><span className={'text-2xl sm:text-3xl md:text-4xl font-normal'}>{dagwood.vet.name}</span>
      </div>
      <GanymedePageContainer
        className={'receipt-container'}
        footerContent={
          (<>
          <div className="text-xl font-semibold border-b border-solid border-gray-200 border-x-0 border-t-0">
            <div className="flex pb-2 flex-row justify-between">
              <div>Invoice Date</div>
              <div>{formattedDate}</div>
            </div>
            <div className="flex pb-2 flex-row justify-between">
              <div>Invoice Total</div>
              <div className="flex flex-row justify-end">
                <div className={coveredItemTotal > 0 && "line-through decoration-green-500 pr-2"}>{common.pennyIntToDollarString(invoiceTotal)}</div>
                {coveredItemTotal > 0 &&
                  <div>{common.pennyIntToDollarString(dagwood.invoice || dagwood.receipt ? invoiceTotal - coveredItemTotal : 0)}</div>
                }
              </div>
            </div>
            <div className="flex pb-2 flex-row justify-between">
              <div>Membership Benefits Covered</div>
              <div>{noPetsEnrolled ?
                'Not enrolled'
                : dagwood.receipt || dagwood.invoice ?
                  common.pennyIntToDollarString(coveredItemTotal)
                  : 'Processing'}
              </div>
            </div>
          </div>
          <GanymedeKeyValueTable className={'ganymede-full-width total'} 
            data={data} /></>)
        }
      >
        <div className={'flex flex-col text-center px-10 pb-14'}>
          <span className={'flex flex-col'}>
            <img className={'h-32 sm:h-40'} src={CheckCircle} alt={'success icon'} />
          </span>
          <span className={'flex flex-col font-normal text-3xl sm:text-4xl font-roboto-slab'}>Thank you!</span>
          <span className={'flex flex-col text-base text-gray-500 mt-5 mb-2.5'}>{subtitle}</span>
        </div>
      </GanymedePageContainer>
      <GanymedePageFooter />
    </>
  );
}

export default PaymentRequestReceiptView;
