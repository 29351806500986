import {useOutletContext} from 'react-router-dom';
import "react-progress-button/react-progress-button.css";


function CodeDisplayStep() {
  const outletContext = useOutletContext();

  const {
    codeContext,
  } = outletContext;

  return (
    <div className={'wellness-code-container'}>
      <div className={'code'}>
        {codeContext?.code}
      </div>
    </div>
  );
}

export default CodeDisplayStep;
