import { useForm } from 'react-hook-form';
import GanymedeInput from '../../../components/ganymede/GanymedeInput';
import SnoutLoadingDots from '../../../components/snout-loading-dots/SnoutLoadingDots';
import { useState } from 'react';
import api from '../../../services/jupiter_api';
import StepContainer from "../StepContainer";
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENT_CODES } from '../../../constants';


function SearchByEmailStep({state, dispatch}) {

  const { formState: { errors }, handleSubmit, register } = useForm();
  const [loading, setLoading] = useState(false);

  const findUserByEmail = async (vet_id, email) => {
    return await api.getClientByEmail(vet_id, email)
  }

  const onSubmit = async(values) => {
    setLoading(true);
    try {
      const result = await findUserByEmail(state.vet.id, values.email);
      track(AMPLITUDE_EVENT_CODES.SELF_ENROLL_CLIENT_LOOKUP_SUCCESS, {
        vet: state?.vet,
        client: result,
      });
      dispatch({ type: 'next', client: result, applicablePatients: result.patients, });
    } catch(e) {
      track(AMPLITUDE_EVENT_CODES.SELF_ENROLL_CLIENT_LOOKUP_FAILURE, {
        vet: state?.vet,
        email: values.email,
      });
      dispatch({ type: 'next', client: {
        email: values.email,
      } });
    }
    setLoading(false);
  }

  return (
    <StepContainer back={() => dispatch({type: 'prev'})}>
      <form className={'flex flex-col items-center h-full'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'font-roboto-slab text-3xl sm:text-4xl text-center'}>Let’s find the right care plan for your pet.</div>
        <div className={'text-gray-500 text-center my-5'}>This should only take a couple of minutes.</div>
        <div className={'flex flex-row mb-3 w-full'}>
          <GanymedeInput
              className={'w-full [&_input]:!pr-7 flex-grow-0'}
              type='text'
              label={"Email"}
              errors={errors}
              {...register("email", {
                value: state?.client?.email,
                required: { value: true, message: "Email is required" },
                pattern: {
                  //This is the version I found online at https://emailregex.com/.
                  value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Email is invalid",
                },
              })}
          />
          <SnoutLoadingDots className={`mt-6 -ml-6 ${loading ? 'block' : '!hidden'}`} size={'0.25rem'}/>
        </div>
        <button className={'mt-auto sm:mt-8 w-full sm:w-40 btn-primary'} type="submit">Continue</button>
      </form>
    </StepContainer>
  );
}

export default SearchByEmailStep;
