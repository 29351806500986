import { useFormContext, Controller } from 'react-hook-form';

/*
* Input to capture a credit card expiry month and year
* NOTE: due to pretty UI formatting, you must call split(' / ') on the output value of this input
* in order to get the expiry month and year as the first and second indices of that array
*
* Because this component calls useFormContext(), ensure the form is wrapped in <FormProvider> or this will break
* */
const GanymedeCreditCardExpiryInput = (
  {
    name,
    label,
    invalidErrorMsg = 'Expiry is not valid', // custom error message if the card doesn't fit the pattern
    rules = {}, // validation options documented here: https://react-hook-form.com/api/useform/register#options
    ...props
  }) => {
  const { control, formState: { errors } } = useFormContext();

  // method that gets object property that handles nesting ex. formGroup.firstName
  const getDotNotatedProperty = (obj, path) => {
    return path.split('.').reduce((o, i) => o?.[i], obj);
  };

  // expiry formatting lifted from https://github.com/medipass/react-payment-inputs/blob/master/src/utils/formatter.js
  const formatExpiry = event => {
    const prevExpiry = event.target.value.split(' / ').join('/');

    if (!prevExpiry) return '';
    let expiry = prevExpiry;
    if (/^[2-9]$/.test(expiry)) {
      expiry = `0${expiry}`;
    }

    if (prevExpiry.length === 2 && +prevExpiry > 12) {
      const [head, ...tail] = prevExpiry.split('');
      expiry = `0${head}/${tail.join('')}`;
    }

    if (/^1[/-]$/.test(expiry)) {
      return `01 / `;
    }

    expiry = expiry.match(/(\d{1,2})/g) || [];
    if (expiry.length === 1) {
      if (prevExpiry.includes('/') && prevExpiry.length === 3) {
        return expiry[0];
      }
      if (/\d{2}/.test(expiry)) {
        return `${expiry[0]} / `;
      }
    }
    if (expiry.length > 2) {
      const [, month = null, year = null] = expiry.join('').match(/^(\d{2}).*(\d{2})$/) || [];
      return [month, year].join(' / ');
    }
    return expiry.join(' / ');
  };

  // Transform and Parse section of react hook api here: https://react-hook-form.com/advanced-usage
  const transform= {
      input: (value) => {
        return value;
      },
      output: (e) => {
        return formatExpiry(e);
    }
  }

  // fancy react-hook-forms api to transform values and maintain validation and such
  return (
    <div className={`ganymede-input form-control-container ${props.className || ''}`}>
      <Controller
        defaultValue={''}
        control={control}
        name={name}
        rules={{ ...rules, maxLength: { value: 7, message: invalidErrorMsg }, minLength: { value: 7, message: invalidErrorMsg } }}
        render={({ field }) => (
          <>
            <span className={'bg-white'}>
              <input
                className={'expiry-input'}
                onChange={(e) => field.onChange(transform.output(e))}
                value={transform.input(field.value)}
                maxLength={19}
              />
            </span>
            <label className={field.value ? 'ganymede-input-label-shrunk' : ''}>{label}</label>
            {getDotNotatedProperty(errors, name)?.message && <span className={'form-validation-error'}>{getDotNotatedProperty(errors, name)?.message}</span>}
          </>
        )}
      />
    </div>
  );
};

export default GanymedeCreditCardExpiryInput;
