import snoutIcon from "./orangeSnout.png";

function GanymedePageFooter () {
  return (
      <div className={`GanymedePageFooter`}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          Powered by <div style={{display: 'flex', alignItems: 'center'}}><img alt="" src={snoutIcon} style={{ height: '1rem', width: '1rem', display: 'inline', marginLeft: '0.31rem', marginRight: '0.13rem'}}/> <span>Snout</span></div>
        </div>
      </div>
  );
}

export default GanymedePageFooter;
