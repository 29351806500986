import {useNavigate} from 'react-router-dom';
import {useState} from "react";
import GanymedePageFooter from "../../components/ganymede/PageFooter";
import GanymedePageContainer from "../../components/ganymede/PageContainer";

function InvitationCodeEntryView() {

  const navigate = useNavigate();

  const [inputCode, setInputCode] = useState('');

  async function onClick () {
    navigate(`load/${inputCode}?src=code`);
  }

  return (
      <>
        <GanymedePageContainer className={'subscription-container'}>
          <h1>Snout Wellness Signup</h1>
          <p>Welcome! Please enter your invitation code to begin the signup process for your care plan subscription.</p>
          <div className={'flex align-middle justify-center'}>
            <input placeholder={'Enter Your Code'} autoFocus className={'code-input'} type="text" value={inputCode} onChange={e => setInputCode(e.target.value)} />
          </div>
          <div className={'flex align-middle justify-center'}>
            <div className={'w-full max-w-[250px]'}>
              <button className={'begin-btn'} onClick={onClick}>Begin</button>
            </div>
          </div>
        </GanymedePageContainer>
        <GanymedePageFooter />
      </>
  );
}

export default InvitationCodeEntryView;
