function GanymedeInvoiceColumnHeader (props) {
  return (
    <div className={`${props.className || ''} GanymedeInvoiceColumnHeader`}>
      <div className={'font-bold text-base sm:text-lg label label-top text-center'}>{ props.labelTop }</div>
      <div className={'md:text-2xl value flex flex-wrap sm:block text-center justify-center items-baseline'}>{ props.value }<span className={'subscript text-right mb-4 sm:mb-0'}>{ props.subscript }</span></div>
      <div className={'text-base label label-bottom text-center'}>{ props.labelBottom }</div>
    </div>
  );
}

export default GanymedeInvoiceColumnHeader;
