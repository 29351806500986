import "react-progress-button/react-progress-button.css";

function FinishRequestStep() {
  return (
    <div className={'finish-step-container'}>
      <div>
        <div className={'complete-payment-container'}>
          <div>
            <div>
              <h4>Terms and Conditions</h4>
              <div className={'charge-warning'}>By clicking the button below, you are agreeing to a temporary hold on your payment method for the invoice total. If you are a plan holder or enroll in a care plan within the next 48 hours, your benefits will be applied to this invoice and the hold will be released. Otherwise, your payment method will be charged for the full invoice total after 48 hours.</div>
            </div>
          </div>
          </div>
      </div>
    </div>
  );
}

export default FinishRequestStep;
