import "./snout-loading-dots.css";

export default function SnoutLoadingDots({ size = '3.13rem', color = '#E05338', delay = 200, ...props }) {
  return (
    <div className={`snout-loading-dots ${props.className || ''}`} style={{ fontSize: size }}>
      {[...Array(3).keys()].map((val, i) => (
        <div key={i} className={'loading-dot'} style={{ backgroundColor: color, animation: `loading-dots-blink 1s ease-in-out ${i * delay}ms infinite`, }}>
        {/*<img alt={'f'} src={SnoutFace} />*/}
      </div>))}
    </div>
  );
}
