import GanymedePageContainer from "../../components/ganymede/PageContainer";
import GanymedeKeyValueTable from "../../components/ganymede/KeyValueTable";
import CheckCircle from "../../assets/svgs/CheckCircle.svg";
import GanymedePageFooter from "../../components/ganymede/PageFooter";
import {useOutletContext} from "react-router-dom";
import common from '../../lib/common';
import DayJs from 'dayjs';
import { useEffect, useState } from 'react';
import api from "../../services/jupiter_api";
import { useParams } from 'react-router-dom';

function PlanReceipt() {

  const [detailsConfig, setDetailsConfig] = useState({});
  const params = useParams()
  const planInstanceId = params.guid;
  const [dagwood, setDagwood] = useState();

  useEffect(() => {
    (async () => {
      try {
        const plan = await api.getPublicPlanInstance(planInstanceId);
        setDagwood(plan);
      } catch (e) {
        console.log(e)
      }
    })();
    // eslint-disable-next-line
  }, []);

  const buildReceiptCfg = (dagwood) => {
    if (dagwood?.plan) {
      const cfg = {
        'Monthly Payment': dagwood?.plan?.monthly_price,
        'Confirmation Number':dagwood?.external_id,
        'Payment Method': `${dagwood?.card?.brand} ****${dagwood?.card?.last4}`,
        'Client': `${dagwood?.client?.first_name} ${dagwood?.client?.last_name}`,
        'Patient': dagwood?.patient?.name,
        'Plan': dagwood?.plan?.name,
        'Enrollment Date': DayJs(dagwood?.start_at)?.format('MM/DD/YYYY'),
      }

      return cfg;
    }
  }

  // useEffect(() => {
  
  //   if (dagwood?.plan) {
  //     const cfg = {
  //       'Monthly Payment': common.pennyIntToDollarString(dagwood?.plan?.monthly_price),
  //       'Enrollment Fee': common.pennyIntToDollarString(dagwood?.plan?.enrollment_fee),
  //       'Confirmation Number':dagwood?.external_id,
  //       'Payment Method': `${dagwood?.card?.brand} ****${dagwood?.card?.last4}`,
  //       'Client': `${dagwood?.client?.first_name} ${dagwood?.client?.last_name}`,
  //       'Patient': dagwood?.patient?.name,
  //       'Plan': dagwood?.plan?.name,
  //       'Enrollment Date': DayJs(dagwood?.start_at)?.format('MM/DD/YYYY'),
  //     }

  //     if (!dagwood.plan.enrollment_fee) delete cfg['Enrollment Fee'];
  //     setDetailsConfig(cfg);
  //   }
  // }, [dagwood]);

  return (
    <>
      <div className={'subscription-flow flex flex-row text-left items-center px-3 py-1.5 md:px-10 md:py-3 lg:px-20 lg:py-6 bg-white'}>
        <img className={'w-20 mr-2.5'} alt={dagwood?.vet?.name} src={dagwood?.vet?.logo_url} /><span className={'text-2xl sm:text-3xl md:text-4xl font-normal'}>{dagwood?.vet?.name}</span>
      </div>
      <GanymedePageContainer
          className={'receipt-container'}
          footerContent={
            (<><GanymedeKeyValueTable className={'ganymede-full-width total last:[&_th]:text-right'} header={['Total Paid Today', `${dagwood?.plan?.monthly_price}`]}
            data={buildReceiptCfg(dagwood)}/></>)
          }
      >
        <div className={'flex flex-col text-center px-10 pb-14'}>
            <span className={'flex flex-col'}>
              <img className={'h-32 sm:h-40'} src={CheckCircle}  alt={'success icon'} />
            </span>
          <span className={'flex flex-col font-normal text-3xl sm:text-4xl font-roboto-slab'}>Thank You!</span>
          <span className={'flex flex-col text-base text-gray-500 mt-5 mb-2.5'}>{dagwood?.patient?.name} has been successfully enrolled in a care plan!</span>
        </div>
      </GanymedePageContainer>
      <GanymedePageFooter />
    </>
  );
}

export default PlanReceipt;
