import { NavLink, useOutletContext } from 'react-router-dom';
import GanymedePageContainer from "../../components/ganymede/PageContainer";
import GanymedeKeyValueTable from "../../components/ganymede/KeyValueTable";
import CheckCircle from "../../assets/svgs/CheckCircle.svg";
import ChevronLeft from "../../assets/svgs/chevron-left-solid.svg";
import GanymedePageFooter from "../../components/ganymede/PageFooter";

function SubscriptionCollectionsPaymentOkView() {
  const outletContext = useOutletContext();
  if (!outletContext.plan) return (<></>);

  const { plan } = outletContext;

  return (
    <>
      <GanymedePageContainer
        className={'receipt-container'}
        footerContent={
          (<><GanymedeKeyValueTable className={'ganymede-full-width total'} header={['Amount to be Paid', plan?.plan.monthly_price]}
            data={{
              'Pet': plan?.patient.name,
              'Plan':plan?.plan.name,
              'Payment Method': `${plan?.card?.brand?.replace(plan?.card?.brand[0], plan?.card?.brand[0].toUpperCase())} ****${plan?.card?.last4}`,
            }}/></>)
        }
      >
        <div className={'flex flex-row text-left items-center px-3 py-1.5 md:px-10 md:py-3 lg:px-20 lg:py-6 bg-white'}>
          <img className={'w-20 mr-2.5'} alt={plan?.vet?.name} src={plan?.vet.logo_url} /><span className={'text-2xl sm:text-3xl md:text-4xl font-normal'}>{plan?.vet.name}</span>
        </div>
        <div className={'receipt-header'}>
            <span className={'payment-success-icon'}>
              <img src={CheckCircle}  alt={'success icon'} />
            </span>
          <span className={'receipt-title'}>Thank You!</span>
          <span className={'receipt-title2'}>Your Payment has been charged</span>
          <span className={'receipt-subheading'}>
            You are all set! Your payment card is in good standing and has been charged successfully.
          </span>
        </div>
      </GanymedePageContainer>
      <GanymedePageFooter />
    </>
  );
}

export default SubscriptionCollectionsPaymentOkView;
