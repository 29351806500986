import './checkout.css';
import GanymedeKeyValueTable from "../../../components/ganymede/KeyValueTable";
import snoutIcon from "../../../components/ganymede/snout.png";
import GanymedePageContainer from "../../../components/ganymede/PageContainer";
import GanymedePageFooter from '../../../components/ganymede/PageFooter';
import GanymedePaymentCardDisplay from '../../../components/ganymede/GanymedePaymentCardDisplay';

function Checkout({ paymentCard, selectedPlan = 'wellness', setSelectedPlan }) {
  const planConfig = {
    wellness: {
      title: 'Wellness Plan',
      price: 68,
    },
    complete: {
      title: 'Complete Coverage',
      price: 118,
    }
  };

  const paymentFooter = (
    <div className={'payment-footer'}>
      <GanymedePaymentCardDisplay brand={'visa'} last4={'6969'} allowChange={true} onRequestChange={() => console.log('poop') } />
      <div className={'btn-container'}>
        <div>
          <span><input className={'agreement-checkbox'} type="checkbox"/></span>
          <span>
            By clicking “Complete Payment” I understand that I will be charged monthly for Bowser’s Snout Wellness Plan, and I have read and agree to the SnoutID <a href={'poop.com'}>Terms of Service</a>.
          </span>
        </div>
        <div>
          <button>Complete Payment</button>
        </div>
      </div>
    </div>
  );
  return (
      <>
        <GanymedePageContainer
          className={'checkout-container'}
          trailer={'*This is an estimate. Final prices may vary depending on the situation.'}
          footerContent={paymentFooter}
        >
          <div class={'title'}>
            <div class={'pre-title'}>
              Bowser's
            </div>
            <div>
              Wellness Plan Checkout
            </div>
          </div>
          <div className={'plan-select'}>
            <div className={`plan-option ${selectedPlan === 'wellness' ? 'selected-plan' : ''}`}>
              <div>
                <div className={'brand'}>
                  <span><img alt="" src={snoutIcon} style={{ height: '0.75rem', width: '0.75rem', display: 'inline', marginRight: '0.19rem'}}/>Snout</span>
                </div>
                <div className={'plan-title'}>{planConfig['wellness'].title}</div>
              </div>
              <div className={'plan-price'}>
                {`$${planConfig['wellness'].price}/mo`}
              </div>
            </div>
            <div className={`plan-option ${selectedPlan === 'complete' ? 'selected-plan' : ''}`}>
              <div>
                <div className={'brand'}>
                  <span><img alt="" src={snoutIcon} style={{ height: '0.75rem', width: '0.75rem', display: 'inline', marginRight: '0.19rem'}}/>Snout</span>
                </div>
                <div className={'plan-title'}>{planConfig['complete'].title}</div>
              </div>
              <div className={'plan-price'}>
                {`$${planConfig['complete'].price}*/mo`}
              </div>
            </div>
          </div>
          <GanymedeKeyValueTable className={'ganymede-full-width'} header={ 'Today\'s Services' }
            data={{
              'Exam':[<><span className={'strikethrough'}>$120</span><span>$0</span></>],
              'Rabies':[<><span className={'strikethrough'}>$35</span><span>$0</span></>],
              'Bordetella':[<><span className={'strikethrough'}>$35</span><span>$0</span></>],
              'Fecal':[<><span className={'strikethrough'}>$50</span><span>$0</span></>],
            }}/>
          <GanymedeKeyValueTable className={'ganymede-full-width'} header={ 'Bowser\'s Wellness Plan' } data={{ 'Monthly Subscription Fee':'$69' }}/>
          <GanymedeKeyValueTable className={'ganymede-full-width total'} header={['Today\'s Total', '$69']} data={{ '':'You\'re saving $240 today' }}/>
        </GanymedePageContainer>
        <GanymedePageFooter />
      </>
  );
}

export default Checkout;
