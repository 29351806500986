import { useForm } from 'react-hook-form';
import RadioButton from '../../../components/RadioButton/RadioButton.js'
import StepContainer from "../StepContainer";
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENT_CODES } from '../../../constants';

function PetSize({state, dispatch}) {

  const { formState: { errors }, handleSubmit, register } = useForm({
    defaultValues: { size: state?.currentPet?.size }
  });

  const onSubmit = (values) => {
    track(AMPLITUDE_EVENT_CODES.SELF_ENROLL_PET_SIZE, {
      vet: state.vet,
      client: state.client,
      pet: { ...state.currentPet, size: values.size },
    });
    dispatch({type: 'next', size: values.size });
  }

  return (
    <StepContainer back={() => dispatch({type: 'prev'})}>
      <form className={'flex flex-col items-center h-full'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'font-roboto-slab text-3xl sm:text-4xl text-center mb-4'}>How big is {state?.currentPet?.name[0].toUpperCase() + state?.currentPet?.name.slice(1)}?</div>
        <div className="w-full grid grid-cols-1 grid-row-gap-2">
          <RadioButton label="Tiny (10 lbs or less)" id="radio-tiny" name="radio-tiny" value="tiny" {...register('size', { shouldUnregister: true, required: { value: true, message: 'Please choose an option above.' }})}/>
          <RadioButton label="Small (10-20lbs)" id="radio-small" name="radio-small" value="small" {...register('size', { shouldUnregister: true, required: { value: true, message: 'Please choose an option above.' }})}/>
          <RadioButton label="Medium (20-50 lbs)" id="radio-medium" name="radio-medium" value="medium" {...register('size', { shouldUnregister: true, required: { value: true, message: 'Please choose an option above.' }})}/>
          <RadioButton className={state?.currentPet.species === 'cat' ? "hidden" : ''} label="Large (50-90 lbs)" id="radio-large" name="radio-large" value="large" {...register('size', { shouldUnregister: true, required: { value: true, message: 'Please choose an option above.' }})}/>
          <RadioButton className={state?.currentPet.species === 'cat' ? "hidden" : ''} label="Giant (90 lbs or more)" id="radio-giant" name="radio-giant" value="giant" {...register('size', { shouldUnregister: true, required: { value: true, message: 'Please choose an option above.' }})}/>
          <div className={'form-validation-error col-span-full -mt-2'}>{errors?.size?.message}</div>
        </div>
        <button className={'mt-auto sm:mt-8 w-full sm:w-40 btn-primary' } type="submit">Continue</button>
      </form>
    </StepContainer>
  );
}

export default PetSize;
