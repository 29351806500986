import { useNavigate } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";
import GanymedeInput from "../../../components/ganymede/GanymedeInput";
import GanymedeKeyValueTable from "../../../components/ganymede/KeyValueTable";
import common from "../../../lib/common";
import parsePhoneNumber, {isPossiblePhoneNumber} from "libphonenumber-js";
import StepContainer from "../StepContainer";
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENT_CODES } from '../../../constants';
import Select from 'react-select';

const states = [
  { name: 'ALABAMA', abbreviation: 'AL'},
  { name: 'ALASKA', abbreviation: 'AK'},
  { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
  { name: 'ARIZONA', abbreviation: 'AZ'},
  { name: 'ARKANSAS', abbreviation: 'AR'},
  { name: 'CALIFORNIA', abbreviation: 'CA'},
  { name: 'COLORADO', abbreviation: 'CO'},
  { name: 'CONNECTICUT', abbreviation: 'CT'},
  { name: 'DELAWARE', abbreviation: 'DE'},
  { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
  { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
  { name: 'FLORIDA', abbreviation: 'FL'},
  { name: 'GEORGIA', abbreviation: 'GA'},
  { name: 'GUAM', abbreviation: 'GU'},
  { name: 'HAWAII', abbreviation: 'HI'},
  { name: 'IDAHO', abbreviation: 'ID'},
  { name: 'ILLINOIS', abbreviation: 'IL'},
  { name: 'INDIANA', abbreviation: 'IN'},
  { name: 'IOWA', abbreviation: 'IA'},
  { name: 'KANSAS', abbreviation: 'KS'},
  { name: 'KENTUCKY', abbreviation: 'KY'},
  { name: 'LOUISIANA', abbreviation: 'LA'},
  { name: 'MAINE', abbreviation: 'ME'},
  { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
  { name: 'MARYLAND', abbreviation: 'MD'},
  { name: 'MASSACHUSETTS', abbreviation: 'MA'},
  { name: 'MICHIGAN', abbreviation: 'MI'},
  { name: 'MINNESOTA', abbreviation: 'MN'},
  { name: 'MISSISSIPPI', abbreviation: 'MS'},
  { name: 'MISSOURI', abbreviation: 'MO'},
  { name: 'MONTANA', abbreviation: 'MT'},
  { name: 'NEBRASKA', abbreviation: 'NE'},
  { name: 'NEVADA', abbreviation: 'NV'},
  { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
  { name: 'NEW JERSEY', abbreviation: 'NJ'},
  { name: 'NEW MEXICO', abbreviation: 'NM'},
  { name: 'NEW YORK', abbreviation: 'NY'},
  { name: 'NORTH CAROLINA', abbreviation: 'NC'},
  { name: 'NORTH DAKOTA', abbreviation: 'ND'},
  { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
  { name: 'OHIO', abbreviation: 'OH'},
  { name: 'OKLAHOMA', abbreviation: 'OK'},
  { name: 'OREGON', abbreviation: 'OR'},
  { name: 'PALAU', abbreviation: 'PW'},
  { name: 'PENNSYLVANIA', abbreviation: 'PA'},
  { name: 'PUERTO RICO', abbreviation: 'PR'},
  { name: 'RHODE ISLAND', abbreviation: 'RI'},
  { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
  { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
  { name: 'TENNESSEE', abbreviation: 'TN'},
  { name: 'TEXAS', abbreviation: 'TX'},
  { name: 'UTAH', abbreviation: 'UT'},
  { name: 'VERMONT', abbreviation: 'VT'},
  { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
  { name: 'VIRGINIA', abbreviation: 'VA'},
  { name: 'WASHINGTON', abbreviation: 'WA'},
  { name: 'WEST VIRGINIA', abbreviation: 'WV'},
  { name: 'WISCONSIN', abbreviation: 'WI'},
  { name: 'WYOMING', abbreviation: 'WY' }
].map(obj => ({ label: obj.abbreviation, value: obj.abbreviation }));

function PersonalDetailsView({ state, dispatch }) {
  const formMethods = useForm({mode: 'onChange', });
  const {handleSubmit, register, formState: { errors }, setValue, control } = formMethods;

  const onSubmit = async (form) => {
    track(AMPLITUDE_EVENT_CODES.SELF_ENROLL_BILLING_DETAILS, {
      vet: state.vet,
      client: state.client,
      billing_details: { ...form, state: form.state.value },
    });
    dispatch({ type: 'next', personalDetails: { ...form, state: form.state.value } });
  }

  //Checks to see whether there is a non-zero enrollment fee
  const getPlanDetails = () => {

  let planDetails = {'Plan': state?.selectedPlan?.name, 'Monthly Payment': common.pennyIntToDollarString(state?.selectedPlan?.monthly_price)};
  const enrollmentFeeAmt = state?.selectedPlan?.enrollment_fee;

  if( enrollmentFeeAmt > 0) {
    planDetails['Enrollment fee'] = common.pennyIntToDollarString(enrollmentFeeAmt);
    }
    return planDetails;
  }

  return (
    <StepContainer back={() => dispatch({type: 'prev'})}>
      <div className={'font-roboto-slab text-3xl sm:text-4xl text-center mb-8'}>Billing Information</div>
      <div className={''}>
        <GanymedeKeyValueTable
            className={'total-table'}
            style={{width: '100%'}}
            data={getPlanDetails()}/>
        <GanymedeKeyValueTable
            className={'total-table last:[&_th]:text-right'}
            style={{width: '100%', marginTop: '0.63rem'}}
            header={[`Total`, `${common.pennyIntToDollarString(state?.selectedPlan?.monthly_price + state?.selectedPlan?.enrollment_fee)}`]}
        />
      </div>
      <FormProvider {...formMethods}>
        <form className={'flex flex-col items-center w-full'} onSubmit={handleSubmit(onSubmit)}>
          <div className={'flex flex-col w-full mb-8'}>
            <div className="grid grid-cols-7 gap-x-7 gap-y-0">
              <h4 className={'col-span-full'}>Contact Information</h4>
              <GanymedeInput
                  className={'col-span-full sm:col-span-3'}
                  label="First Name"
                  type="text"
                  errors={errors}
                  {...register("firstName", {
                    onChange: (e) => setValue('firstName', e?.target?.value?.trim()),
                    value: state?.personalDetails?.first_name || state?.client.first_name, required: { value: true, message: 'You must enter your first name.' }
                  })}
              />
              <GanymedeInput
                  className={'col-span-full sm:col-span-4'}
                  label="Last Name"
                  type="text"
                  errors={errors}
                  {...register("lastName", {
                    onChange: (e) => setValue('lastName', e?.target?.value?.trim()),
                    value: state?.personalDetails?.last_name || state?.client.last_name, required: { value: true, message: 'You must enter your last name.' }
                  })}
              />
              <GanymedeInput
                  className={'col-span-full sm:col-span-3'}
                  label="Email Address"
                  type="text"
                  errors={errors}
                  {...register("emailAddress", {
                    value: state?.personalDetails?.email || state?.client.email,
                    pattern: {
                      value: /.+@.+\..+/,
                      message: "Email is invalid",
                    },
                    required: { value: true, message: 'You must enter your email address.' }
                  })}
              />
              <GanymedeInput
                  className={'col-span-full sm:col-span-4'}
                  label="Phone Number"
                  type="text"
                  errors={errors}
                  {...register("phoneNumber", {
                    value: state?.personalDetails?.phoneNumber ? parsePhoneNumber(state?.personalDetails?.phoneNumber, 'US')?.formatNational() : state?.client?.phone ? parsePhoneNumber(state?.client?.phone, 'US')?.formatNational() : '',
                    required: { value: true, message: 'You must enter your phone number.' } ,
                    validate: (value) => {
                      return (
                          isPossiblePhoneNumber(value, "US") ||
                          "Invalid phone number"
                      );
                    }
                  })}
              />
              <h4 className={'col-span-full mt-0'}>Billing Address</h4>
              <GanymedeInput
                  className={'col-span-full'}
                  label="Street Address"
                  type="text"
                  errors={errors}
                  {...register("streetAddress", {
                    setValueAs: val => val?.trim(),
                    value: state?.personalDetails?.streetAddress, required: { value: true, message: 'You must enter your street address.' }
                  })}
              />
              <GanymedeInput
                  className={'col-span-full sm:col-span-2'}
                  label="City"
                  type="text"
                  errors={errors}
                  {...register("city", {
                    setValueAs: val => val?.trim(), value: state?.personalDetails?.city, required: { value: true, message: 'You must enter your city.' }
                  })}
              />
              <Controller
                name={'state'}
                rules={{ required: { value: true, message: 'You must enter your state'} }}
                control={control}
                defaultValue={states.find(s => s.value === state?.personalDetails?.state )}
                render={({ field }) =>
                  <div className={'col-span-full sm:col-span-2 mb-3.5'}>
                    <Select
                      placeholder={'State'}
                      options={states}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          height: '3.13rem',
                        }),
                      }}
                      {...field}
                    />
                    {errors?.[field.name] && <span className={'form-validation-error absolute'}>{errors?.[field.name]?.message}</span>}
                  </div>
                }
              />
              <GanymedeInput
                  className={'col-span-full sm:col-span-3'}
                  label="ZIP Code"
                  type="text"
                  errors={errors}
                  {...register("zip", {
                    onChange: (e) => setValue('zip', e?.target?.value?.trim()),
                    value: state?.personalDetails?.zip,
                    required: { value: true, message: 'You must enter your zip code.' },
                    minLength: {  value: 5, message: 'Zip code too short' }
                  })}
              />
            </div>
          </div>
          <button className={'mt-auto w-full sm:w-40 btn-primary' } type="submit">Continue</button>
        </form>
      </FormProvider>
    </StepContainer>
  );
}

export default PersonalDetailsView;
