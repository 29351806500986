import { useForm } from 'react-hook-form';
import RadioButton from '../../../components/RadioButton/RadioButton.js'
import StepContainer from "../StepContainer";
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENT_CODES } from '../../../constants';

function NewOrExistingClient({state, dispatch}) {

  const { formState: { errors }, handleSubmit, register } = useForm({

  });

  const onSubmit = (values) => {
    track(AMPLITUDE_EVENT_CODES.SELF_ENROLL_NEW_OR_EXISTING, {
      vet: state?.vet,
      user_exists: values.existingClient,
    });
    dispatch({type: 'next', payload: { answer: values.existingClient }});
  }

  return (
    <StepContainer>
      <form className={'flex flex-col items-center h-full'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'font-roboto-slab text-3xl sm:text-4xl text-center mb-4'}>Hello! Are you already a client with {state?.vet?.name}?</div>
        <div className="w-full mt-4">
          <RadioButton className={'mb-2'} label="Yes" id="radio-yes" name="yes" value="yes" {...register('existingClient', { shouldUnregister: true, required: { value: true, message: 'Response is required' }})}/>
          <RadioButton label="No" id="radio-no" name="no" value="no" {...register('existingClient', { shouldUnregister: true, required: { value: true, message: 'Response is required' }})}/>
        </div>
        <div className={'mt-2 form-validation-error w-full'}>{errors?.existingClient?.message}</div>
        <button className={'mt-auto sm:mt-8 w-full sm:w-40 btn-primary'} type="submit">Continue</button>
      </form>
    </StepContainer>
  );
}

export default NewOrExistingClient;
