import {useOutletContext} from 'react-router-dom';
import GanymedePageContainer from "../../components/ganymede/PageContainer";
import GanymedeKeyValueTable from "../../components/ganymede/KeyValueTable";
import GanymedePageFooter from "../../components/ganymede/PageFooter";
import GanymedePaymentCardDisplay from "../../components/ganymede/GanymedePaymentCardDisplay";
import { useEffect, useRef, useState } from "react";
import { useForm, FormProvider } from 'react-hook-form';
import ProgressButton from 'react-progress-button';
import "react-progress-button/react-progress-button.css";
import DayJs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENT_CODES } from '../../constants';
DayJs.extend(AdvancedFormat);

function SubscriptionCollectionsPaymentView() {
  const outletContext = useOutletContext();
  const formMethods = useForm({ mode: 'onChange', });
  const { handleSubmit, register, watch, setValue, trigger, formState: { errors, isValid, isSubmitting } } = formMethods;
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(undefined);
  const btnRef = useRef();

  // setup watchers for apiState from context so we can change button state and errors accordingly
  useEffect(() => {
    if (outletContext?.apiState?.type === 'payment') {
      switch(outletContext.apiState.status) {
        case 'loading':
          btnRef?.current?.loading();
          break;
        case 'success':
          btnRef?.current?.success(null, true);
          break;
        case 'error':
          btnRef?.current?.error(null);
          break;
      }
    }
  }, [outletContext?.apiState]);

  const { plan, doUpdatePaymentMethod, apiState } = outletContext;

  const onSubmit = (values) => {
    track(AMPLITUDE_EVENT_CODES.COLLECT_CC_INFO_ENTERED, {
      user: { ...plan.client, cards: undefined, patients: undefined, personalDetails: undefined },
      pet: plan.patient,
      amount: plan?.plan.monthly_price,
      card: values.paymentMethod.external_id ? 'existing' : 'new',
    });
    doUpdatePaymentMethod(values.paymentMethod);
  };

  return (
    <>
      <GanymedePageContainer
        className={'plan-payment-container'}
        trailer={'*This is an estimate. Final prices may vary depending on the situation.'}
      >
        <div className={'flex flex-row text-left items-center px-3 py-1.5 md:px-10 md:py-3 lg:px-20 lg:py-6 bg-white'}>
          <img className={'w-20 mr-2.5'} alt={plan?.vet?.name} src={plan?.vet?.logo_url} /><span className={'text-2xl sm:text-3xl md:text-4xl font-normal'}>{plan?.vet?.name}</span>
        </div>
        <div className={'title'}>
          <div>
            Confirm Payment
          </div>
        </div>
        <GanymedeKeyValueTable className={'ganymede-full-width total'}
          header={[`${plan?.patient.name}'s Care Plan`, '']}
          data={{ [plan?.plan.name]: `${plan?.plan.monthly_price} /month`, '': `Charge recurs monthly on the ${DayJs(plan?.start_at).format('Do')} for ${DayJs(plan?.end_at).diff(plan?.start_at, 'month')} more months` }}
        />
        <div className={'payment-footer'}>
          <FormProvider {...formMethods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={`add-cc-form`}
            >
              <GanymedePaymentCardDisplay
                className={'invoice-payment-card-display'}
                paymentMethods={plan?.client?.cards}
                selectedPaymentMethod={selectedPaymentMethod}
                allowChange={true}
                onSelectPaymentMethod={setSelectedPaymentMethod}
              />
              {apiState?.type === 'payment' && apiState?.status === 'error' && <div className={'form-validation-error invoice-payment-error'}>{apiState.error.message || 'something went wrong with your payment. please try again'}</div>}
              <div className={'complete-payment-container'}>
                <div>
                  <div>
                    <ProgressButton state={isSubmitting || apiState?.status === 'success' ? 'disabled' : ''} durationSuccess={2000} ref={btnRef} controlled={true} className={`complete-payment-button ${!isValid ? 'btn-disabled' : ''}`} type="submit">Complete Payment</ProgressButton>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </GanymedePageContainer>
      <GanymedePageFooter />
    </>
  );
}

export default SubscriptionCollectionsPaymentView;
