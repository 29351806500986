import './invoice.css';
import { useOutletContext } from 'react-router-dom';
import GanymedePageContainer from "../components/ganymede/PageContainer";
import GanymedeKeyValueTable from "../components/ganymede/KeyValueTable";
import CheckCircle from "../assets/svgs/CheckCircle.svg";
import GanymedePageFooter from "../components/ganymede/PageFooter";

function InvoiceReceiptView() {
  const outletContext = useOutletContext();
  if (!outletContext.dagwood) return (<></>);

  const tabulateItems = (invoice, plan) => {
    return invoice.items.reduce((agg, item) => {
      item.covered = !!plan?.coverage?.find(c => c.id === item.id || c === item.id);
      agg[item.name] = (<>
            {item.covered && <span className={'strikethrough'}>{item.price}</span>}
            <span>{item.covered?"$0":item.price}</span></>
      );
      return agg;
    }, {});
  };

  const { dagwood: { invoice, invoice: { practice }, payment, } } = outletContext;

  return (
      <>
        <GanymedePageContainer
            className={'receipt-container'}
            footerContent={
              (<><GanymedeKeyValueTable className={'ganymede-full-width total'} header={['Total Paid Today', payment.amount]} data={{ 'Confirmation Number':payment.id, 'Payment Date': payment.date, 'Payment Method': `${payment.method.provider} ****${payment.method.number}` }}/>
                <GanymedeKeyValueTable className={'ganymede-full-width'} header={ 'Payment Details' }
                                       data={tabulateItems(invoice, invoice.plan)}/></>)
            }
        >
          <div className={'flex flex-row text-left items-center px-3 py-1.5 md:px-10 md:py-3 lg:px-20 lg:py-6 bg-white'}>
            <img className={'w-20 mr-2.5'} alt={practice.name} src={practice.logo} /><span className={'text-2xl sm:text-3xl md:text-4xl font-normal'}>{practice.name}</span>
          </div>
          <div className={'receipt-header'}>
            <span className={'payment-success-icon'}>
              <img src={CheckCircle}  alt={'success icon'} />
            </span>
            <span className={'receipt-title'}>Payment Successful</span>
            {invoice.plan && <span className={'receipt-subheading'}>
              {invoice.pet.name} is now enrolled in {invoice.plan.name}, we look forward to seeing you both soon.
              You will receive a confirmation email shortly.
            </span>}
          </div>
        </GanymedePageContainer>
        <GanymedePageFooter />
      </>
  );
}

export default InvoiceReceiptView;
