import {useOutletContext} from 'react-router-dom';
import GanymedePageContainer from "../components/ganymede/PageContainer";
import GanymedeDocumentHeader from "../components/ganymede/DocumentHeader";
import GanymedeKeyValueTable from "../components/ganymede/KeyValueTable";
import InvoiceColumnHeader from "../components/ganymede/InvoiceColumnHeader";
import snoutIcon from "../components/ganymede/snout.png";
import GanymedePageFooter from "../components/ganymede/PageFooter";
import SmallerCheckCircle from '../assets/svgs/SmallerCheckCircle.svg';
import parsePhoneNumber from 'libphonenumber-js';

function tabulateInvoicePlans (invoice) {
  const tableSections = [];
  if (invoice.items?.length) { tableSections.push({ title: 'Today\'s Services', items: invoice.items })}
  if (invoice.planItems?.length) { tableSections.push({ title: 'Future Care Plan', items: invoice.planItems })}
  const table = {};
  tableSections.map(tableSection => {
    return [tableSection.title, tableSection.items.map(item => {
      return [item.name, ...invoice.plans.map(plan => {
        const coverage = plan.coverage.find(coverage => (coverage?.id || coverage) === item.id);
        return coverage ? (coverage?.price || <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><img style={{marginRight: '0.31rem'}} src={SmallerCheckCircle}  alt={'included'} />{item.max_quantity > 1 && <div style={{color: '#e05338', fontSize: '1rem', fontWeight: 600,}}>x{item.max_quantity}</div>}</div>) : '-';
      }), item.price,];
    })];
  }).forEach(tableSection => {
    const sectionRows = {};
    tableSection[1].forEach(sectionRow => { sectionRows[sectionRow[0]] = sectionRow.slice(1)});
    table[tableSection[0]] = sectionRows;
  });
  return table;
}

function InvoiceSelectionView() {
  const outletContext = useOutletContext();
  if (!outletContext.dagwood) return (<></>);

  const { dagwood: { invoice, invoice: { practice, client, pet, plans, total, due, } }, doSelectPlan } = outletContext;

  const tabularInvoicePlans = tabulateInvoicePlans(invoice);

  const style = { height: '0.75rem', width: '0.75rem', display: 'inline', marginRight: '0.19rem'};

  return (
      <>
        <GanymedePageContainer className="invoice-selection-container" trailer={'*This is an estimate. Final prices may vary depending on the situation.'}>
          <div className={'InvoiceHeader'}>
            <GanymedeDocumentHeader
                badge={<img alt="vet icon" src={practice.logo} style={{ height: '5.50rem', width: '4.38rem'}}/>}
                supertext={invoice.dateCreated}
                title={`${pet.name}'s Invoice`}
                attribution={(
                    <>
                      {practice.attribution.map((attr, index) => (<span key={index}>{attr}</span>))}
                    </>
                )}
            />
          </div>
          <div className={'InvoiceDetailTables'}>
            <GanymedeKeyValueTable header={ 'Pet Details' } data={{ 'Pet Name':pet.name, 'Sex':pet.sex, 'Species':pet.species }}/>
            <GanymedeKeyValueTable header={ 'Owner Details' } data={{ 'Owner Name':client.name, 'Email':client.email, 'Phone':parsePhoneNumber(client.phone)?.formatNational() }}/>
          </div>
          <div className={'InvoiceBody'}>
            <GanymedeKeyValueTable className={'GanymedeLarge'}
              header={
                [
                  (<span>{`${pet.name}'s`}<br/>Care Plan</span>),
                  ...plans.map((plan, index) => {
                    return <InvoiceColumnHeader key={index} labelTop={(<span><img alt="" src={snoutIcon} style={style}/>Snout</span>)} labelBottom={plan.name} value={plan.total.price} subscript={`/${plan.total.period}`} />
                  }),
                  (<InvoiceColumnHeader labelTop={'Without'} labelBottom={'Wellness Plan'} value={total} subscript={'Total'} />),
                ]
              }
              data={tabularInvoicePlans}
              footer={['Due Today', ...plans.map(p => p.due), due]}
              trailer={[(<></>),
                ...plans.map((plan) => (<button key={plan.id} onClick={() => doSelectPlan(plan.id)}>Choose Plan</button>)),
                (<button onClick={() => doSelectPlan(undefined)}>Pay In Full</button>),
              ]}
            />
          </div>
        </GanymedePageContainer>
        <GanymedePageFooter />
      </>
  );
}

export default InvoiceSelectionView;
