import './subscription.css';
import {Outlet, useNavigate, useOutletContext} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENT_CODES } from '../../constants';

function InvitationView() {

  const { code, dagwood, apiState, createSubscription, personalDetails, setPersonalDetails } = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!dagwood) navigate(`../load/${code}`);
  }, [dagwood]);

  const [plan, setPlan] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [paymentMode, setPaymentMode] = useState('select');
  const [tos, setTos] = useState();
  const [tosCount, setTosCount] = useState(0);

  // track checking membership agreement in amplitude
  useEffect(() => {
    if (tos && tosCount < 1) {
      track(AMPLITUDE_EVENT_CODES.ENROLL_AGREEMENT_BOX_CHECKED, {
        user: { ...dagwood.client, cards: undefined, patients: undefined, personalDetails: undefined },
        pet: dagwood.patient,
        plan: dagwood.plans[plan],
      });
      setTosCount(1);
    }
  }, [tos, tosCount]);

  // route to a flow based upon invitation state
  const stateRouteMap = { new: 'chooser', paid: 'receipt' }

  if (!dagwood) return <></>;

  const choosePlan = async (planIndex) => {
    setPlan(planIndex);
    track(AMPLITUDE_EVENT_CODES.ENROLL_PLAN_SELECTED, {
      user: { ...dagwood.client, cards: undefined, patients: undefined, personalDetails: undefined },
      pet: dagwood.patient,
      plans: JSON.stringify(dagwood.plans),
      plan: dagwood.plans[planIndex],
    });
    navigate('personal-details');
  };

  const checkout = async (paymentMethod) => {
    setPaymentMethod(paymentMethod);
    track(AMPLITUDE_EVENT_CODES.ENROLL_CC_INFO_ENTERED, {
      user: { ...dagwood.client, cards: undefined, patients: undefined, personalDetails: undefined },
      pet: dagwood.patient,
      plan: dagwood.plans[plan],
      card: paymentMethod.external_id ? 'existing' : 'new',
    });
    await createSubscription(dagwood.external_id, dagwood.plans[plan].id, paymentMethod);
  };

  return (
    <Outlet context={{ dagwood, apiState, checkout, choosePlan, plan, personalDetails, setPersonalDetails,
      paymentMethod, setPaymentMethod, paymentMode, setPaymentMode, tos, setTos }}/>
  );

}

export default InvitationView;
