import StepContainer from "../StepContainer";

function LoadingStep({state, dispatch}) {

  return (
      <StepContainer>
        <div className={'flex flex-col justify-center h-full'}>
          <h1 className={'font-roboto-slab text-3xl sm:text-4xl mb-8'}>Could not load this page</h1>
          <p className={'text-gray-500'}>The address or link you used to arrive here could be out of date.</p>
          <p className={'text-gray-500'}>
            If you copied a URL and pasted it into the address bar, please ensure you selected the whole thing.
            Otherwise, please wait a few minutes and try the link again.
          </p>
          <p className={'text-gray-500'}>If that doesn't help, please <a className={'text-snout-orange'} href="mailto:kyle@snoutid.com">contact us</a> and we'll help you out.</p>
        </div>
      </StepContainer>
  );
}

export default LoadingStep;
