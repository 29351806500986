import SnoutLoadingDots from '../../../components/snout-loading-dots/SnoutLoadingDots';
import StepContainer from "../StepContainer";
import { useEffect } from "react";
import api from "../../../services/jupiter_api";

function LoadingStep({state, dispatch}) {

  useEffect(() => {
    api.getVetBySlug(state.vetSlug)
        .then((vet) => {
          dispatch({ type: 'loadVet', vet});
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: 'loadVetError' });
        })
  }, []);

  return (
    <StepContainer>
      <div className={'flex flex-col justify-center h-full'}>
        <SnoutLoadingDots />
      </div>
    </StepContainer>
  );
}

export default LoadingStep;
