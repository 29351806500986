import {Outlet, useNavigate, useOutletContext} from 'react-router-dom';
import {useState} from "react";

function InvoiceView() {
  const navigate = useNavigate();
  const context = useOutletContext();
  const { dagwood } = context;

  const [selectedPlanId, setSelectedPlanId] = useState();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(dagwood?.paymentMethods.find(m => m.default));

  return (
    <>
      <Outlet context={{
        doSelectPlan: (planId) => {
          setSelectedPlanId(planId);
          navigate('payment');
        },
        doCompletePayment: () => {},
        selectedPlanId,
        setSelectedPlanId,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        ...context
      }}/>
    </>
  );
}

export default InvoiceView;
