const DayJs = require("dayjs");
DayJs.extend(require('dayjs/plugin/customParseFormat'));

const common = {

  validatePetBirthDate: (year, month, day) => {
    const inputDate = DayJs(`${year}-${month+1}-${day}`, 'YYYY-M-D', true);
    const today = DayJs();
    const thirtyYearsAgo = DayJs().subtract(30, 'year');
    if (!inputDate.isValid()) {
      return 'Please enter a valid date.';
    } else if (inputDate.isBefore(thirtyYearsAgo)) {
      return 'Pets cannot be older than thirty years. Please re-enter your pet\'s birthdate.';
    } else if (inputDate.isAfter(today)) {
      return 'Pets cannot travel through time. Please re-enter your pet\'s birthdate.';
    }
    return undefined; // indicates a valid birthdate
  }

}

module.exports = common;
