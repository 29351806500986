import { useOutletContext } from 'react-router-dom';
import DayJs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import common from '../../lib/common';
DayJs.extend(relativeTime);

function PatientPlanUsage() {
  const { planPatient } = useOutletContext();

  return (
    <div className={'flex flex-col'}>
      <div className={'p-5 text-[#999999] bg-[#fafafa]'}>
        <div className={'text-xs font-bold'}>PLAN</div>
        <div className={'font-bold text-base'}>{planPatient?.plan_instance.name}</div>
        <div>
          <span className={'font-bold text-base'}>Start:&nbsp;</span>
          <span className={'text-base'}>{DayJs(planPatient?.plan_instance?.start).format('MMMM DD, YYYY')}</span>
        </div>
        <div>
          <span className={'font-bold text-base'}>End:&nbsp;</span>
          <span className={'text-base'}>{DayJs(planPatient?.plan_instance?.end).format('MMMM DD, YYYY')}&nbsp;</span>
          <span className={'text-base'}>({DayJs(planPatient?.plan_instance?.end).fromNow(true)} remaining)</span>
        </div>
        <div>
          <span className={'font-bold text-base'}>Monthly Price:&nbsp;</span>
          <span className={'text-base'}>{common.pennyIntToDollarString(planPatient?.plan_instance?.monthly_price)}</span>
        </div>
      </div>

      <div className={'grid grid-cols-7 rounded-md gap-0 mt-5 border border-solid border-stone-300'}>
        <div className={'col-span-3 sm:col-span-5 p-4 font-bold text-xs bg-stone-50 text-stone-500 rounded-tl-md'}>
          Benefit
        </div>
        <div className={'col-span-2 sm:col-span-1 p-4 font-bold text-xs bg-stone-50 text-stone-500 text-center'}>
          Maximum
        </div>
        <div className={'col-span-2 sm:col-span-1 p-4 font-bold text-xs bg-stone-50 text-stone-500 rounded-tr-md text-center'}>
          Remaining
        </div>
        <div className={'grid grid-cols-7 col-span-full border border-solid border-t-stone-300 border-b-0 border-l-0 border-r-0'}>
          {planPatient?.plan_instance?.usage?.map((item, i) => (
            <>
              <div key={`${i}-description`} className={`col-span-3 sm:col-span-5 p-4 ${i === 0 ? '!border-0' : ''} border border-solid border-t-stone-300 border-b-0 border-l-0 border-r-0`}>{item.description}</div>
              <div key={`${i}-amount`} className={`col-span-2 sm:col-span-1 p-4 ${i === 0 ? '!border-0' : ''} border border-solid border-t-stone-300 border-b-0 border-l-0 border-r-0 text-center`}>{item.max_quantity}</div>
              <div key={`${i}-remaining`} className={`col-span-2 sm:col-span-1 p-4 ${i === 0 ? '!border-0' : ''} border border-solid border-t-stone-300 border-b-0 border-l-0 border-r-0 text-center`}>{item.max_quantity - item.uses.quantity}</div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PatientPlanUsage;
