import { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import GanymedeInput from './GanymedeInput';
import defaultCardIcon from '../../assets/svgs/credit-card-icon.svg';
import visa from '../../assets/svgs/visa.svg';
import amex from '../../assets/svgs/amex.svg';
import dinersClub from '../../assets/svgs/diners-club.svg';
import jcb from '../../assets/svgs/jcb.svg';
import discover from '../../assets/svgs/discover.svg';
import mastercard from '../../assets/svgs/mastercard.svg';

import GanymedeCreditCardNumberInput from './GanymedeCreditCardNumberInput';
import GanymedeCreditCardExpiryInput from './GanymedeCreditCardExpiryInput';
import Cvv from '../../assets/pngs/cvv-tip.png';

const ccType = require('credit-card-type');
const DEFAULT_CARD_DISPLAY_LENGTH = 4;

const cardIconMap = {
  visa,
  ['american express']: amex,
  jcb,
  discover,
  mastercard,
  ['diners-club']: dinersClub,
  undefined: defaultCardIcon,
};

function GanymedePaymentCardDisplay({
  paymentMethods = [],
  mode = 'select',
  setMode = () => {},
  selectedPaymentMethod,
  setPaymentMethod = () => {},
  ...props
}) {
  const [showAllCards, setShowAllCards] = useState(false);
  const {
    control,
    register,
    unregister,
    setValue,
    formState: { errors },
    watch,
    clearErrors,
    trigger,
    resetField,
  } = useFormContext();
  const paymentMethod = watch('paymentMethod');
  const expiry = watch('paymentMethod.expiry');
  const paymentMethodRadio = watch('paymentMethodRadio');

  register('paymentMethodRadio');

  // headless form values for expiration month and year
  register('paymentMethod.exp_month');
  register('paymentMethod.exp_year');
  const zip = register('paymentMethod.postal_code', {
    required: { value: mode === 'add', message: 'Zip code is required' },
  });

  const cvc = watch('paymentMethod.cvc');
  // watch the expiry input and just parse it into month and year values
  useEffect(() => {
    if (expiry?.includes('/')) {
      const [month, year] = expiry.split(' / ');
      setValue('paymentMethod.exp_month', month);
      setValue('paymentMethod.exp_year', year);
    }
  }, [expiry]);

  useEffect(() => {
    if (mode === 'select' && !paymentMethods.length) {
      setMode('add');
    }
    // eslint-disable-next-line
  }, [paymentMethods, mode]);

  useEffect(() => {
    if (mode === 'add') {
      setValue('paymentMethodRadio', undefined, { shouldValidate: true });
      setValue('paymentMethod', null);
      setValue('paymentMethod.cvc', null);
      setPaymentMethod(null);
    }
  }, [mode, paymentMethodRadio]);

  useEffect(() => {
    if (paymentMethodRadio) {
      setValue('paymentMethod', paymentMethods[paymentMethodRadio]);
      setValue('paymentMethod.cvc', cvc, { shouldValidate: true });
    }
  }, [paymentMethodRadio]);

  const cancel = () => {
    setMode('select');
    unregister('paymentMethod');
  };

  const CardDisplay = ({ card }) => {
    const brand =
      card.brand?.toLowerCase() ||
      ccType(card.cardNumber || card.last4)[0]?.type;
    return (
      <div className={'flex w-full justify-between'}>
        <span className={'inline-card-display'}>
          <img
            className={'cc-icon'}
            src={cardIconMap[brand]}
            alt={'credit card icon'}
          />
          <span className={'CardNumber ml-2 text-lg sm:ml-4 sm:text-xl'}>
            <span className={'hidden sm:inline-block'}>
              ••••&nbsp;••••&nbsp;
            </span>
            {`•••• ${card?.cardNumber?.slice(-4) || card.last4}`}
          </span>
        </span>
        <span
          className={
            'absolute right-1 top-0.5 left-auto text-xs text-gray-400 sm:relative sm:right-auto sm:top-auto sm:text-base'
          }
        >
          {`expires ${card.exp_month}/${card.exp_year}`}
        </span>
      </div>
    );
  };

  return (
    <div className={`ganymede-payment-card-container ${props.className || ''}`}>
      <div className={'inner-ganymede-payment-card-container'}>
        <h4 style={{ marginBottom: mode === 'add' ? '0.63rem' : 0 }}>
          {mode === 'add' ? 'Add Payment Method' : 'Select Payment Method'}
        </h4>
        {/* display/select mode */}
        {mode === 'select' && paymentMethods?.length && (
          <>
            <div className={'change-btn-container'}>
              <div>
                <button
                  className={'ChangeButton'}
                  onClick={() => setMode('add')}
                >
                  Add New Card
                </button>
              </div>
            </div>
            <div className={'payment-methods'}>
              {paymentMethods
                ?.slice(0, DEFAULT_CARD_DISPLAY_LENGTH)
                ?.map((card, index) => {
                  return (
                    <label
                      className={`payment-method relative ${
                        paymentMethod?.external_id === card.external_id
                          ? 'selected-payment-method'
                          : ''
                      }`}
                      key={index}
                    >
                      <div>
                        <div className={'grid-span-3'}>
                          <input
                            name='paymentMethod'
                            type='radio'
                            {...register('paymentMethodRadio', {
                              value: index.toString(),
                              shouldUnregister: true,
                              required: {
                                value: true,
                                message: 'Select a card',
                              },
                            })}
                            value={index.toString()}
                            defaultChecked={
                              index.toString() === paymentMethodRadio
                            }
                          />
                        </div>
                        <CardDisplay card={card} />
                      </div>
                      {paymentMethod?.external_id === card.external_id && (
                        <div
                          className={
                            'cvv-capture grid grid-cols-6 grid-cols-6 gap-2 sm:mt-4'
                          }
                        >
                          <div className={'col-span-full sm:col-span-1'}>
                            <GanymedeInput
                              type={'tel'}
                              autoFocus
                              errors={errors}
                              maxLength={4}
                              label={'CVC'}
                              className={`selected-card-cvv-input ${
                                errors?.paymentMethod?.cvc
                                  ? 'invalid-input'
                                  : ''
                              }`}
                              {...register('paymentMethod.cvc', {
                                required: {
                                  value: true,
                                  message: 'CVC is required',
                                },
                                minLength: {
                                  value: 3,
                                  message: 'CVC is not valid',
                                },
                                min: 3,
                              })}
                            />
                          </div>
                          <div className={'col-span-full sm:col-span-3'}>
                            <img
                              className={'cvv-tip-image'}
                              alt={'cvv'}
                              src={Cvv}
                            />
                            Last 3 digits on the back of your card
                          </div>
                        </div>
                      )}
                    </label>
                  );
                })}
            </div>
            {paymentMethods?.length > DEFAULT_CARD_DISPLAY_LENGTH && (
              <>
                {!showAllCards && (
                  <button
                    onClick={() => setShowAllCards(true)}
                    className={'expand-payment-methods-btn'}
                  >
                    show all
                  </button>
                )}
                {showAllCards && (
                  <div className={'payment-methods'}>
                    {paymentMethods
                      ?.slice(DEFAULT_CARD_DISPLAY_LENGTH)
                      ?.map((card, index) => {
                        return (
                          <label
                            className={`payment-method ${
                              paymentMethod?.external_id === card.external_id
                                ? 'selected-payment-method'
                                : ''
                            }`}
                            key={index + DEFAULT_CARD_DISPLAY_LENGTH}
                          >
                            <div>
                              <div className={'grid-span-3'}>
                                <input
                                  name='paymentMethod'
                                  type='radio'
                                  {...register('paymentMethodRadio', {
                                    value: index.toString(),
                                    shouldUnregister: true,
                                    required: {
                                      value: true,
                                      message: 'Select a card',
                                    },
                                  })}
                                  value={index.toString()}
                                  defaultChecked={
                                    index.toString() === paymentMethodRadio
                                  }
                                />
                              </div>
                              <CardDisplay card={card} />
                            </div>
                            {paymentMethod?.external_id ===
                              card.external_id && (
                              <div
                                className={
                                  'cvv-capture grid grid-cols-6 grid-cols-6 gap-2 sm:mt-4'
                                }
                              >
                                <div className={'col-span-full sm:col-span-1'}>
                                  <GanymedeInput
                                    type={'tel'}
                                    autoFocus
                                    errors={errors}
                                    maxLength={4}
                                    label={'CVC'}
                                    className={`selected-card-cvv-input ${
                                      errors?.paymentMethod?.cvc
                                        ? 'invalid-input'
                                        : ''
                                    }`}
                                    {...register('paymentMethod.cvc', {
                                      required: {
                                        value: true,
                                        message: 'CVC is required',
                                      },
                                      minLength: {
                                        value: 3,
                                        message: 'CVC is not valid',
                                      },
                                      min: 3,
                                    })}
                                  />
                                </div>
                                <div className={'col-span-full sm:col-span-3'}>
                                  <img
                                    className={'cvv-tip-image'}
                                    alt={'cvv'}
                                    src={Cvv}
                                  />
                                  Last 3 digits on the back of your card
                                </div>
                              </div>
                            )}
                          </label>
                        );
                      })}
                  </div>
                )}
              </>
            )}
            {errors?.paymentMethodRadio && (
              <div className={'form-validation-error'}>
                {errors?.paymentMethodRadio.message}
              </div>
            )}
          </>
        )}
        {/* edit mode */}
        {mode === 'add' && (
          <>
            <div className={`add-cc-form grid grid-cols-6 gap-x-2.5`}>
              <GanymedeInput
                className={'col-span-full sm:col-span-2'}
                label='First Name'
                type='text'
                errors={errors}
                {...register('paymentMethod.first_name', {
                  shouldUnregister: false,
                  required: { value: true, message: 'First name is required' },
                })}
              />
              <GanymedeInput
                className={'col-span-full sm:col-span-2'}
                label='Last Name'
                type='text'
                errors={errors}
                {...register('paymentMethod.last_name', {
                  shouldUnregister: false,
                  required: { value: true, message: 'Last name is required' },
                })}
              />
              <GanymedeInput
                className={'col-span-full sm:col-span-2'}
                label='Zip'
                type='text'
                errors={errors}
                {...zip}
              />
              <GanymedeCreditCardNumberInput
                className={'col-span-full sm:col-span-3'}
                label='Card Number'
                name={'paymentMethod.number'}
                rules={{
                  required: {
                    shouldUnregister: false,
                    value: true,
                    message: 'Card number is required',
                  },
                }}
              />
              <GanymedeCreditCardExpiryInput
                className={'col-span-full sm:col-span-2'}
                label='Expiry'
                name={'paymentMethod.expiry'}
                rules={{
                  shouldUnregister: false,
                  required: { value: true, message: 'Expiry is required' },
                }}
              />
              <GanymedeInput
                className={'col-span-full sm:col-span-1'}
                label='CVC'
                type='tel'
                errors={errors}
                maxLength={4}
                {...register('paymentMethod.cvc', {
                  required: { value: true, message: 'CVC is required' },
                  minLength: { value: 3, message: 'CVC is not valid' },
                  min: 3,
                })}
              />
              {paymentMethods?.length > 0 && (
                <div className={'cc-btn-group col-span-full'}>
                  <button
                    className={'cc-cancel-btn'}
                    onClick={cancel}
                    type='button'
                  >
                    Use Existing Card
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default GanymedePaymentCardDisplay;
