import './basic-invoice.css';
import syossetIcon from "../../../components/ganymede/syosset.png";
import GanymedeDocumentHeader from "../../../components/ganymede/DocumentHeader";
import GanymedeKeyValueTable from "../../../components/ganymede/KeyValueTable";
import InvoiceColumnHeader from "../../../components/ganymede/InvoiceColumnHeader";
import snoutIcon from "../../../components/ganymede/snout.png";
import GanymedePageContainer from "../../../components/ganymede/PageContainer";
import GanymedePageFooter from "../../../components/ganymede/PageFooter";

function BasicInvoiceView() {
  return (
      <div>
        <GanymedePageContainer trailer={'*This is an estimate. Final prices may vary depending on the situation.'}>
          <div className={'InvoiceHeader'}>
            <GanymedeDocumentHeader
                badge={<img alt="vet icon" src={syossetIcon} style={{ height: '5.50rem', width: '4.38rem'}}/>}
                supertext={'March 31, 2022'}
                title={'Bowser\'s Invoice'}
                attribution={(
                    <>
                      <span>Syosset Animal Hospital</span>
                      <span>123 Main St.</span>
                      <span>Seattle, WA 98015</span>
                      <span>(415) 823-2934</span>
                    </>
                )}
            />
          </div>
          <div className={'InvoiceDetailTables'}>
            <GanymedeKeyValueTable header={ 'Pet Details' } data={{ 'Pet Name':'Bowser', 'Sex':'Female', 'Species':'Dog' }}/>
            <GanymedeKeyValueTable header={ 'Owner Details' } data={{ 'Owner Name':'John Smith', 'Email':'john@smith.com', 'Phone':'(123) 123-1234' }}/>
          </div>
          <div className={'InvoiceBody'}>
            <GanymedeKeyValueTable className={'GanymedeLarge'} header={[(<span>Bowser's<br/>Care Plan</span>), (<InvoiceColumnHeader labelTop={'Without'} labelBottom={'Wellness Plan'} value={'$1,565'} subscript={'Total'} />), (<InvoiceColumnHeader labelTop={(<span><img alt="" src={snoutIcon} style={{ height: '0.75rem', width: '0.75rem', display: 'inline', marginRight: '0.19rem'}}/>Snout</span>)} labelBottom={'Wellness Plan'} value={'$140'} subscript={'/month'} />), (<InvoiceColumnHeader labelTop={(<span><img alt="" src={snoutIcon} style={{ height: '0.75rem', width: '0.75rem', display: 'inline', marginRight: '0.19rem'}}/>SnoutID</span>)} labelBottom={'Complete Coverage'} value={'$190*'} subscript={'/month'} />)]}
                                   data={{
                                     'Today\'s Services': { 'Exam':['$120','Free','Free'], 'Rabies':['$35','Covered','Covered'], 'Bordetella':['$35', 'Covered', 'Covered'], 'Urine':['$120', 'Covered', 'Covered'] },
                                     'Future Care Plan': { 'Fecal':['$35','Covered','Covered'], 'Bloodwork':['$35','Covered','Covered'], 'Simparica':['$120','Covered','Covered'], 'Dental':['$35*','Covered','Covered'], 'Medication Delivery':['Not Included','Free','Free'], 'Accidentals':['Per Incident','Not Included','$50 /month*'], '24Hr Service via App':['Not Included','Free','Free'] },
                                   }}
                                   footer={['Due Today', '$310', '$140', '$140']}
                                   trailer={[(<></>),
                                     (<button>Pay Invoice</button>),
                                     (<button>Choose Plan</button>),
                                     (<button>Choose Plan</button>)]}
            />
          </div>
        </GanymedePageContainer>
        <GanymedePageFooter />
      </div>
  );
}

export default BasicInvoiceView;
