import './invoice.css';
import {useParams, Outlet, useNavigate} from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../services/jupiter_api';

function InvoiceViewController() {
  const invoiceId = useParams()?.invoiceId;
  const navigate = useNavigate();
  const [dagwood, setDagwood] = useState();
  const [apiError, setApiError] = useState();

  // load the invoice on init
  useEffect(() => {
    (async () => setDagwood(await api.getInvoiceDagwood(invoiceId)))();
    // eslint-disable-next-line
  }, []);

  // route to a flow based upon invoice state
  const stateRouteMap = { 'undefined': '', pending: 'view/selection', new: 'view/selection', paid: 'receipt', hasWellness: 'view/payment' }
  useEffect(() => {
    const hasWellness = dagwood?.invoice?.currentPlan;
    let state = dagwood?.invoice?.state;
    state = hasWellness && state !== 'paid' && 'hasWellness' || state;
    !dagwood?.result?.error && navigate(stateRouteMap[state], { replace: true });

    // eslint-disable-next-line
  }, [dagwood]);

  const doPayment = async (invoiceId, invoiceUpdated, selectedPlanId, selectedPaymentMethodId) => {
    setApiError(undefined);
    try {
      setDagwood(await api.payInvoice(invoiceId, invoiceUpdated, selectedPlanId, selectedPaymentMethodId));
    } catch (e) {
      setApiError(e);
    }
  }

  return (
    <>
      <Outlet context={{ dagwood, doPayment, apiError }}/>
    </>
  );
}

export default InvoiceViewController;
