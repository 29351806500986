import React, { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';


const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

const GanymedeCurrencyInput = React.forwardRef(({
                                    name,
                                    label,
                                    rules = {},
                                    max = Number.MAX_SAFE_INTEGER,
                                    ...props
                                  }, ref) => {
  const { control, formState: { errors } } = useFormContext();
  const {
    field: { onChange, onBlur, name: fieldName, value, ref: fieldRef },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules,
    defaultValue: "",
  });

  const handleKeyDown = useCallback(
    (e) => {
    const { key, keyCode } = e;
    if (
      (value === 0 && !VALID_FIRST.test(key)) ||
      (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
    ) {
      return;
    }
    const valueString = value.toString();
    let nextValue;
    if (keyCode !== DELETE_KEY_CODE) {
      const nextValueString = value === 0 ? key : `${valueString}${key}`;
      nextValue = Number.parseInt(nextValueString, 10);
    } else {
      const nextValueString = valueString.slice(0, -1);
      nextValue = nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10);
    }
    if (nextValue > max) {
      return;
    }
    onChange(nextValue);
  },
  [max, onChange, value]
);
  const handleChange = useCallback(() => {
    // DUMMY TO AVOID REACT WARNING
  }, []);
  const valueDisplay = (value / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  // method that gets object property that handles nesting ex. formGroup.firstName
  const getDotNotatedProperty = (obj, path) => {
    return path.split('.').reduce((o, i) => o?.[i], obj);
  };

  return (
    <div className={`ganymede-input form-control-container ${props.className || ''}`}>
      <input
        className={`ganymede-currency-input ${value > 0 && 'has-value'}`}
        inputMode="numeric"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={valueDisplay}
        ref={ref}
      />
      <label className={value ? 'ganymede-input-label-shrunk' : ''}>{label}</label>
      {getDotNotatedProperty(errors, name)?.message && <span className={'form-validation-error'}>{getDotNotatedProperty(errors, name)?.message}</span>}
    </div>
  );
});

export default GanymedeCurrencyInput;