

import common from "../../../lib/common";
import SmallerCheckCircle from '../../../assets/svgs/SmallerCheckCircle.svg'
import { useState } from 'react';
import StepContainer from "../StepContainer";
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENT_CODES } from '../../../constants';
import DayJs from "dayjs";

const renderPlanItems = (items, { displayHidden = false, showGroupedItems = false, displayQuantity = false, }) => {
  if (items) {
    // group and omit items
    const itemsConfig = items.reduce((agg, item, index, array) => {
      if (!item.is_hidden && displayHidden) {
        if (item.display_group) {
          const firstItemInGroupIdx = array.findIndex(itm => itm.display_group === item.display_group);
          agg[firstItemInGroupIdx] = agg[firstItemInGroupIdx]?.concat(item) || [item];
        } else {
          agg[index] = item;
        }
      }
      return agg;
    }, []).filter(o=>o);

    // NOTE this should probably just be told what components to feed props (itemGroup) into so we can
    //  modify the display depending on what page this is
    return itemsConfig.map((itemGroup, key) => {
      if (itemGroup.length) {
        return (
          <div className={'plan-item-group'} key={key}>
            <div className='flex flex-row items-center mt-2' >
              {<img className='w-4.5 mr-2.5' src={SmallerCheckCircle} alt={'included'}/>}
              <div>
                {itemGroup[0].display_group}
              </div>
            </div>
            {showGroupedItems && itemGroup.map((item, index) => (
              <li key={index} className={'inner-plan-item text-sm ml-7'}>{item.description}</li>
            ))}
          </div>
        );
      }
      else {
          return (
            <div className='plan-item flex flex-row items-center my-2' key={key}>
              {displayQuantity ? <div className='w-4 p-0.5 h-4 mr-2.5 rounded-full bg-snout-orange text-white text-xs flex justify-center items-center font-bold'>{itemGroup.max_quantity}</div> : <img className='w-4 mr-2.5' src={SmallerCheckCircle} alt={'included'}/>}
              <div>
                {itemGroup.description}
              </div>
            </div>
          )
        }
    });
  }
  return null;
}

function PlanItems({plan, ...props}) {
  return renderPlanItems(plan.items, { displayHidden: true, showGroupedItems: true, displayQuantity: true})
}

function PlanCard({plan, onClick}) {
return (
  <div className={'plan-card hover:drop-shadow-lg flex flex-col px-4 py-4 border border-solid rounded-md border-stone-300 bg-white '}>
    <div className='text-snout-orange font-roboto-slab text-base font-semibold'>{plan.name}</div>
    <div className='flex flex-row flex-nowrap items-baseline font-roboto-slab'><span className='text-3xl font-medium'>{common.pennyIntToRoundedDollarString(plan.monthly_price)}/</span><span>month</span></div>
    <div className="mt-4"><span className='bg-red-100 font-semibold rounded-md text-snout-orange font-roboto-slab text-sm px-2 py-1'>{common.getSavings(plan)} Savings</span></div>
    <hr className='border-0 bg-stone-300 h-[1px] w-full my-4'/>
    {/* <PlanItems className='mb-4' plan={plan}/> */}
    <button className={'cursor-pointer mt-auto w-full btn-secondary'} type="button" onClick={onClick}>Choose Plan</button>

  </div>
  );
}

function PlanPicker({state, dispatch}) {
  const twoYearsAgo = DayJs().subtract(2, 'years');
  const youngPatient = DayJs(state.currentPet?.birthdate)?.isAfter(twoYearsAgo);

  const planFilter = (plan) => {
    return (
        // filter plans which include neuter if animal is already neutered
        !(state?.currentPet?.spayNeuter === 'yes' && plan.includes_neuter) &&
        // filter plans which are age inappropriate for the animal
        !(youngPatient && plan.target_age === 'senior') &&
        !(!youngPatient && plan.target_age === 'young') &&
        // filter plans which are for the other gender
        !(plan.sex && state?.currentPet?.sex !== plan.sex)
    );
  };

  const [currentPlanId, setCurrentPlanId] = useState(state.selectedPlan?.id || state?.speciesPlans?.filter(planFilter)[0].id);

  const selectPlan = (planId) => {
    const selectedPlan = state?.speciesPlans?.find(p => p.id === planId);
    track(AMPLITUDE_EVENT_CODES.SELF_ENROLL_SELECT_PLAN, {
      vet: state.vet,
      client: state.client,
      pet: state.currentPet,
      plan: selectedPlan,
    });
    dispatch({type: 'next', selectedPlan })
  };

  return (
    <StepContainer back={() => dispatch({type: 'prev'})} wide={true}>
      <div className={'flex flex-col items-center h-full'}>
        <div className={'font-roboto-slab text-3xl sm:text-4xl text-center'}>We’ve got some great options for {state?.currentPet?.name[0].toUpperCase() + state?.currentPet?.name.slice(1)}</div>
        <div className={'text-gray-500 text-center my-5'}>
          We think you’ll like them too.
          { state?.vet?.plans_details_pdf_url &&
            <> See what’s included in each plan <a href={state.vet.plans_details_pdf_url} target="_blank">here</a>.</>
          }
        </div>

        {/* START DESKTOP VIEW OF PLANS */}
        <div className='flex flex-row gap-4'>
          {(state?.speciesPlans || [])
              .filter(planFilter)
              .map((plan, key) => <PlanCard key={key} plan={plan} onClick={()=> selectPlan(plan.id)}/>)}
        </div>

        {/* START MOBILE VIEW OF PLANS */}
        {/* <div className='lg:hidden flex flex-col w-full'>

          <div className={'mb-8 drop-shadow-lg flex flex-col px-4 sm:px-10 md:px-12 py-4 sm:py-10 md:py-12 border border-solid rounded-md border-stone-300 bg-white '}>
            <div className='mb-4 flex flex-row justify-between bg-stone-200 rounded-full'>
              {(state?.speciesPlans || [])
                .filter(planFilter)
                .map((plan) => (
                  <button  className={`${currentPlanId === plan.id && '!bg-white !text-snout-orange'} cursor-pointer border-0 bg-transparent text-stone-500 rounded-full py-2 my-1 mx-1 flex-1`} key={plan.id} onClick={()=> { setCurrentPlanId(plan.id); } }>{plan.name}</button>
              ))}
            </div>
            <div className='mb-2 flex flex-row flex-nowrap items-baseline font-roboto-slab'><span className='text-3xl font-medium'>{common.pennyIntToRoundedDollarString(state?.speciesPlans?.find(p => p.id === currentPlanId)?.monthly_price)}/</span><span>month</span></div>
            <div className="mb-4"><span className='bg-red-100 rounded-md text-snout-orange font-roboto-slab text-sm px-2 py-1 font-semibold'>{common.getSavings(state?.speciesPlans?.find(p => p.id === currentPlanId))} Savings</span></div>
            <PlanItems className='mb-2' plan={state?.speciesPlans.find(p => p.id === currentPlanId)}/>
          </div>
          <div className={'mt-auto sm:mt-8 w-full text-center'}>
            <button className={'w-full sm:w-60 btn-primary'} type="button" onClick={()=> selectPlan(currentPlanId)}>Purchase Selected Plan</button>
          </div>
        </div> */}
      </div>
    </StepContainer>
  );
}

export default PlanPicker;

