import { useForm } from 'react-hook-form';
import GanymedeInput from '../../../components/ganymede/GanymedeInput'
import StepContainer from "../StepContainer";
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENT_CODES } from '../../../constants';

function PetName({state, dispatch}) {

  const { formState: { errors }, handleSubmit, register, setValue } = useForm({
  });

  const onSubmit = (values) => {
    track(AMPLITUDE_EVENT_CODES.SELF_ENROLL_PET_NAME, {
      vet: state.vet,
      client: state.client,
      pet: state.currentPet || { name: values.petName },
    });
    dispatch({type: 'next', petName: values.petName});
  }

  return (
    <StepContainer back={() => dispatch({type: 'prev'})}>
      <form className={'flex flex-col items-center h-full'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'font-roboto-slab text-3xl sm:text-4xl text-center mb-4'}>What's your pet's name?</div>
        <GanymedeInput className={'w-full'} type={'text'} label={'Pet Name'} errors={errors}
          {...register('petName', {
            onChange: (e) => setValue('petName', e?.target?.value?.trim()),
            value: state?.currentPet?.name, required: { value: true, message: 'Pet name is required.' },
          })} />
        <button className={'mt-auto sm:mt-8 w-full sm:w-40 btn-primary'} type="submit">Continue</button>
      </form>
    </StepContainer>
  );
}

export default PetName;
