import './payment-request.css';
import {Outlet, useNavigate, useOutletContext} from 'react-router-dom';
import { useEffect, useState } from 'react';

function PaymentRequestView() {
  const { code, apiState, dagwood, updatePaymentRequest } = useOutletContext();
  const navigate = useNavigate();
  const [paymentMode, setPaymentMode] = useState('select');

  useEffect(() => {
    if (!dagwood) navigate(`../load/${code}`);
  }, [dagwood]);

  const [paymentMethod, setPaymentMethod] = useState();

  const checkout = async () => {
      await updatePaymentRequest(paymentMethod); 
  };

  const onSubmitPaymentInformation = (paymentMethod) => {
    setPaymentMethod(paymentMethod);
    navigate('finish');
  }

  return (
    <Outlet context={{ dagwood, apiState, onSubmitPaymentInformation, checkout, paymentMode, setPaymentMode}}/>
  );

}

export default PaymentRequestView;
