import {useNavigate, useOutletContext, useParams} from 'react-router-dom';
import {useEffect, useState} from "react";
import GanymedePageFooter from "../../components/ganymede/PageFooter";
import GanymedePageContainer from "../../components/ganymede/PageContainer";
import SnoutLoadingDots from '../../components/snout-loading-dots/SnoutLoadingDots';

function PaymentRequestLoadingView() {

  const params = useParams();
  const navigate = useNavigate();
  const { dagwood, loadRequestByCode } = useOutletContext();
  const [ mode, setMode ] = useState('loading');

  const [inputCode, setInputCode] = useState('');

  const code = dagwood?.external_id || params?.code;

  useEffect(() => {
    (async () => {
      try {
        await loadRequestByCode(code);
      } catch (e) {
        if (e.status === 404) {
          setMode('not found');
        } else {
          setMode('whoops');
        }
      }
    })();
  }, []);

  return (
      <>
        <GanymedePageContainer className={'loader-container'}>
          { mode === 'loading' &&
            <>
              <h1>Fetching Your Payment Request...</h1>
              <div className={'loading-dots-container'}>
                <SnoutLoadingDots />
              </div>
            </>
          }
          { mode === 'not found' &&
          <>
            <h1>Request Code Not Found</h1>
            <p>Sorry, your request code was not recognized!</p>
          </>
          }
          { mode === 'whoops' &&
          <>
            <h1>Whoops!</h1>
            <p>Sorry, an unknown error has occurred. Please try again later.</p>
          </>
          }
        </GanymedePageContainer>
        <GanymedePageFooter />
      </>
  );
}

export default PaymentRequestLoadingView;
