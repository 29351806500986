import StepContainer from "../StepContainer";

function ServiceAgreement({ state, dispatch }) {

  return (
    <StepContainer back={() => dispatch({type: 'prev'})}>
      <div className={''}>
        <div className={"font-roboto-slab text-3xl border-solid border-stone-300 border-r-0 border-t-0 border-l-0 pb-2 mb-6"}>
          SNOUT PLANS - MEMBERSHIP AGREEMENT
        </div>
        <div className={'overflow-x-scroll h-[58vh]'}>
          <div className={""}>
            {/* <p
          //</div>style="text-align: center;"
          >
            <strong>SNOUT PLANS - MEMBERSHIP AGREEMENT</strong>
          </p> */}

            <p className="intro-text">
              This Membership Agreement (
              <strong>
                <em>“Agreement”</em>
              </strong>
              ) constitutes a legally binding agreement between you (
              <strong>
                <em>"you"</em>
              </strong>
              ) and Snout, Inc. (the{" "}
              <strong>
                <em>“Company”</em>
              </strong>
              ) governing your enrollment in the wellness-based subscription plan
              for pets offered by the Company (the{" "}
              <strong>
                <em>“Snout Plan”</em>
              </strong>
              ) for veterinary services (the{" "}
              <strong>
                <em>“Veterinary Services”</em>
              </strong>
              ) provided to you by your veterinarian (the{" "}
              <strong>
                <em>“Practice”</em>
              </strong>
              ) identified in the receipt delivered by the Company to you upon
              enrollment (the{" "}
              <strong>
                <em>“Receipt”</em>
              </strong>
              ).
            </p>
            <p>
              YOU ACKNOWLEDGE AND AGREE THAT, BY CLICKING ON THE “I AGREE” OR
              SIMILAR BUTTON, REGISTERING FOR AN ACCOUNT WITH THE COMPANY, OR
              ACCESSING OR USING THE SNOUT PLAN, YOU ARE INDICATING THAT YOU HAVE
              READ, UNDERSTAND AND AGREE TO BE BOUND BY THIS MEMBERSHIP AGREEMENT,
              WHETHER OR NOT YOU HAVE REGISTERED WITH THE COMPANY. IF YOU DO NOT
              AGREE TO THIS MEMBERSHIP AGREEMENT, THEN YOU HAVE NO RIGHT TO ACCESS
              OR USE THE SNOUT PLAN. This Agreement is effective as of the date
              you first click “I agree” (or similar button or checkbox) or use or
              access the Snout Plan, whichever is earlier.
            </p>
            <p>
              Any personal data you submit to the Company or which we collect
              about you is governed by our Privacy Policy{" "}
              {" "}
              (the <strong>
              <em>“Privacy Policy”</em>
            </strong>), available at{" "}
              <a href="https://www.snoutid.com/privacy">
                https://www.snoutid.com/privacy
              </a>
              . You acknowledge that by using the Snout Plan you have reviewed the
              Privacy Policy. The Privacy Policy is incorporated by reference into
              this Agreement and together form and are hereinafter referred to as
              this “Agreement.” Further, the Company does not knowingly accept,
              request, or solicit information from children or knowingly market to
              children. Therefore, in accordance with the U.S. Children’s Online
              Privacy Protection Act, if the Company receives actual knowledge
              that anyone under the age of 13 has provided personal information to
              the Company without the requisite and verifiable parental consent,
              the Company will delete that information as quickly as reasonably
              practical.
            </p>

            <p>
              YOU ACKNOWLEDGE THAT THE SNOUT PLAN IS IN NO WAY TO BE CONSIDERED
              PET HEALTH INSURANCE OR INDEMNITY PROPERTY INSURANCE.
            </p>
            <p>
              PLEASE NOTE: THIS AGREEMENT GOVERNS HOW DISPUTES BETWEEN YOU AND
              COMPANY CAN BE RESOLVED. IT CONTAINS A BINDING AND FINAL ARBITRATION
              PROVISION AND CLASS ACTION WAIVER. PLEASE READ CAREFULLY AS IT
              AFFECTS YOUR LEGAL RIGHTS, INCLUDING, IF APPLICABLE, YOUR RIGHT TO
              OPT OUT OF ARBITRATION.
            </p>
            <ol>
              <li>
                <h3>SCOPE OF SNOUT PLANS</h3>
                <ol>
                  <li>
                    {" "}
                    Subject to the terms of this Agreement, your Snout Plan will
                    only include the Veterinary Services provided by the Practice
                    set forth on the Receipt based on the prices set forth in the
                    Receipt. The scope of services and prices listed in the
                    Receipt may be amended with mutual written consent of the
                    Practice and Company (the{" "}
                    <strong>
                      <em>“Updates”</em>
                    </strong>
                    ), provided that any such Updates will not apply to you until
                    after the expiration of your then-current annual subscription
                    term for the Snout Plan. For clarity, the Snout Plan will only
                    pay the Practice for Veterinary Services provided during the
                    Term.
                  </li>
                  <li>
                    For clarity, the Practice will be solely responsible for
                    providing the Veterinary Services to you.
                  </li>
                  <li>
                    You may not assign or transfer your Snout Plan to any other
                    person and Snout Plan shall only cover pet(s) specified in the
                    Receipt.
                  </li>
                </ol>
              </li>
              <li>
                <h3>PAYMENT OF FEES</h3>
                <ol>
                  <li>
                    You must pay the Company the applicable monthly fee set forth
                    on the Receipt (the{" "}
                    <strong>
                      <em>“Monthly Membership Fee”</em>
                    </strong>
                    ) in advance on a monthly basis.
                  </li>
                  <li>
                    You must provide accurate and complete information for a valid
                    credit card, debit card, or bank account that you are
                    authorized to use (the{" "}
                    <strong>
                      <em>“Payment Method”</em>
                    </strong>
                    ). You will be billed for the Monthly Membership Fee through
                    such Payment Method on a monthly basis. You must promptly
                    notify the Company of any changes related to your Payment
                    Method. BY COMPLETING REGISTRATION FOR THE SNOUT PLAN, YOU
                    AUTHORIZE THE COMPANY OR ITS AGENT TO CHARGE YOUR PAYMENT
                    METHOD ON A RECURRING BASIS FOR THE APPLICABLE MONTHLY
                    MEMBERSHIP FEE. Such authorization continues through the
                    applicable Term until you cancel this Agreement as set forth
                    in Section 3 below.
                  </li>
                  <li>
                    Each Monthly Membership Fee is due by 11:59pm PT on the last
                    day of the month prior. If the Company does not receive
                    payment from your Payment Method by that time, you agree to
                    pay all amounts due upon demand. Any payments received after
                    this time are considered (
                    <strong>
                      <em>“Late”</em>
                    </strong>
                    ). Late payments will be subject to a late fee (
                    <strong>
                      <em>“Late Fee”</em>
                    </strong>
                    ) equal to $15.00 USD. If any payment is at least 30 calendar
                    days late, Company may immediately terminate this agreement.
                    You will be responsible for paying the sum of (i) all
                    remaining Monthly Membership Fees, (ii) a Late Fee, and (iii)
                    any applicable costs set forth in Section 2.4 below.
                  </li>
                  <li>
                    You will reimburse any costs or expenses (including, but not
                    limited to, reasonable attorneys’ fees and collection agency
                    costs) incurred by the Company to collect any amount that is
                    not paid when due. Company may accept payment in any amount
                    without prejudice to its right to recover the balance of the
                    amount due or to pursue any other right or remedy. Amounts due
                    to Company may not be withheld or offset by you for any reason
                    against amounts due or asserted to be due from Company.
                  </li>
                  <li>
                    All payments required by this Agreement are stated exclusive
                    of all taxes, duties, levies, imposts, fines, or similar
                    governmental assessments (collectively, the{" "}
                    <strong>
                      <em>“Taxes”</em>
                    </strong>
                    ). You will be responsible for and bear Taxes associated with
                    your purchase of, payment for, access to or use of the Snout
                    Plan.
                  </li>
                  <li>
                    All payments made by you to the Company are non-refundable.
                  </li>
                </ol>
              </li>
              <li>
                <h3>TERM AND TERMINATION</h3>
                <ol>
                  <li>
                    Subject to earlier termination as provided below, this
                    Agreement is for a term set forth in the Receipt (the{" "}
                    <strong>
                      <em>“Initial Service Term”</em>
                    </strong>
                    ), and shall be automatically renewed for additional periods
                    of the same duration as the Initial Service Term only upon
                    delivery of written notice of such renewal by the Company to
                    you within 30 days prior to such renewal (collectively, the{" "}
                    <strong>
                      <em>“Term”</em>
                    </strong>
                    ).
                  </li>
                  <li>
                    You may terminate this Agreement immediately in case of: (i)
                    your pet’s death, (ii) your relocation to a new place of
                    residence that is at least 20 miles from the office of the
                    Practice (subject to you providing reasonably sufficient proof
                    of such relocation to the Company), (iii) the Practice
                    permanently ceasing operations, or (iv) the Practice
                    relocating at least 20 miles from its previous location.
                  </li>
                  <li>
                    In addition to any other remedies it may have, either party
                    may also terminate this Agreement upon thirty (30) days’
                    notice (or without notice in the case of nonpayment), if the
                    other party materially breaches any of the terms or conditions
                    of this Agreement.
                  </li>
                  <li>
                    Upon termination of this Agreement, (i) the Company will not
                    pay the Practice for any Veterinary Services provided to you
                    by the Practice and you will be solely responsible for any
                    fees or payments owed for any services provided by the
                    Practice to you, and (ii) to the extent the aggregate Monthly
                    Membership Fees paid by you to the Company during the Term as
                    of such termination is less than the cost of Veterinary
                    Services (as specified in the Receipt) provided to you by the
                    Practice during the Term (such difference, the{" "}
                    <strong>
                      <em>“Underpayment Amount”</em>
                    </strong>
                    ), you will immediately pay such Underpayment Amount to the
                    Company. In the event where the aggregate Monthly Membership
                    Fees paid by you to the Company during the Term exceed the
                    cost of Veterinary Services (as specified in the Receipt)
                    provided to you by the Practice during the Term, no additional
                    fees will be incurred nor will funds be returned to you.
                  </li>
                </ol>
              </li>
              <li>
                <h3>WARRANTY AND DISCLAIMER</h3>
                <p>
                  The Company is not offering an insurance policy as part of the
                  Snout Plan. The Snout Plan is offered separately and distinctly
                  from any insurance. COMPANY MAKES NO WARRANTIES REGARDING THE
                  VETERINARY SERVICES PROVIDED BY THE PRACTICE; NOR DOES IT MAKE
                  ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM SUCH
                  VETERINARY SERVICES. EXCEPT AS EXPRESSLY SET FORTH IN THIS
                  SECTION, THE COMPANY’S SERVICES ARE PROVIDED “AS IS” AND COMPANY
                  DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                  LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS
                  FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                </p>
              </li>
              <li>
                <h3>INDEMNITY</h3>
                <p>
                  You agree to defend, indemnify and hold harmless Company, its
                  affiliates, and its and their respective owners, officers,
                  directors, employees, agents, investors, and/or licensors, from
                  and against any and all claims, damages, obligations, losses,
                  liabilities, costs and expenses (including but not limited to
                  attorney’s fees) arising from: (i) your breach of this
                  Agreement; (ii) your violation of any third-party right or any
                  applicable law; (iii) your negligence or willful misconduct, and
                  (iv) any Veterinary Services not explicitly included in the
                  Snout Plan.
                </p>
              </li>
              <li>
                <h3>LIMITATION OF LIABILITY</h3>
                <p>
                  NOTWITHSTANDING ANYTHING TO THE CONTRARY, COMPANY AND ITS
                  SUPPLIERS, OFFICERS, AFFILIATES, INVESTORS, REPRESENTATIVES,
                  CONTRACTORS AND EMPLOYEES SHALL NOT BE RESPONSIBLE OR LIABLE
                  WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR TERMS
                  AND CONDITIONS RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE,
                  STRICT LIABILITY OR OTHER THEORY: (A) FOR ANY INDIRECT,
                  EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES; (B) FOR
                  ANY MATTER BEYOND COMPANY’S REASONABLE CONTROL; OR (C) FOR ANY
                  AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL OTHER
                  CLAIMS, EXCEED THE MONTHLY MEMBERSHIP FEES PAID BY YOU TO THE
                  COMPANY DURING THE PRECEDING TWELVE (12) MONTHS.
                </p>
              </li>
              <li>
                <h3 className="arbitration">
                  {" "}
                  DISPUTE RESOLUTION &ndash; ARBITRATION &amp; CLASS ACTION WAIVER
                </h3>
                <p className={'subpoint'}>
                  PLEASE READ THIS SECTION CAREFULLY—IT AFFECTS YOUR LEGAL RIGHTS
                  AND GOVERNS HOW YOU AND THE COMPANY CAN BRING CLAIMS AGAINST
                  EACH OTHER. THIS SECTION WILL, WITH LIMITED EXCEPTION, REQUIRE
                  YOU AND THE COMPANY TO SUBMIT CLAIMS AGAINST EACH OTHER TO
                  BINDING AND FINAL ARBITRATION ON AN INDIVIDUAL BASIS.
                </p>
                <p className={'subpoint'}>
                  You agree that, in the event any dispute or claim arises out of
                  or relating to your use of the Snout Plan, you will contact us
                  at support@snoutid.com and you and the Company will attempt in
                  good faith to negotiate a written resolution of the matter
                  directly. You agree that if the matter remains unresolved for 30
                  days after notification (via certified mail or personal
                  delivery), such matter will be deemed a “Dispute” as defined
                  below. Except for the right to seek injunctive or other
                  equitable relief described under the “Binding Arbitration”
                  section below, should you file any arbitration claims, or any
                  administrative or legal actions without first having attempted
                  to resolve the matter by mediation, then you agree that you will
                  not be entitled to recover attorneys' fees, even if you may have
                  been entitled to them otherwise.
                </p>
                <p className={'subpoint'}>
                  <strong>Binding Arbitration.</strong> You and the Company agree
                  that any dispute, claim or controversy arising out of or
                  relating to this Agreement or to your use of the Snout Plan
                  (collectively
                  <strong> “Disputes”</strong>) will be settled by binding
                  arbitration, except that each party retains the right to seek
                  injunctive or other equitable relief in a court of competent
                  jurisdiction to prevent the actual or threatened infringement,
                  misappropriation, or violation of a party’s copyrights,
                  trademarks, trade secrets, patents, or other intellectual
                  property rights.{" "}
                  <strong>
                    This means that you and the Company both agree to waive the
                    right to a trial by jury.
                  </strong>{" "}
                  Notwithstanding the foregoing, you may bring a claim against the
                  Company in “small claims” court, instead of by arbitration, but
                  only if the claim is eligible under the rules of the small
                  claims court and is brought in an individual, non-class, and
                  non-representative basis, and only for so long as it remains in
                  the small claims court and in an individual, non-class, and
                  non-representative basis.
                </p>
                <p className={'subpoint'}>
                  <strong>Class Action Waiver.</strong> You and the Company agree
                  that any proceedings to resolve Disputes will be conducted on an
                  individual basis and not in a class, consolidated, or
                  representative action.{" "}
                  <strong>
                    This means that you and the Company both agree to waive the
                    right to participate as a plaintiff as a class member in any
                    class action proceeding. Further, unless you and the Company
                    agree otherwise in writing, the arbitrator in any Dispute may
                    not consolidate more than one person’s claims and may not
                    preside over any form of class action proceeding.
                  </strong>
                </p>
                <p className={'subpoint'}>
                  <strong>Arbitration Administration and Rules.</strong> The
                  arbitration will be administered by the American Arbitration
                  Association (<strong>“AAA”</strong>) in accordance with the
                  Commercial Arbitration Rules and the Supplementary Procedures
                  for Consumer Related Disputes (the <strong>“AAA Rules”</strong>)
                  then in effect, except as modified by this “Dispute Resolution’
                  section. (The AAA Rules are available at{" "}
                  <a href="http://www.adr.org">http://www.adr.org</a> or by
                  calling the AAA at 1-800-778-7879).
                </p>
                <p className={'subpoint'}>
                  <strong>Arbitration Process.</strong> A party who desires to
                  initiate the arbitration must provide the other party with a
                  written Demand for Arbitration as specified in the AAA Rules.
                  The arbitrator will be either a retired judge or an attorney
                  licensed to practice law in the state of New York and will be
                  selected by the parties from the AAA’s roster of arbitrators
                  with relevant experience. If the parties are unable to agree
                  upon an arbitrator within seven days of delivery of the Demand
                  for Arbitration, then the AAA will appoint the arbitrator in
                  accordance with AAA Rules.
                </p>
                <p className={'subpoint'}>
                  <strong>Arbitration Location and Procedure.</strong> Unless you
                  and the Company agree otherwise, the seat of the arbitration
                  shall be in San Francisco, California. If your claim does not
                  exceed USD$10,000, then the arbitration will be conducted solely
                  on the basis of documents you and the Company submit to the
                  arbitrator, unless you request a hearing and the arbitrator then
                  determines that a hearing is necessary. If your claim exceeds
                  USD$10,000, your right to a hearing will be determined by AAA
                  Rules. Subject to AAA Rules, the arbitrator will have the
                  discretion to direct a reasonable exchange of information by the
                  parties, consistent with the expedited nature of the
                  arbitration. Hearings may be conducted by telephone or video
                  conference, if requested and agreed to by the parties.
                </p>
                <p className={'subpoint'}>
                  <strong>Arbitrator’s Decision and Governing Law.</strong> The
                  arbitrator shall apply California law consistent with the
                  Federal Arbitration Act and applicable statutes of limitations,
                  and shall honor claims of privilege recognized by law. The
                  arbitrator will render an award within the timeframe specified
                  in the AAA Rules. Judgment on the arbitration may be entered in
                  any court having jurisdiction thereof. Any award of damages by
                  an arbitrator must be consistent with the “Warranties and
                  Disclaimers” and “Limitations of Liability” sections above. The
                  arbitrator may award declaratory or injunctive relief in favor
                  of the claimant only to the extent necessary to provide relief
                  warranted by the claimant’s individual claim.
                </p>
                <p className={'subpoint'}>
                  <strong>Fees.</strong> Each party’s responsibility to pay the
                  arbitration filing, administrative and arbitrator fees will
                  depend on the circumstances of the arbitration and are set forth
                  in the AAA Rules.
                </p>
              </li>
              <li>
                <h3>MISCELLANEOUS</h3>
                <p>
                  All sections of this Agreement which by their nature should
                  survive termination will survive termination, including, without
                  limitation, accrued rights to payment, confidentiality
                  obligations, warranty disclaimers, indemnification obligations,
                  and limitations of liability. If any provision of this Agreement
                  is found to be unenforceable or invalid, that provision will be
                  limited or eliminated to the minimum extent necessary so that
                  this Agreement will otherwise remain in full force and effect
                  and enforceable. This Agreement is not assignable, transferable
                  or sublicensable by you except with Company’s prior written
                  consent. Company may transfer and assign any of its rights and
                  obligations under this Agreement without consent. This Agreement
                  is the complete and exclusive statement of the mutual
                  understanding of the parties and supersedes and cancels all
                  previous written and oral agreements, communications and other
                  understandings relating to the subject matter of this Agreement,
                  and that all waivers and modifications must be in a writing
                  signed by both parties, except as otherwise provided herein. No
                  agency, partnership, joint venture, or employment is created as
                  a result of this Agreement and you do not have any authority of
                  any kind to bind Company in any respect whatsoever. All notices
                  under this Agreement will be in writing and will be deemed to
                  have been duly given when received, if personally delivered;
                  when receipt is electronically confirmed, if transmitted by
                  facsimile or e-mail; the day after it is sent, if sent for next
                  day delivery by recognized overnight delivery service; and upon
                  receipt, if sent by certified or registered mail, return receipt
                  requested. This Agreement shall be governed by the laws of the
                  State of California without regard to its conflict of laws
                  provisions.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </StepContainer>
  );
}

export default ServiceAgreement;
