function GanymedeDocumentHeader ({ className, badge, supertext, title, attribution, ...props }) {
  return (
      <div className={`${className} GanymedeDocumentHeader`}>
        {badge && <div className={'GanymedeDocumentHeaderBadge'}>{badge}</div>}
        <div className={'flex-grow font-normal text-2xl md:text-4xl GanymedeDocumentHeaderTitle'}>
          <div>{ supertext }</div>
          <div>{ title }</div>
        </div>
        <div className={'GanymedeDocumentHeaderAttribution'}>{ attribution }</div>
      </div>
  );
}

export default GanymedeDocumentHeader;
