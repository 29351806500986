import "./snout-progress-btn.css";
import ProgressButton from 'react-progress-button';
import { forwardRef } from 'react';

const DURATION_SUCCESS = 2000;

const SnoutProgressBtn = forwardRef(({ children, className, ...props }, ref) => {

  return (
      <ProgressButton
        className={`snout-progress-btn ${className || ''}`}
        durationSuccess={DURATION_SUCCESS}
        ref={ref}
        controlled={true}
        {...props}
      >
        {children}
      </ProgressButton>
  );
})

export default SnoutProgressBtn;