import { forwardRef, useEffect, useRef, useState } from 'react';
import { mergeRefs } from "react-merge-refs";

const GanymedeInput = forwardRef(({ label, errors, type, customError, className, ...props }, ref) => {
  const { name, onChange, ...rest } = props;

  // state for shrinking the label when not empty
  const [value, setValue] = useState(props.value);

  const innerRef = useRef();

  useEffect(() => {
      setValue(innerRef?.current?.value);
  }, [innerRef.current?.value]);

  // method that gets object property that handles nesting ex. formGroup.firstName
  const getDotNotatedProperty = (obj, path) => {
    return path.split('.').reduce((o, i) => o?.[i], obj);
  };

  return (
    <div className={`ganymede-input form-control-container ${className || ''}`}>
      <input
        name={name}
        type={type || 'text'}
        onChange={(e)=>{
          setValue(e?.target?.value);
          onChange(e);
        }}
        {...rest}
        ref={mergeRefs([ref, innerRef])}
      />
      <label className={value ? 'ganymede-input-label-shrunk' : ''}>{label}</label>
      {customError || getDotNotatedProperty(errors, name) && <span className={'form-validation-error'}>{getDotNotatedProperty(errors, name)?.message || 'this field has errors'}</span>}
    </div>
  );
});

export default GanymedeInput;
