import GanymedePageContainer from "../../components/ganymede/PageContainer";
import GanymedeKeyValueTable from "../../components/ganymede/KeyValueTable";
import CheckCircle from "../../assets/svgs/CheckCircle.svg";
import GanymedePageFooter from "../../components/ganymede/PageFooter";
import {useOutletContext} from "react-router-dom";
import { useMemo } from 'react';

function ReceiptStep() {

  const { client, paymentRequest } = useOutletContext();

  const title = useMemo(() => {
    return 'Thank You!';
  }, []);

  const subtitle = useMemo(() => {
    if (paymentRequest) {
      if (paymentRequest.fundsHeld) {
        return `We have placed a hold on your payment method. Your care plan benefits, if any, will be applied to your invoice accordingly. We will send you a final, itemized receipt upon completion.`;
      } else {
        return `We are processing your invoice. Your care plan benefits, if any, will be applied to your invoice accordingly. We will charge your card and send you a final, itemized receipt upon completion.`;
      }
    }
  }, [paymentRequest]);

  const data = useMemo(() => {
    if (paymentRequest) {
        return {
          'confirmation number':paymentRequest?.external_id,
          'Payment Method': `${paymentRequest?.card?.brand} ****${paymentRequest?.card?.last4}`,
          'Client': `${paymentRequest?.client?.first_name} ${paymentRequest?.client?.last_name}`,
          'Patient(s)': paymentRequest?.patients?.map(p => p.name).join(', ')
        };
    }
  }, [paymentRequest]);


  return (
    <div className={'receipt-container'}>
      <div className={'receipt-header'}>
            <span className={'payment-success-icon'}>
              <img src={CheckCircle}  alt={'success icon'} />
            </span>
        <span className={'receipt-title'}>{title}</span>
        <span className={'receipt-title2'}>{subtitle}</span>
        <span className={'receipt-title3'}>Please hand this device back to the employee.</span>
      </div>
      <GanymedeKeyValueTable className={'ganymede-full-width total'} header={['total paid Today', paymentRequest?.invoice_amount]}
                                      data={data}/>
    </div>
  );
}

export default ReceiptStep;
