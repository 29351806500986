import { Controller, useForm } from 'react-hook-form';
import RadioButton from '../../../components/RadioButton/RadioButton.js'
import GanymedeInput from '../../../components/ganymede/GanymedeInput';
import Select from 'react-select';
import StepContainer from "../StepContainer";
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENT_CODES } from '../../../constants';
import {useState} from "react";
import { validatePetBirthDate } from '../common';
import DayJs from "dayjs";
DayJs.extend(require('dayjs/plugin/localizedFormat'));

const months = ["January","February","March","April","May","June","July",
  "August","September","October","November","December"].map((m, idx) => ({ value: idx, label: m, }));

function PetAgeAndSex({state, dispatch}) {

  const birthdate = state?.currentPet?.birthdate && DayJs(state?.currentPet?.birthdate);

  const { formState: { errors }, handleSubmit, register, control } = useForm({ defaultValues: {
    sex: state?.currentPet?.sex,
    month: months.find((month) => month.value === birthdate?.month()),
    year: birthdate?.year(),
    day: birthdate?.date(),
    } });

  const [dateError, setDateError] = useState(undefined);

  const onSubmit = (values) => {
    // support two-digit years by turning them into four digit years "intelligently"
    const yearInt = parseInt(values.year);
    if (yearInt < 50) { values.year = `20${yearInt}`; }
    if (yearInt >= 50 && yearInt < 100) { values.year = `19${yearInt}`; }

    // remove leading zeroes from day field (breaks the DayJs validation function)
    values.day = String(parseInt(values.day));

    // validate the pet's birthdate
    const dateErrorMessage = validatePetBirthDate(values.year, values.month.value, values.day);
    setDateError(dateErrorMessage);
    if (dateErrorMessage) { return; }

    track(AMPLITUDE_EVENT_CODES.SELF_ENROLL_PET_SEX_AGE, {
      vet: state.vet,
      client: state.client,
      pet: { ...state.currentPet, ...values, month: values.month.value },
    });
    dispatch({type: 'next', birthdate: DayJs(`${values.year}-${values.month.value+1}-${values.day}`, 'YYYY-M-D', true).toISOString(), sex: values.sex });
  }

  return (
    <StepContainer back={() => dispatch({type: 'prev'})}>
      <form className={'flex flex-col items-center h-full'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'font-roboto-slab text-3xl sm:text-4xl text-center'}>{state?.client?.first_name[0].toUpperCase() + state?.client?.first_name.slice(1) }, tell me more about {state?.currentPet?.name[0].toUpperCase() + state?.currentPet?.name.slice(1)}...</div>
        <div className={'text-gray-500 text-center my-5'}>If you don’t know {state?.currentPet?.name[0].toUpperCase() + state?.currentPet?.name.slice(1)}'s exact birthdate, just give your best guess.</div>

        <div className={'grid grid-cols-12 grid-col-gap-2 grid-row-gap-0 w-full '}>

          <Controller
              name={'month'}
              rules={{ required: { value: true, message: 'Month is required'} }}
              control={control}
              render={({ field }) =>
                  <div className={'col-span-full sm:col-span-5 mb-3.5'}>
                    <Select
                        placeholder={'Birth month'}
                        options={months}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            height: '3.13rem',
                          }),
                        }}
                        {...field}
                    />
                    {errors?.[field.name] && <span className={'form-validation-error absolute'}>{errors?.[field.name]?.message}</span>}
                  </div>
              }
          />

          <GanymedeInput className={'col-span-full sm:col-span-3 flex-grow-0'} errors={errors} label={'Day'} type={'text'} maxLength="2" {...register('day', { required: { value: true, message: 'Day is required' }, pattern: { value: /^\d+$/, message: 'Day must be a number'} })}/>
          <GanymedeInput className={'col-span-full sm:col-span-4 flex-grow-0'} errors={errors} label={'Year'} maxLength="4" type="text" {...register('year', {  required: { value: true, message: 'Year is required',}, pattern: { value: /^\d+$/, message: 'Year must be a number'} })}/>
          { dateError && <div className="px-2 py-1 mb-4 -mt-1 block col-span-full bg-red-50 rounded border border-solid border-stone-300 text-stone-500 text-sm font-medium">{dateError}</div> }
          <RadioButton className={'col-span-full sm:col-span-6 mb-4'} label="Boy" id="radio-m" name="radio-m" value="m" {...register('sex', { shouldUnregister: true, required: { value: true, message: 'Gender is required' }})}/>
          <RadioButton className={'col-span-full sm:col-span-6 mb-4'} label="Girl" id="radio-f" name="radio-f" value="f" {...register('sex', { shouldUnregister: true, required: { value: true, message: 'Gender is required' }})}/>
          <div className={'form-validation-error col-span-full flex flex-row justify-left -mt-4'}>{errors?.sex?.message}</div>
        </div>
        <button className={'mt-auto sm:mt-8 w-full sm:w-40 btn-primary' } type="submit">Continue</button>
      </form>
    </StepContainer>
  );
}

export default PetAgeAndSex;
