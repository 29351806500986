function GanymedePageContainer ({ trailer, footerContent, ...props }) {
  return (
      <div className={`items-center flex-grow bg-gray-50 sm:p-4 md:p-10 lg:p-20 GanymedePageContainerBackground page-container ${props.className}`}>
        <div>
          <div className={'bg-white border border-solid border-gray-200 md:rounded box-border flex-grow shadow-2xl GanymedePageContainerPage'}>
            <div className={'py-2 px-4 sm:p-12 GanymedePageContainerContent'}>
              { props.children }
            </div>
            {footerContent && <div className={'p-2 sm:p-12 GanymedePageContainerFooter'}>
              {footerContent}
            </div>}
          </div>
          {trailer && <div className={'GanymedePageContainerTrailer'}>
            {trailer}
          </div>}
        </div>
      </div>
  );
}

export default GanymedePageContainer;
