import { useFormContext, Controller } from 'react-hook-form';

/*
* Input to capture a credit card number and format with spaces
*
* Because this component calls useFormContext(), ensure the form is wrapped in <FormProvider> or this will break
* */
const GanymedeCreditCardNumberInput = (
  {
    name,
    label,
    invalidErrorMsg = 'Card number is not valid', // custom error message if the card doesn't fit the pattern
    rules = {}, // validation options documented here: https://react-hook-form.com/api/useform/register#options
    ...props
  }) => {
  const { control, formState: { errors } } = useFormContext();

  // method that gets object property that handles nesting ex. formGroup.firstName
  const getDotNotatedProperty = (obj, path) => {
    return path.split('.').reduce((o, i) => o?.[i], obj);
  };

  // Transform and Parse section of react hook api here: https://react-hook-form.com/advanced-usage
  const transform= {
      input: (value) => {
        // regex stolen from dis guy https://stackoverflow.com/a/59339120
        const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
        const onlyNumbers = value.replace(/[^\d]/g, '');
        return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
          [$1, $2, $3, $4].filter(group => !!group).join(' ')
        )
      },
      output: (e) => {
        return e?.target?.value?.split(' ').join('');
    }
  }

  // fancy react-hook-forms api to transform values and maintain validation and such
  return (
    <div className={`ganymede-input form-control-container ${props.className || ''}`}>
      <Controller
        defaultValue={''}
        control={control}
        name={name}
        rules={{ ...rules, maxLength: { value: 16, message: invalidErrorMsg }, minLength: { value: 14, message: invalidErrorMsg } }}
        render={({ field }) => (
          <>
            <input
              onChange={(e) => field.onChange(transform.output(e))}
              value={transform.input(field.value)}
              maxLength={19}
            />
            <label className={field.value ? 'ganymede-input-label-shrunk' : ''}>{label}</label>
            {getDotNotatedProperty(errors, name)?.message && <span className={'form-validation-error'}>{getDotNotatedProperty(errors, name)?.message}</span>}
          </>
        )}
      />
    </div>
  );
};

export default GanymedeCreditCardNumberInput;
